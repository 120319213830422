import React, { forwardRef, useEffect } from "react";
import { useHistory } from "react-router";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import "@material-ui/core";
import YellowPagesNavigation from "./leftnav";
import Axios from "axios";
import PostReply from "./../components/dashboard/PostReply";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Box from "@material-ui/core/Box";
import EventQuickLinks from "./homeapps/EventQuickLinks";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import SportsCricketIcon from '@material-ui/icons/SportsCricket';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import PeopleIcon from '@material-ui/icons/People';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import ListIcon from '@material-ui/icons/List';
import MovieIcon from '@material-ui/icons/Movie';
import Utils from './utils/homeutils';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as _ from 'lodash';
var moment: any = require('moment');


function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function dateFormat2(d: any) {
  let monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  let t = new Date(d);
  return monthShortNames[t.getMonth()] + ' ' + t.getDate();
}
const useStyles = (theme: any) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },
  padding: {
    padding: theme.spacing(0),
  },
  indicator: {
    backgroundColor: "#fff"
    //backgroundColor: '#fff#823587',
  },
  indicatorColor: {
    backgroundColor: "#82338d",
  },
  "&.Mui-selected": {
    outline: "none",
  },
  rootCard: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  subheader: {
    //backgroundColor: '#FF9933',
    backgroundColor: "#823587",
    minHeight: 24,
    height: 42,
    color: "#fff",
  },
});


class Employment extends React.Component<{
  componentName: any,
  cityid: any
  match: any,
  classes: any,
  history: any
}, {
  value: any,
  loading: boolean,
  IsRegistrationSelected: boolean,
  registrationopen: boolean,
  requirements: any[],
  Industries: any[],
  selecedIndustry: any,
  allRequirements: any[]
}>{
  constructor(props: any) {
    super(props);
    this.state = {
      value: "All",
      loading: true,
      IsRegistrationSelected: false,
      registrationopen: false,
      requirements: [],
      Industries: [],
      selecedIndustry: '',
      allRequirements: []
    }
  }
  async componentDidUpdate(prevProps: any, _prevState: any) {

  }
  async componentDidMount() {
    this.props.componentName("Employment");
    let requirementItems = await Utils.GetRequirements(Number(this.props.cityid));
    let industryItems = await Utils.GetIndustryDetails();
    this.setState({ loading: false, Industries: industryItems, allRequirements: requirementItems, requirements: requirementItems });
  }
  OpenJobRegistration = () => {
    this.props.history.push("/jobregistration");
  }
  handleChange = (event: any, newValue: any) => {
    this.props.history.push("/events/" + newValue.toLowerCase() + "");
  }
  GoToEntity = (obj: any) => {
    this.props.history.push("/employmententity/" + obj._source.id + "");
  }
  FilterByIndustry = (obj: any) => {
    if(obj!=="0"){
      let filteredData = this.state.allRequirements.filter(function (selectedRequirement: any) {
        return Number(selectedRequirement._source.industryid) === Number(obj)
      });
      this.setState({ requirements: filteredData });
    }else{
      this.setState({ requirements: this.state.allRequirements });
    }    
  }

  render() {
    const { classes, history } = this.props;
    let { value, loading, requirements, Industries } = this.state;
    let mainScope = this;
    return (
      <div className="d-flex" id="jh-wrapper">
        <div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
          <div className="row">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Employment
              </li>
              </ol>
            </nav>
          </div>
          <div className="jh-content-body">
            <div className="col-md-12 jh-events-title">
              <h2 className="jh-title-1">Employment</h2>
            </div>
            <div className="col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="jh-employment">
                <div className="jh-employment-body">
                  <div className="row jh-employment-filter">
                    <div className="col-md-8">
                      <select className="form-control" onChange={(e) => this.FilterByIndustry(e.target.value)}>
                        <option value="0">All</option>
                        {Industries.map((value: any) => <option value={value._source.id}>{value._source.title}</option>)}
                      </select>
                    </div>
                    <div className="col-md-4">
                      <button type="button" className="btn btn-primary  pull-right btn-block" onClick={this.OpenJobRegistration}>Job Seeker Registration</button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      {requirements.length === 0 && <p>No data found</p>}
                      <div className="row jh-card-columns">
                        {requirements.length > 0 && requirements.map(function (item) {
                          return (
                            <div className="col-lg-4 col-md-6 col-sm-6 yellow-card jh-card">
                              <div className="jh-card-inner jh-card-bg-1 jh-employment-card" onClick={() => mainScope.GoToEntity(item)}>
                                <div className="jh-card-header d-flex justify-content-center align-items-center">
                                  <h5 className="jh-card-title">
                                    {item._source.companyname} - {item._source.title}
                                  </h5>
                                </div>
                                <hr className="jh-card-separator" />
                                <div className="jh-card-body">
                                <div className="row jh-employment-card-1">
                                      <div className="col-md-6 col-lg-6 col-12">
                                        <b>Exp (Years):</b> <span>{item._source.experience}</span>
                                      </div>
                                      <div className="col-md-6 col-lg-6 col-12">
                                      <b>Industry:</b> <span>{item._source.industry}</span>
                                      </div>
                                  </div> 
                                  <p className="jh-card-text">
                                    <i className="fa fa-phone tiles-phone-icon" aria-hidden="true"></i>
                                    <span className="tiles-address-text">{item._source.contactnumber != null ? item._source.contactnumber : 'NA'}</span>
                                  </p>
                                  <p className="jh-card-text">
                                    <i className="fa fa-map-marker tiles-address-icon" aria-hidden="true"></i>
                                    <span className="tiles-address-text">{item._source.city}, {item._source.st}, {item._source.country}</span>
                                  </p> 
                                  <p className="jh-card-text">
                                    <i className="fa fa fa-calendar-o tiles-address-icon" aria-hidden="true"></i>
                                    <span>{moment(item._source.createddate).format('MM/DD/yyyy')}</span>
                                  </p>                                                                  
                                </div>
                                
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    )

  }
}



export default withStyles(useStyles)(Employment);


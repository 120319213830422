import React from 'react';


class Hospitals extends React.Component {
    render() {
      return (
      
<div>Top Hospitals</div>
            

       
      )
      
    }
  }
  
  

export default Hospitals;

import React, { useEffect } from "react";
import { useHistory, useParams, } from "react-router";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import {
  Button,
  Typography,
  DialogContent,
  TextField,
  FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "51%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  title: {
    marginLeft: theme.spacing(2),
    marginTop: 8,
    marginBottom : 32,
    flex: 1,
  },
  formButtonControl: {
    margin: theme.spacing(5),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    marginLeft: -10,
  },
  btnLoader:{
	  display:'flex',
	  alignItems:'center'
  },
  btnLoaderWrapper:{
	  margin:theme.spacing(1),
	  position:'relative',
	  width:'100%',
	  
	  
  },
  btnProgress:{
	  color:'inherit',
	  position:'absolute',
	  top:'50%',
	  left:'50%',
	  marginTop:-12,
	  marginLeft:-12,
  }
}));



function PostReply() {
  const [replyName, setReplyName] = React.useState("");
  const [replyMail, setReplyMail] = React.useState("");
  const [replyOTP, setReplyOTP] = React.useState("");
  const [otpMessage, setOtpMessage] = React.useState("");
  const [replyMessage, setReplyMessage] = React.useState("");
  const [discussionId, setDiscussionId] = React.useState(0);
  const [loading, setLoading] = React.useState(false);  
  const[postReplyInfo,setPostReplyInfo]=  React.useState({});
  const[postReplyErrors,setPostReplyErrors]=  React.useState<any>({});
  const history = useHistory();
  const { rowid } = useParams();

  var rowDataStorage : any = localStorage.getItem('rowData');
  var rowData = JSON.parse(rowDataStorage);

  console.log("state " + rowData);
  console.log("rowid " + rowid);

  useEffect(() => {
    setDiscussionId(parseInt(rowid, 10));
  }, []);
  const postReplyHandleChange=(e:any) =>{          
        var fields:any = postReplyInfo;        
        fields[e.target.name] = e.target.value;
        setPostReplyInfo(fields);      
  }
  const postReplyValidateForm=()=> {
        var fields :any= postReplyInfo;
        let errors = postReplyErrors;
			errors["username"] = "";			
        let formIsValid = true;
		if (!fields.username) {
            formIsValid = false;
            errors["username"]="*Please enter your name.";
        }
		if (!fields.useremail) {
            formIsValid = false;
            errors["useremail"]="*Please enter your email.";
        }
		let regexEmail= /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
         if (!regexEmail.test(fields.useremail)) {
          formIsValid = false;
          errors["useremail"]="*Please enter valid email.";
         }
		 if (!fields.description) {
            formIsValid = false;
            errors["description"]="*Please enter description.";
        }
		if (!fields.otp) {
            formIsValid = false;
            errors["otp"]="*Please enter OTP.";
        }
		setPostReplyErrors(errors);
       return formIsValid;
  }
  const submitPostReplyForm=(e:any)=>{	  
        e.preventDefault();
		if(postReplyValidateForm()){
			var fields :any= postReplyInfo;	
			var axiosConfig = {
			  headers: {		  
					 Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			};
			axios.post('https://api.jaiho.com/DiscussionReply', {
				discussionid: discussionId,
					username: fields.username,
					useremail: fields.useremail,
					message: fields.description,
					DiscussionOTP:fields.otp
			}, axiosConfig)
			.then((res) => {
			  console.log("RESPONSE RECEIVED: ", res);
			  if(res.data.errorHandler.success=="Y"){
				updateReply(rowData);
			  }else{		  
				  if(res.data.errorHandler.message=="NULLOTP"){
					  setOtpMessage("Enter OTP");
				  }else if(res.data.errorHandler.message=="InvalidOTP"){
					  setOtpMessage("InvalidOTP");
				  }else if(res.data.errorHandler.message=="SessionExp"){
					  setOtpMessage("OTP Expired.");
				  }
			  }
			})
			.catch((err) => {
			  console.log("AXIOS ERROR: ", err);
			}) 			
		}
  }
const generateOTP=()=>{
	setLoading(true);
	var fields:any=postReplyInfo;
	var axiosConfig = {
	  headers: {		  
			 Accept: 'application/json',
			'Content-Type': 'application/json'
	  }	  
	};
	axios.post('https://api.jaiho.com/DiscussionReplyOTP', {
		DiscussionID:discussionId,
		Useremail:fields.useremail
	}, axiosConfig)
	.then((res) => {
		setLoading(false);
		setOtpMessage("OTPSENT");		
	})
	.catch((err) => {
		setLoading(false);
	  console.log("AXIOS ERROR: ", err);
	})
}
  const handlePostReply = () => {
		  
	var axiosConfig = {
	  headers: {		  
			 Accept: 'application/json',
			'Content-Type': 'application/json'
		}
	};
	axios.post('https://api.jaiho.com/DiscussionReply', {
		discussionid: discussionId,
			username: replyName,
			useremail: replyMail,
			message: replyMessage,
			DiscussionOTP:replyOTP
	}, axiosConfig)
	.then((res) => {
	  console.log("RESPONSE RECEIVED: ", res);
	  if(res.data.errorHandler.success=="Y"){
		updateReply(rowData);
	  }else{		  
		  if(res.data.errorHandler.message=="NULLOTP"){
			  setOtpMessage("Enter OTP");
		  }else if(res.data.errorHandler.message=="InvalidOTP"){
			  setOtpMessage("InvalidOTP");
		  }else if(res.data.errorHandler.message=="SessionExp"){
			  setOtpMessage("OTP Expired.");
		  }
	  }
	})
	.catch((err) => {
	  console.log("AXIOS ERROR: ", err);
	}) 
};

  const updateReply = (data: any) => {
    console.log('rowData updateReply '+data);
    console.log('rowData updateReply '+discussionId);	
    console.log('rowData title '+data.title);    
    fetch("https://api.jaiho.com/Discussion/"+discussionId,{
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: discussionId,
        title: data.title,
        description: data.description,
        discussioncategoryid: data.discussioncategoryid,
        discussioncategoryname: data.discussioncategoryname,
        username: data.username,
        useremail: data.useremail,
        noofviews: 0,
        noofreplies: 1,
        isactive: data.isactive,
        createdby: data.createdby,
        createddate: data.createddate,
        modifiedby: data.modifiedby,
        modifieddate: data.modifieddate,
        
      }),
    }).then((response) => {
        history.push("/discussion");
        console.log("Update Reply " + JSON.stringify(response));
    }).catch((e) => {
        console.log("Post Reply error" + JSON.stringify(e));
    });
  }

  const classes = useStyles();
  return (
    <div className="d-flex" id="jh-wrapper">
      <div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
        <div className="row">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
			  <li className="breadcrumb-item">
                <Link to="/discussion">Message Board</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Post Reply
              </li>
            </ol>
          </nav>
        </div>
        <div className="jh-content-body">
          <div className="col-md-12 jh-events-title">
            <h2 className="jh-title-1">Post Reply</h2>
          </div>
          <div className="col-11 col-lg-11 col-md-11 col-sm-12">
            <div className="jh-events">
                <div className="jh-events-body">
					<div className="row">
						<div className="col-md-6 col-lg-6 col-12">
							<div className="row">
								<div className="col-12">
									<div className="form-group">
										<label>Name <small className="text-danger">*</small></label>
										<input type="text" className="form-control" name="username"  onChange={postReplyHandleChange}/>
									    <span className="text-danger">{postReplyErrors.username}</span>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<div className="form-group">
										<label>Email <small className="text-danger">*</small></label>
										<input type="email" className="form-control" name="useremail" onChange={postReplyHandleChange}/>
										<span className="text-danger">{postReplyErrors.useremail}</span>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<div className="form-group">
										<label>Description <small className="text-danger">*</small></label>
										<textarea className="form-control" name="description" cols={6} onChange={postReplyHandleChange}/>
										<span className="text-danger">{postReplyErrors.description}</span>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-8 col-lg-8 col-12">
									<div className="form-group">
										<label>OTP <small className="text-danger">*</small></label>
										<input type="text" className="form-control" name="otp" onChange={postReplyHandleChange}/>
										<span className="text-danger">{postReplyErrors.otp}</span>
									</div>
								</div>
								<div className="col-md-4 col-lg-4 col-12">
									<div className="form-group">										
										<button className="btn btn-primary btn-block" style={{marginTop:"25px"}} onClick={generateOTP}>
											{loading && <span className="spinner-border spinner-border-sm"></span>}GET OTP</button>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
								{otpMessage=='OTPSENT' && <div className="alert alert-success">
										OTP has been sent to your email
								</div>}
								{otpMessage=='InvalidOTP' && <div className="alert alert-danger">
										Invalid OTP
								</div>}
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 col-sm-6 col-lg-6 col-12">
									<button className="btn btn-primary btn-block" onClick={submitPostReplyForm}>Save</button>
								</div>
								<div className="col-md-6 col-sm-6 col-lg-6  col-12">
									<button className="btn btn-secondary btn-block">Cancel</button>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-6 col-12">						
						</div>
					</div>
                </div>
              </div>
            </div>
         </div>          
        </div>
      </div>    
  );
}

export default PostReply;
import React from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import Utils from './utils/homeutils';
//import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Skeleton from '@material-ui/lab/Skeleton';

import UsefulQuickLinks from "./homeapps/UsefulQuickLinks";
import {
  Button,
  Typography,
  TextField,
  FormControl,
} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as _ from 'lodash';
class UsefulLinks extends React.Component<{
  componentName: any,
  cityid: any,
  countryid: any,
  history: any
}, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      categories: [],
      links: [],
      loading: true,
      skelarray: ["1", "2", "3", "4", "5"],
      skels: ["1", "2", "3", "4", "5", "6"]
    }
  }
  async componentDidUpdate(prevProps: any, _prevState: any) {
    if (prevProps.cityid !== this.props.cityid) {
      let usefullinkscats = await Utils.GetUsefullinksCatItems(Number(this.props.countryid));
      let sortByUsefullinksCat = _.sortBy(usefullinkscats, (e) => {
        return e._source.position
      });
      let usefullinkItems = await Utils.GetUsefullinksItems(Number(this.props.countryid));
      this.setState({ categories: sortByUsefullinksCat, links: usefullinkItems, loading: false });
    }

  }
  async componentDidMount() {
    this.props.componentName("UsefulLinks");
    window.scrollTo({ top: 0, behavior: 'smooth' });
    let usefullinkscats = await Utils.GetUsefullinksCatItems(Number(this.props.countryid));
    let sortByUsefullinksCat = _.sortBy(usefullinkscats, (e) => {
      return e._source.position
    });
    let usefullinkItems = await Utils.GetUsefullinksItems(Number(this.props.countryid));
    this.setState({ categories: sortByUsefullinksCat, links: usefullinkItems, loading: false });
  }
  render() {
    const { history, countryid } = this.props;
    let { links, categories, loading, skelarray, skels } = this.state;
    return (
      <div className="d-flex" id="jh-wrapper">
        <div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
          <div className="row">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Useful Links</li>
              </ol>
            </nav>
          </div>
          <div className="jh-content-body">
            <div className="row">
              <div className="col-lg-12 jh-events-title">
                <h2 className="jh-title-1">Useful Links</h2>
              </div>
            </div>
            <div className="col-12">
              <div className="jh-events">
                <div className="jh-events-body">
                  {loading && <div className="row">
                    {skels.map((i: any) => (
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="jh-events-block">{/* <div className={"widget " + widgetcolor}> */}
                          <div className="jh-events-block-title">
                            <div className="row">
                              <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                <Skeleton height={30} />
                              </div>
                              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <Skeleton variant="circle" width={30} height={30} />
                              </div>
                            </div>
                            <ul className="jh-events-block-list">
                              {skelarray.map((i: any) => (
                                <li className="jh-events-block-list-item" key={i}>
                                  <Skeleton height={5} />
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>



                      </div>
                    ))}
                  </div>}

                  <div className="row eventscontentwrapper">
                    {categories.length===0 && <span>No Data Found</span>}
                    {categories.map((category: any) =>
                      category._source.isactive === true && links.filter((e: any) => e._source.isactive === true && e._source.categoryid === Number(category._source.id)).length > 0 &&
                      (<div className="col-lg-4 col-md-6 col-sm-6 col-12">

                        <UsefulQuickLinks
                          nodataHeight="160px"
                          history={history}
                          loading={loading}
                          catid={category._source.id}
                          items={links}
                          widgetIcon="fa fa-yelp"
                          widgettitle={category._source.title}
                          widgetcolor="widget-color-5"
                        ></UsefulQuickLinks>
                      </div>)
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  };
}


export default UsefulLinks;

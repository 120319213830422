import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

function AddBusiness(props:any){
    let fields = {fullName:'',country:'',state:'',city:'',companyName:'',phone:'',email:'', category:''}    
    const {countries,cities,states,successCallback,componentName, categories} = props; 

    // console.log("add your business Countries", countries);
    // console.log("add business categories", categories);
    // console.log("add business subcategories", subCategories);
    // ;  
    const [values, setValues] = useState(fields);
    const [errors, setErrors] = useState(fields);
    const [filteredStates,setFilteredStates] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);

    const filterStates = (countryId:any) => {
        setFilteredStates(states.filter((x:any)=>x.countryId==countryId));
    }

    const filterCities = (stateId:any) => {
        setFilteredCities(cities.filter((x:any)=>x.stateId==stateId));
    }

    useEffect(() => {
        componentName("AddBusiness");
    }, [componentName]);

    const apiendpoint=process.env.REACT_APP_API_Endpoint;

    const onSubmit=(e:any)=> {
        e.preventDefault();
        if(AddBusinessValidateForm()){
            axios.post(apiendpoint + 'addbusiness', {                
                fullName: values.fullName,
                countryId: Number(values.country),
                stateId: Number(values.state),
                cityId: Number(values.city),
                companyName: values.companyName,
                phone: values.phone,
                email: values.email,
                categoryId:Number(values.category)
            }).then(function (response) {
                if (response.status === 200) {
                    setValues(fields);
                    successCallback(true, "Your request han been successfully submitted!");
                } 
            })
            .catch(function (error) {
                console.log(error);
            });
        }        
    }

    const AddBusinessValidateForm = () => {        
        let regexEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        let formIsValid = true;
        let inputValues = values; 
        console.log(values)   ;

        setErrors(fields);
        let err = fields;
       
        if (inputValues.fullName.length <= 4) {
            formIsValid = false;
            err.fullName= "Full name atleast have 5 letters";
        }
        if (!inputValues.country) {
            formIsValid = false;
            err.country= "Please select Country";
        }
        if (!inputValues.state) {
            formIsValid = false;
            err.state = "*Please select state";
        }
        if (!inputValues.city) {
            formIsValid = false;
            err.city= "Please select city";
        }
        if (inputValues.companyName.length <= 10) {
            formIsValid = false;
            err.companyName = "company name atleast have 10 letters";
        }
        if (!inputValues.phone) {
            formIsValid = false;
            err.phone = "Please enter phone number";
        }
       
        if (!regexEmail.test(inputValues.email)) {
            formIsValid = false;
            err.email = "*Please enter valid email.";
        } 
        if (!inputValues.category) {
            formIsValid = false;
            err.category= "Please select category";
        }    
        setErrors(err) ;
        return formIsValid;
    }
    
  const handleOnChange = (event:any) => {
    event.persist();
    let name = event.target.name;
    let val = event.target.value;
    console.log(event.target);
    if(name==="country"){
        filterStates(val);
        setFilteredCities([]);
        setValues({
            ...values,
            state:'',
            city:'',
        });
    }
     if(name==="state"){
        filterCities(val);
        setValues({
            ...values,
            city:'',
        });
    }
   
    setValues({
            ...values,
            [name]:val,
        });
    
   
}

    return(      
          <div className="d-flex" id="jh-wrapper">
                <div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
                    <div className="row">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Add Your Business</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="about-us" style={{ padding: "0px" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="about-text">
                                        <h2>Add Your Business</h2>                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>    

                    <div className="row" style={{paddingLeft:'25px',paddingRight:'20px'}} >
                        <div className="col-md-4 col-lg-4 col-12">
                                        <div className="form-group">
                                            <label>Full Name <small className="text-danger">*</small></label>
                                            <input type="text" id="txtFullName" value={values.fullName} name="fullName" className="form-control" onChange={handleOnChange}/>
                                            <span className="text-danger">{errors.fullName}</span>
                                        </div>
                        </div>

                        <div className="col-md-4 col-lg-4 col-12">
                                <div className="form-group">
                                        <label>Country <small className="text-danger">*</small></label>
                                        <select id="ddlCountry" value={values.country} name="country" className="form-control city-dropdown"
                                            onChange={handleOnChange}>
                                            <option value="0"></option>
                                            {countries.map((value: any) => <option key={value.countryId} value={value.countryId}>{value.countryName}</option>)}
                                        </select>
                                        <span className="text-danger">{errors.country}</span>
                                </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-12">
                                <div className="form-group">
                                        <label>State <small className="text-danger">*</small></label>
                                        <select id="ddlState" value={values.state} name="state" className="form-control city-dropdown"
                                            onChange={handleOnChange}>
                                            <option value="0"></option>
                                            {filteredStates.map((value: any) => <option key={value.stateId} value={value.stateId}>{value.stateName}</option>)}
                                        </select>
                                        <span className="text-danger">{errors.state}</span>
                                </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-12">
                                <div className="form-group">
                                        <label>City<small className="text-danger">*</small></label>
                                        <select id="ddlCity" value={values.city} name="city" className="form-control city-dropdown"
                                            onChange={handleOnChange}>
                                            <option value="0"></option>
                                            {filteredCities.map((value: any) => <option key={value.cityId} value={value.cityId}>{value.cityName}</option>)}
                                        </select>
                                        <span className="text-danger">{errors.city}</span>
                                </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-12">
                                <div className="form-group">
                                    <label>Company Name <small className="text-danger">*</small></label>
                                    <input type="text" id="txtCompanyName" value={values.companyName} name="companyName" className="form-control" onChange={handleOnChange}/>
                                    <span className="text-danger">{errors.companyName}</span>
                                </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-12">
                                <div className="form-group">
                                    <label>Phone Number <small className="text-danger">*</small></label>
                                    <input type="text" id="txtPhone" value={values.phone} name="phone" className="form-control" onChange={handleOnChange}/>
                                    <span className="text-danger">{errors.phone}</span>
                                </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-12">
                                <div className="form-group">
                                    <label>E-Mail Address<small className="text-danger">*</small></label>
                                    <input type="text" id="txtEmail" value={values.email} name="email" className="form-control" onChange={handleOnChange}/>
                                    <span className="text-danger">{errors.email}</span>
                                </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-12">
                                <div className="form-group">
                                        <label>Category <small className="text-danger">*</small></label>
                                        <select id="ddlCategories" value={values.category} name="category" className="form-control city-dropdown"
                                            onChange={handleOnChange}>
                                            <option value="0"></option>
                                            {categories.map((value: any) => <option key={value._source.id} value={value._source.id}>{value._source.title}</option>)}
                                        </select>
                                        <span className="text-danger">{errors.category}</span>
                                </div>
                        </div>
                    </div>   
                    <br/>
                    <div style={{width:'20%', margin:'auto'}} >
                        <button id="btnSubmit" className="btn btn-primary btn-block" onClick={onSubmit}>Submit</button>
                    </div>  
                    
            </div>
        </div>
    )
}

export default AddBusiness;
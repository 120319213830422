import React from 'react';
import MUIRichTextEditor from 'mui-rte'
import AsyncImageUpload from '../components/custom/RichText';
import Utils from './utils/homeutils';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';



class EmploymenetEntity extends React.Component<{
    componentName: any,
    history: any,
    match: any
}, {
    country: any,
    st: any,
    city: any,
    company: any,
    email: any,
    contactnumber: any,
    jobrole: any,
    industry: any,
    experience: any,
    description: any
}> {
    constructor(props: any) {
        super(props);
        this.state = {
            country: '',
            st: '',
            city: '',
            company: '',
            email: '',
            contactnumber: '',
            jobrole: '',
            industry: '',
            experience: '',
            description: ''
        }
    }
    GoToHome = () => {
        this.props.history.push("/");
    }
    async componentDidUpdate(prevProps: any, _prevState: any) {
        let entityID = this.props.match.params.id;
        if (prevProps.match.params.id === this.props.match.params.id)
            return;
        entityID = this.props.match.params.id;
        let objSelectedEntity = await Utils.GetRequirementSingleEntity(Number(entityID));
        this.setState({
            country: objSelectedEntity[0]._source.country,
            st: objSelectedEntity[0]._source.st,
            city: objSelectedEntity[0]._source.city,
            company: objSelectedEntity[0]._source.companyname,
            contactnumber: objSelectedEntity[0]._source.contactnumber,
            email: objSelectedEntity[0]._source.email,
            jobrole: objSelectedEntity[0]._source.title,
            industry: objSelectedEntity[0]._source.industry,
            experience: objSelectedEntity[0]._source.experience,
            description: objSelectedEntity[0]._source.jobdescription
        });
       // let objSelectedEntityPG = await Utils.GetSingleEntityFromPG(Number(entityID));
       // this.setState({ description: objSelectedEntityPG[0].description });
    }
    async componentDidMount() {
        this.props.componentName("Entity");
        let entityID = this.props.match.params.id;
        /*let elkDataFromCache = null;
        elkDataFromCache = localStorage.getItem("ElkResults");
        let elkData = [];
        if (elkDataFromCache === null) {
            elkData = await Utils.GetAllItems("Bangalore",44);
            console.log(elkData);
            localStorage.setItem("ElkResults", JSON.stringify(elkData));
        } else {
            elkData = JSON.parse(elkDataFromCache);
        }
        console.log(elkData);
        let objSelectedEntity = elkData.filter(function (elkItem: any) { return elkItem._source.id === Number(entityID) });*/
        let objSelectedEntity = await Utils.GetRequirementSingleEntity(Number(entityID));
        this.setState({
            country: objSelectedEntity[0]._source.country,
            st: objSelectedEntity[0]._source.st,
            city: objSelectedEntity[0]._source.city,
            company: objSelectedEntity[0]._source.companyname,
            contactnumber: objSelectedEntity[0]._source.contactnumber,
            email: objSelectedEntity[0]._source.email,
            jobrole: objSelectedEntity[0]._source.title,
            industry: objSelectedEntity[0]._source.industry,
            experience: objSelectedEntity[0]._source.experience,
            description: objSelectedEntity[0]._source.jobdescription
        });
       // let objSelectedEntityPG = await Utils.GetSingleEntityFromPG(Number(entityID));
       // this.setState({ description: objSelectedEntityPG[0].description });
    }
    private GoToYellowpages = (cat: any, catid: any) => {
        //const { history } = this.props;
        this.props.history.push("/yellowpages/" + cat + "/" + catid + "");
    }
    render() {
        let { company, jobrole, country, st, city, email,contactnumber, industry, experience, description} = this.state;
        return (
            <div className="d-flex" id="jh-wrapper">
                <div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
                    <div className="row">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/"}>Home</Link></li>
                                <li className="breadcrumb-item"><Link to={"/employment"}>Employment</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{company} - {jobrole}</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-12 jh-main-content jh-inner-page-main-content">
                        <div className="row jh-main-content-block">
                            <div className="jh-card-page-title-block">
                                <h4 className="jh-card-page-title">{company} - {jobrole}</h4>
                            </div>
                        </div>
                        <div className="row jh-main-content-block">
                            <div className="card-section">
                                <div className="card">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12 jh-card-address-blocks">
                                                    <div className="jh-card-address-block">
                                                        <div className="jh-card-address-block-left">
                                                            <p className="jh-card-address-block-title"><b>Location</b></p>
                                                        </div>
                                                        <div className="jh-card-address-block-right jh-card-address-text">
                                                            <p>{city}, {st}, {country}</p>                                                            
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="jh-card-address-block">
                                                        <div className="jh-card-address-block-left">
                                                            <p className="jh-card-address-block-title"><b>Contact</b></p>
                                                        </div>
                                                        <div className="jh-card-address-block-right">
                                                            <div className="jh-card-address-block-inner">
                                                                <div className="jh-card-address-block-icons">
                                                                    <i className="fa fa-phone"></i>
                                                                </div>
                                                                <div className="jh-card-address-block-details">
                                                                    <p><a href={'tel:' + contactnumber}>{contactnumber}</a></p>
                                                                </div>
                                                            </div>                                                            
                                                            <div className="jh-card-address-block-inner">
                                                                <div className="jh-card-address-block-icons">
                                                                    <i className="fa fa-envelope"></i>
                                                                </div>
                                                                <div className="jh-card-address-block-details">
                                                                    <p>
                                                                        <a href={'mailto:' + email}>{email}</a>
                                                                    </p>
                                                                </div>

                                                            </div>
                                                            


                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="jh-card-address-block">
                                                        <div className="jh-card-address-block-left">
                                                            <p className="jh-card-address-block-title"><b>Details</b></p>
                                                        </div>
                                                        <div className="jh-card-address-block-right">
                                                            <div className="jh-card-address-block-inner">
                                                                <div className="jh-card-address-block-icons">
                                                                    <i className="fa fa-chevron-circle-right"></i>
                                                                </div>
                                                                <div className="jh-card-address-block-details">
                                                                    <p>{jobrole}</p>
                                                                </div>
                                                            </div>
                                                            <div className="jh-card-address-block-inner">
                                                                <div className="jh-card-address-block-icons">
                                                                    <i className="fa fa-list-ol"></i>
                                                                </div>
                                                                <div className="jh-card-address-block-details">
                                                                    <p>{experience}</p>
                                                                </div>
                                                            </div>
                                                            <div className="jh-card-address-block-inner">
                                                                <div className="jh-card-address-block-icons">
                                                                    <i className="fa fa-industry"></i>
                                                                </div>
                                                                <div className="jh-card-address-block-details">
                                                                    <p>{industry}</p>
                                                                </div>
                                                            </div>
                                                        </div>                                                        
                                                    </div>
                                                    <hr />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {ReactHtmlParser(description)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        )







    }
}



export default EmploymenetEntity;

import React from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import Utils from './utils/homeutils';
import * as _ from 'lodash';

class MainCategories extends React.Component<{
	componentName: any,
	categories:any[],
	match:any	
},{
	items:any[],
	categoryName:any
}> {
    constructor(props: any) {
        super(props); 
		this.state={
			items:[],
			categoryName:""
		};	
    }
    async componentDidUpdate(prevProps: any, _prevState: any) {        
        let entityID = this.props.match.params.id;
		let entityName=this.props.match.params.name;
        if (prevProps.match.params.id === this.props.match.params.id)
            return;
		let filteredCategories = this.props.categories.filter(function (elkCat: any) { return elkCat._source.categoryid===entityID && elkCat._source.isactive === true && elkCat._source.recordtype==='Business'});
		let sortByCategories = _.sortBy(filteredCategories, (e) => {
			return e._source.title
		});	
        this.setState({items:sortByCategories,categoryName:entityName});
       
    }

    async componentDidMount() {  
	    this.props.componentName("categories");	
        let entityID = this.props.match.params.id;  
        let entityName=this.props.match.params.name;
        let filteredCategories = this.props.categories.filter(function (elkCat: any) { return elkCat._source.id===entityID && elkCat._source.isactive === true && elkCat._source.recordtype==='Business'});
		let sortByCategories = _.sortBy(filteredCategories, (e) => {
			return e._source.title
		});	
        this.setState({items:sortByCategories,categoryName:entityName});		
    }
   
    render() { 
        let { items,categoryName} = this.state;        
        return (
            <div className="d-flex" id="jh-wrapper">                
                <div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
				 <div className="row">
				  <nav aria-label="breadcrumb">
					<ol className="breadcrumb">
					  <li className="breadcrumb-item"><a href="#">Home</a></li>
					  <li className="breadcrumb-item">
					  <a href="#">Yellow Pages</a></li>
					  	
<li className="breadcrumb-item active" aria-current="page">{categoryName}</li>					  
					</ol>
				  </nav>
				</div>
                <div className="col-12 jh-main-content jh-inner-page-main-content"> 
					<div className="row jh-main-content-block">
                            <div className="jh-card-page-title-block">
                                <h4 className="jh-card-page-title">{categoryName}</h4>
                            </div>
                    </div>				
                    <div className="row jh-main-content-block">
                        <div className="col-12">
						{items.length==0 && <div>No Data Found</div>}
                            <div className="card-columns jh-card-columns">
                                    {items.map(function (item) {
                                        return (
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title"><a href="#">{item._source.title}
                                        </a> </h5>
										</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            
                            </div>                        
                                </div>
                    </div>
                </div>
                
                
            </div>



        )







    }
}



export default MainCategories;

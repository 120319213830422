import React from 'react';

import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';

import aboutuslink from '../img/aboutus.jpg';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import Utils from '../components/utils/homeutils';
import axios from 'axios';
import * as _ from 'lodash';
var DatePicker = require('react-datepicker');
const theme = createMuiTheme({
    overrides: {
        MuiFormControl: {
            marginNormal: {
                marginTop: "5px !important",
                marginBottom: 0
            }
        }
    }
});
const apiendpoint=process.env.REACT_APP_API_Endpoint;
class Advertisements extends React.Component<{
    componentName: any,successCallback:any, countries: any[], states: any[],
    cities: any[], categories: any[], subcategories: any[], advertisementtypes: any[]
},
    any>{

    constructor(props: any) {
        super(props);
        this.state = {
            IsBusinessModelOpen: false,
            IsBusinessModelSelected: false,
            Cities: [],
            States: [],
            Categories: [],
            Subcategories: [],
            Countries: [],
            AdvertiseFields: {},
            AdvertiseErrors: {},
            AdvertisementTypes: []

        }
        this.AdvertiseHandleChange = this.AdvertiseHandleChange.bind(this);
        this.submitAdvetisementForm = this.submitAdvetisementForm.bind(this)
    }
    async componentDidMount() {
        this.props.componentName("Advertise");
        let fields = this.state.AdvertiseFields;
        fields["startdate"] = null;
        fields["enddate"] = null;
               
        this.setState({ Countries: this.props.countries, States: this.props.states, Cities: this.props.cities, 
            Categories: this.props.categories, Subcategories: this.props.subcategories, 
            AdvertisementTypes: this.props.advertisementtypes,fields});
    }
    BusinessModelClose = () => {
        this.setState({ IsBusinessModelOpen: false });
    }
    AddAdvertise = () => {
        this.setState({ IsBusinessModelSelected: true, IsBusinessModelOpen: true });
    }

    AdvertiseHandleChange = (e: any) => {
        let fields = this.state.AdvertiseFields;
        fields[e.target.name] = e.target.value;
        if(e.target.name==="country")
        fields["countryname"]=e.target.selectedOptions[0].text;
        if(e.target.name==="state")
        fields["statename"]=e.target.selectedOptions[0].text;
        if(e.target.name==="city")
        fields["cityname"]=e.target.selectedOptions[0].text;
        if(e.target.name==="category")
        fields["categoryname"]=e.target.selectedOptions[0].text;
        if(e.target.name==="subcategory")
        fields["subcategoryname"]=e.target.selectedOptions[0].text;
        if(e.target.name==="advtype")
        fields["advtypename"]=e.target.selectedOptions[0].text;
        this.setState({
            fields
        });
    }
    AdvertiseDateHandleChange = (e: any) => {
        let fields = this.state.AdvertiseFields;
        fields["startdate"] = e;
        this.setState({
            fields
        });
    }
    AdvertiseEndDateHandleChange = (e: any) => {
        let fields = this.state.AdvertiseFields;
        fields["enddate"] = e;
        this.setState({
            fields
        });
    }
    submitAdvetisementForm = (e: any) => {
        let mainSate = this;
        e.preventDefault();
        if (this.AdvertisementValidateForm()) {
            axios.post(apiendpoint+"Bizrequest", {
                CountryID: Number(this.state.AdvertiseFields.country),
                StateID: Number(this.state.AdvertiseFields.state),
                CityID: Number(this.state.AdvertiseFields.city),
                Country: this.state.AdvertiseFields.countryname,
                StateName: this.state.AdvertiseFields.statename,
                CityName: this.state.AdvertiseFields.cityname,
                Name: this.state.AdvertiseFields.uname,
                Email: this.state.AdvertiseFields.email,
                Phone: this.state.AdvertiseFields.phone,
                AreaCode: this.state.AdvertiseFields.areacode,
                BusinessName: this.state.AdvertiseFields.business,
                CategoryID: Number(this.state.AdvertiseFields.category),
                SubcategoryID: Number(this.state.AdvertiseFields.subcategory),
                Category: this.state.AdvertiseFields.categoryname,
                Subcategory: this.state.AdvertiseFields.subcategoryname,
                AdvertisementType: Number(this.state.AdvertiseFields.advtype),
                Advertisement: this.state.AdvertiseFields.advtypename,
                Startdate: this.state.AdvertiseFields.startdate,
                Enddate: this.state.AdvertiseFields.enddate,
                Comments: this.state.AdvertiseFields.comments
            }).then(function (response) {
                if (response.data.statusCode === 200) {
                    // mainSate.props.successCallback(true);	
                } else {
                    //mainScope.setState({ RegistrationStatus: "Failed" });
                }
            })
                .catch(function (error) {
                    console.log(error);
                });
            let fields = this.state.AdvertiseFields;
            fields["country"] = "";
            fields["state"] = "";
            fields["city"] = "";
            fields["uname"] = "";
            fields["email"] = "";
            fields["phone"] = "";
            fields["areacode"] = "";
            fields["business"] = "";
            fields["category"] = "";
            fields["subcategory"] = "";
            fields["advtype"] = "";
            fields["startdate"] = "";
            fields["enddate"] = "";
            fields["comments"] = "";
            this.setState({ contactFields: fields,IsBusinessModelOpen:false,IsBusinessModelSelected:false });
            mainSate.props.successCallback(true, "Your request han been successfully submitted!");

        }
    }
    AdvertisementValidateForm = () => {
        let fields = this.state.AdvertiseFields;
        let errors = this.state.AdvertiseErrors;
        errors["country"] = "";
        errors["state"] = "";
        errors["city"] = "";
        errors["uname"] = "";
        errors["email"] = "";
        errors["phone"] = "";
        errors["areacode"] = "";
        errors["business"] = "";
        errors["category"] = "";
        errors["subcategory"] = "";
        errors["advtype"] = "";
        errors["startdate"] = "";
        errors["enddate"] = "";
        errors["comments"] = "";
        let formIsValid = true;
        if (!fields.country) {
            formIsValid = false;
            errors["country"] = "*Please select country.";
        }
        if (!fields.state) {
            formIsValid = false;
            errors["state"] = "*Please select state.";
        }
        if (!fields.city) {
            formIsValid = false;
            errors["city"] = "*Please select city.";
        }
        if (!fields.uname) {
            formIsValid = false;
            errors["uname"] = "*Please enter your name.";
        }
        if (!fields.email) {
            formIsValid = false;
            errors["email"] = "*Please enter your email.";
        }
        let regexEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!regexEmail.test(fields.email)) {
            formIsValid = false;
            errors["email"] = "*Please enter valid email.";
        }
        if (!fields.phone) {
            formIsValid = false;
            errors["areacode"] = "*Please enter your area code.";
        }
        if (!fields.phone) {
            formIsValid = false;
            errors["phone"] = "*Please enter your phone number.";
        }
        let regexPhone = /^[0-9]*$/;
        if (!regexPhone.test(fields.phone)) {
            formIsValid = false;
            errors["phone"] = "*Please enter valid phone number.";
        }
        if (!fields.business) {
            formIsValid = false;
            errors["business"] = "*Please enter your business name.";
        }
        if (!fields.category) {
            formIsValid = false;
            errors["category"] = "*Please select category.";
        }
        if (!fields.subcategory) {
            formIsValid = false;
            errors["subcategory"] = "*Please select subcategory.";
        }
        if (!fields.advtype) {
            formIsValid = false;
            errors["advtype"] = "*Please select advertisement type.";
        }
        if (!fields.startdate) {
            formIsValid = false;
            errors["startdate"] = "*Please select start date.";
        }
        if (!fields.enddate) {
            formIsValid = false;
            errors["enddate"] = "*Please select end date.";
        }
        if (!fields.comments) {
            formIsValid = false;
            errors["comments"] = "*Please enter your message.";
        }
        this.setState({
            contactErrors: errors
        });
        return formIsValid;
    }
    ClearForm = () => {
        let fields = this.state.AdvertiseFields;
        let errors = this.state.AdvertiseErrors;
        fields["country"] = "";
        fields["state"] = "";
        fields["city"] = "";
        fields["uname"] = "";
        fields["email"] = "";
        fields["areacode"] = "";
        fields["phone"] = "";
        fields["business"] = "";
        fields["category"] = "";
        fields["subcategory"] = "";
        fields["advtype"] = "";
        fields["startdate"] = null;
        fields["enddate"] = null;        
        fields["comments"] = "";
        errors["country"] = "";
        errors["state"] = "";
        errors["city"] = "";
        errors["uname"] = "";
        errors["email"] = "";
        errors["areacode"] = "";
        errors["phone"] = "";
        errors["business"] = "";
        errors["category"] = "";
        errors["subcategory"] = "";
        errors["advtype"] = "";
        errors["startdate"] = "";
        errors["enddate"] = "";        
        errors["comments"] = "";       
        this.setState({ AdvertiseFields: fields, AdvertiseErrors: errors });
    }

    render() {

        return (
            <div className="d-flex" id="jh-wrapper">
                {this.state.IsBusinessModelOpen && <div><Dialog open={this.state.IsBusinessModelOpen} maxWidth='md' fullWidth={true} onClose={this.BusinessModelClose} aria-labelledby="form-dialog-title">
                    <DialogTitle classes={{ root: 'jh-franchise-dailog-title' }} id="customized-dialog-title">
                        <span>Create Advertisement Request</span><span className="pull-right" onClick={this.BusinessModelClose}><i className="fa fa-times jh-mdl-appdownload-close" /></span>
                    </DialogTitle> 

                    <DialogContent>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="form-group">
                                        <label>Country <small className="text-danger">*</small></label>
                                        <select id="ddlCountry" value={this.state.AdvertiseFields.country} name="country" className="form-control city-dropdown"
                                            onChange={this.AdvertiseHandleChange}>
                                            <option value="0"></option>
                                            {this.state.Countries.map((value: any) => <option value={value._source.id}>{value._source.title}</option>)}
                                        </select>
                                        <span className="text-danger">{this.state.AdvertiseErrors.country}</span>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="form-group">
                                        <label>State <small className="text-danger">*</small></label>
                                        <select id="ddlState" value={this.state.AdvertiseFields.state} name="state" className="form-control city-dropdown"
                                            onChange={this.AdvertiseHandleChange}>
                                            <option value="0"></option>
                                            {this.state.States.filter((e: any) => e._source.countryid === Number(this.state.AdvertiseFields.country)).map((value: any) => <option value={value._source.id}>{value._source.title}</option>)}
                                        </select>
                                        <span className="text-danger">{this.state.AdvertiseErrors.state}</span>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="form-group">
                                        <label>City <small className="text-danger">*</small></label>
                                        <select id="ddlCity" value={this.state.AdvertiseFields.city} name="city" className="form-control" onChange={this.AdvertiseHandleChange}>
                                            <option value="0"></option>
                                            {this.state.Cities.filter((e: any) => e._source.stateid === Number(this.state.AdvertiseFields.state)).map((value: any) => <option value={value._source.id}>{value._source.title}</option>)}
                                        </select>
                                        <span className="text-danger">{this.state.AdvertiseErrors.city}</span>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="form-group">
                                        <label>Name <small className="text-danger">*</small></label>
                                        <input type="text" id="txtName" value={this.state.AdvertiseFields.uname} name="uname" className="form-control" onChange={this.AdvertiseHandleChange} />
                                        <span className="text-danger">{this.state.AdvertiseErrors.uname}</span>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="form-group">
                                        <label>Email <small className="text-danger">*</small></label>
                                        <input type="email" id="txtEmail" value={this.state.AdvertiseFields.email} name="email" className="form-control" onChange={this.AdvertiseHandleChange} />
                                        <span className="text-danger">{this.state.AdvertiseErrors.email}</span>
                                    </div>
                                </div>
                                <div className="col-md-2 col-lg-2 col-12">
                                    <div className="form-group">
                                        <label>Area Code <small className="text-danger">*</small></label>
                                        <input type="text" id="txtAreaCode" value={this.state.AdvertiseFields.areacode} name="areacode" className="form-control" onChange={this.AdvertiseHandleChange} />
                                        <span className="text-danger">{this.state.AdvertiseErrors.areacode}</span>
                                    </div>
                                </div>
                                <div className="col-md-2 col-lg-2 col-12">
                                    <div className="form-group">
                                        <label>Phone <small className="text-danger">*</small></label>
                                        <input type="text" id="txtPhone" value={this.state.AdvertiseFields.phone} name="phone" className="form-control" onChange={this.AdvertiseHandleChange} />
                                        <span className="text-danger">{this.state.AdvertiseErrors.phone}</span>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="form-group">
                                        <label>Business Name <small className="text-danger">*</small></label>
                                        <input type="text" id="txtBusiness" value={this.state.AdvertiseFields.business} name="business" className="form-control" onChange={this.AdvertiseHandleChange} />
                                        <span className="text-danger">{this.state.AdvertiseErrors.business}</span>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="form-group">
                                        <label>Category <small className="text-danger">*</small></label>
                                        <select id="ddlCategory" value={this.state.AdvertiseFields.category} name="category" className="form-control city-dropdown"
                                            onChange={this.AdvertiseHandleChange}>
                                            <option value="0"></option>
                                            {this.state.Categories.map((value: any) => <option value={value._source.id}>{value._source.title}</option>)}
                                        </select>
                                        <span className="text-danger">{this.state.AdvertiseErrors.category}</span>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="form-group">
                                        <label>Sub Category <small className="text-danger">*</small></label>
                                        <select id="ddlSubcategory" value={this.state.AdvertiseFields.subcategory} name="subcategory" className="form-control city-dropdown"
                                            onChange={this.AdvertiseHandleChange}>
                                            <option value="0"></option>
                                            {this.state.Subcategories.filter((e: any) => e._source.categoryid === Number(this.state.AdvertiseFields.category)).map((value: any) => <option value={value._source.id}>{value._source.title}</option>)}
                                        </select>
                                        <span className="text-danger">{this.state.AdvertiseErrors.subcategory}</span>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="form-group">
                                        <label>Advertisement Type <small className="text-danger">*</small></label>
                                        <select id="ddlAdvtype" value={this.state.AdvertiseFields.advtype} name="advtype" className="form-control city-dropdown"
                                            onChange={this.AdvertiseHandleChange}>
                                            <option value="0"></option>
                                            {this.state.AdvertisementTypes.map((value: any) => <option value={value._source.id}>{value._source.title}</option>)}
                                        </select>
                                        <span className="text-danger">{this.state.AdvertiseErrors.advtype}</span>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="form-group">
                                        <label>Start Date <small className="text-danger">*</small></label>
                                        <ThemeProvider theme={theme}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    fullWidth
                                                    variant="dialog"
                                                    format="dd/MM/yyyy"
                                                    margin="normal"
                                                    id="date-picker-inline"
                                                    label=""
                                                    value={this.state.AdvertiseFields.startdate}
                                                    onChange={this.AdvertiseDateHandleChange}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </ThemeProvider>
                                        <span className="text-danger">{this.state.AdvertiseErrors.startdate}</span>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="form-group">
                                        <label>End Date <small className="text-danger">*</small></label>
                                        <ThemeProvider theme={theme}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    fullWidth
                                                    variant="dialog"
                                                    format="dd/MM/yyyy"
                                                    margin="normal"
                                                    id="date-picker-inline1"
                                                    label=""
                                                    value={this.state.AdvertiseFields.enddate}
                                                    onChange={this.AdvertiseEndDateHandleChange}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </ThemeProvider>
                                        <span className="text-danger">{this.state.AdvertiseErrors.enddate}</span>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Additional Information <small className="text-danger">*</small></label>
                                        <textarea id="txtAdditionalInformation" value={this.state.AdvertiseFields.comments} maxLength={1000} name="comments" className="form-control" cols={3} rows={3} onChange={this.AdvertiseHandleChange}></textarea>
                                        <span className="text-danger">{this.state.AdvertiseErrors.comments}</span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <button className="btn btn-primary btn-block" onClick={this.submitAdvetisementForm}>Submit</button>
                                </div>
                                <div className="col-6">
                                    <button className="btn btn-secondary btn-block" onClick={this.ClearForm}>Cancel</button>
                                </div>
                            </div>

                        </div>
                    </DialogContent>
                    <DialogActions>




                    </DialogActions>
                </Dialog>
                </div>}
                <div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
                    <div className="row">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Advertise</li>
                            </ol>
                        </nav>
                    </div>

                    <div className="advertise-us">
                        <div className="container">
                            <div className="row">

                                <div className="advertise-text">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-8 col-lg-8">
                                                <h2 className="jh-title-1">Advertise on JaiHo</h2>
                                            </div>
                                            <div className="col-md-4 col-lg-4">
                                                <button className="btn btn-primary btn-block" onClick={this.AddAdvertise}>Advertise Your Business</button>
                                            </div>
                                        </div>

                                        <div className="jh-paragraph">
                                            <p>
                                                JaiHo.com offers multiple options to promote your business or event.
                                                Our banner sizes are standard GIF files.
                                            </p><p>
                                                Rates may vary by type of promotion, position and is entirely at the discretion on jaiho.com.
                                                Invoices will be provided for payment.

                                                For more information please e-mail us <a href="mailto:info@jaiho.com">info@jaiho.com</a>.
                                            </p>

                                        </div>
                                    </div>


                                </div>
                                <div className="advertise-text">
                                    <div className="col-md-12">
                                        <h2 className="jh-title-8">Text Links in Home Page</h2>
                                        <div className="jh-paragraph mb-2">
                                            <p>There are several options to advertise as a "Text link" under the following titles:</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-lg-8 jh-advertise-categories">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-6 col-sm-6 widget-color-1">
                                                        <div className="widget-header widget-header-advertise">
                                                            C1. Dining/Resturants
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-6 widget-color-10">
                                                        <div className="widget-header widget-header-advertise">
                                                            C2. Professional Services
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-6 widget-color-3">
                                                        <div className="widget-header widget-header-advertise">
                                                            C3. Grocery
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-6 widget-color-5">
                                                        <div className="widget-header widget-header-advertise">
                                                            C4. Real Estate
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-6 widget-color-7">
                                                        <div className="widget-header widget-header-advertise">
                                                            C5. Eductaion
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-6 widget-color-2">
                                                        <div className="widget-header widget-header-advertise">
                                                            C6. Saloon/SPA
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-6 widget-color-6">
                                                        <div className="widget-header widget-header-advertise">
                                                            C7. Jewellary
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-6 widget-color-11">
                                                        <div className="widget-header widget-header-advertise">
                                                            C8. Events
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-6 widget-color-9">
                                                        <div className="widget-header widget-header-advertise">
                                                            C9. Automotive
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-6 widget-color-4">
                                                        <div className="widget-header widget-header-advertise">
                                                            C10. Fashions
                                                        </div>  
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-6">
                                                <div className="jh-advertise-gif-dim-block">
                                                    <div className="jh-advertise-gif-dim-img">
                                                        <span className="jh-advertise-gif-dim-img-4">300 x 250</span>
                                                    </div>
                                                    <div className="jh-advertise-gif-dim-info">
                                                        <a className="jh-advertise-gif-text-3" target="_blank" href={aboutuslink}>Reference Image</a>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>


                                <div className="col-12">
                                    <h6 className="jh-title-8">Digital GIF Banner in Home Page</h6>
                                </div>
                                <div className="col-12 col-lg-4 col-md-6 col-sm-6">
                                    <div className="jh-advertise-gif-dim-block">
                                        <div className="jh-advertise-gif-dim-img">
                                            <span className="jh-advertise-gif-dim-img-1">315 x 250</span>
                                        </div>
                                        <div className="jh-advertise-gif-dim-info">
                                            <a className="jh-advertise-gif-text-3" target="_blank" href={aboutuslink}>Reference Image</a>
                                            <p className="jh-advertise-gif-text-2">Size <b>315 x 250</b> pixels</p>
                                            <h4 className="jh-advertise-gif-text-1"><b>D2. </b>Home Page Bottom Banner</h4>

                                        </div>
                                    </div>


                                </div>
                                <div className="col-12 col-lg-4 col-md-6 col-sm-6">
                                    <div className="jh-advertise-gif-dim-block">
                                        <div className="jh-advertise-gif-dim-img">
                                            <span className="jh-advertise-gif-dim-img-2">250 x 60</span>
                                        </div>
                                        <div className="jh-advertise-gif-dim-info">
                                            <a className="jh-advertise-gif-text-3" target="_blank" href={aboutuslink}>Reference Image</a>
                                            <p className="jh-advertise-gif-text-2">Size <b>250 x 60</b> pixels</p>
                                            <h4 className="jh-advertise-gif-text-1"><b>D1. </b> Home Page Header banner</h4>

                                        </div>
                                    </div>


                                </div>
                                <div className="col-12 col-lg-4 col-md-6 col-sm-6">
                                    <div className="jh-advertise-gif-dim-block">
                                        <div className="jh-advertise-gif-dim-img">
                                            <span className="jh-advertise-gif-dim-img-3">110 x 60</span>
                                        </div>
                                        <div className="jh-advertise-gif-dim-info">
                                            <a className="jh-advertise-gif-text-3" target="_blank" href={aboutuslink}>Reference Image</a>
                                            <p className="jh-advertise-gif-text-2">Size <b>110 x 60</b> pixels</p>
                                            <h4 className="jh-advertise-gif-text-1"><b>D3 </b> Home Page Right Side Banners</h4>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div >
        );
    }
}



export default Advertisements;

import React from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import Precident from '../img/DrKrishna.jpeg';
class Awards extends React.Component<{
    componentName: any
}> {
    constructor(props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.componentName("Awards");
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    render() {
        return (
            <div className="d-flex" id="jh-wrapper">
                <div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
                    <div className="row">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Awards</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="about-us" style={{ padding: "0px" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="about-text">
                                        <h2>Awards</h2>
                                        <p>
                                            JaiHo is India’s fastest growing, very influential gathering of the country’s finest minds in various business domain such as Food Chain/ Restaurant/Hotels /Malls/ Provision Stores etc.….  creation, distribution, marketing and retailing. Launched on Jan 1st 2021, the annual business event will be a powerful showcase of ground-breaking insights, analyses, products, technologies, emerging innovations, trends and developments, in the business sector in India. Every year, JaiHo will – through a conference, master classes and specialized exhibition zones — presents and analyses unique research findings, consumption and retail trends various business domains.  The event puts together the most comprehensive capsule of trends and innovations that affect businesses engaged in the all aspects of the costumer retail value chain – from sourcing ingredients to retailing.
                            </p>
                                        <p>
                                            JaiHo is inviting nominations for awards to honor the excellence and achievement of various Business Categories in following fields and also selected people from news media who is innovative, adventurous, has helping hands or help community, best teacher, high yield farmer, best Citizen, good police,  soldier/army men who is protecting us at the border.
                            </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="about-us" style={{ padding: "0px" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="about-text">
                                        <h2>Personal Awards</h2>
                                        <p>
                                            <ol className="jh-about-list">
                                                <li>
                                                    JaiHo’s Best Citizen award of the year
                                    </li>
                                                <li>
                                                    JaiHo’s Best Police award of the year 2020
                                    </li>
                                                <li>
                                                    JaiHo’s Best Farmer award of the year
                                    </li>
                                                <li>
                                                    JaiHo’s Best Soldier award of the year
                                    </li>
                                                <li>
                                                    JaiHo’s Best Teacher award of the year
                                    </li>
                                            </ol>
                                        </p>
                                    </div>
                                    <div className="about-text">
                                        <h2>Business Awards</h2>
                                        <p>
                                            <ol className="jh-about-list">
                                                <li>
                                                    JaiHo’s Best School award of the year
                                    </li>
                                                <li>
                                                    JaiHo’s Best College award of the year
                                    </li>
                                                <li>
                                                    JaiHo’s Best Restaurant award of the year
                                    </li>
                                                <li>
                                                    JaiHo’s Best Retail Business award of the year
                                    </li>
                                                <li>
                                                    JaiHo’s Best Hospital award of the year
                                    </li>
                                            </ol>
                                        </p>
                                    </div>
                                    <div className="about-text">
                                        <p>
                                            JaiHo is an all-access platform to connect with every component that a business needs — from rich market knowledge to consumer research to upcoming product innovation and retail technology trends to smart business networking. It is the one source for every insight you need to boost not just for business growth, but also profitability.
                                        </p>
                                        <p>
                                            JaiHo brings together trends, data, research and analyses to create an unparalleled knowledge source with inputs from some of the world’s most respected consulting and/or market study organizations, retail business heads, brand analysts and consumer research companies and most importantly direct for the retail consumers.
                                        </p>
                                        <p>The award will be offered to most extensive mapped, business models of the future, success stories created by local brands, benchmark figures on consumer behavior across key business verticals.</p>
                                        <p>This will be an opportunity for business to get recognized and grow, the awards will be decided by the jury of industry experts on key consumption trends, new concepts in F&G, FMCG, and F&B. The forum takes a holistic view of the individual sector and highlights the potential areas for producers and sellers to know about the upcoming trends and scope in the various businesses.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </div>
        )
    }
}



export default Awards;

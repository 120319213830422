import React from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import Utils from './utils/homeutils';
import * as _ from 'lodash';

class YellowPagesNavigation extends React.Component<{
    categories:any[],
    subCategories:any[]
}>{
    constructor(props: any) {
        super(props);		
    }
	
    componentDidMount() {
    } 
    render() {
     const { categories, subCategories } = this.props;
        return (
            <div className="w-100 left-nav-sidebar">
                <ul className="nav flex-column flex-nowrap overflow-hidden">
                {categories.map(function (cat) {
                    return (
                        
                        <li className="nav-item" key={cat._source.id}>
                            <a className="nav-link collapsed text-truncate" 
                                href={`#submenu${cat._source.id}`} 
                                data-toggle="collapse" 
                                data-target={`#submenu${cat._source.id}`}
                                aria-expanded="false"
                                >
                               <span className="d-sm-inline">{cat._source.title}</span>
                                
                            </a>
                            
                            <div className="collapse" id={`submenu${cat._source.id}`} aria-expanded="false">
                            <ul className="flex-column nav">
                                {subCategories.filter(e => e._source.categoryid === cat._source.id).map(function (subcat) {
                                    return (									
                                        <li className="nav-item" key={subcat._source.id+subcat._source.title}>
                                           <Link to={`/yellowpages/${cat._source.title.replace(/ /g, '').replace(/&/g,'')}/${subcat._source.title.replace(/ /g, '')}/${subcat._source.id}`} className="nav-link">{subcat._source.title}</Link>
                                        </li>
                                    );
                                })}
                            </ul>
                            </div>                              
                            

                        </li>
                    );
                })}


            </ul>
            </div>
           
        )

    }
}



export default YellowPagesNavigation;

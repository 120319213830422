import React,{useState,useEffect} from 'react';
import {Link,useHistory} from 'react-router-dom';
import axios from 'axios';
import cors from 'react';
import Utils from '../components/utils/homeutils'; 

import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap'

function Registration(props:any){
    let fields = {userName:'',password:'',confirmPassword:'',fullName:'',country:'',state:'',city:'',companyName:'',phone:'',email:'', role:'', aadhar:'',name:'',contact:'', otp:''}    
    const {countries,cities,states,successCallback,componentName, roles} = props; 

    // console.log("add your business Countries", countries);
    // console.log("add business categories", categories);
    // console.log("add business subcategories", subCategories);
    // ;  
    const [values, setValues] = useState(fields);
    const [errors, setErrors] = useState(fields);
    const cors = require ("cors");
    const [filteredStates,setFilteredStates] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);
    const [isDuplicateUser, setIsDuplicateUser] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [aadharNumber, setAadharNumber] = useState("");
    const [name, setName] = useState("");
    const [contact, setContact] = useState("");
    const regexEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const headers = {"Access-Control-Allow-Origin": "*", "Accept": "applicaiton/json", "Content-Type": "application/json",};
        const filterStates = (countryId:any) => {
        setFilteredStates(states.filter((x:any)=>x.countryId==countryId));
    }       






    const filterCities = (stateId:any) => {
        setFilteredCities(cities.filter((x:any)=>x.stateId==stateId));
    }

    const apiendpoint=process.env.REACT_APP_API_Endpoint;

    useEffect(() => {
        componentName("Registration");
    }, [componentName]);

   
    const GenerateOtp =(e:any)=> {
        console.log(values);
        e.preventDefault();
        axios.post('https://api.hind.biz/GenerateOTP', {
            SendTo:values.userName,
            otpType:regexEmail.test(values.userName)?"email":"mobile"
        },{headers:headers}).then(function (response) {
            if (response.status === 200) {
               successCallback(true, "OTP sent successfully");
                handleShow();
            } 
        })
        .catch(function (error) {
           alert("Error while generating OTP. " + JSON.stringify(error));
        })
        // .finally(() => {
        //     setShow(true);
        //   });
    }

    const onSubmit=(e:any)=> {
        if(RegistrationValidateForm()){
            GenerateOtp(e);
        }        
    }
    const onConfirm =(e:any)=> {
        console.log(values);
        e.preventDefault();

        axios.post('https://api.hind.biz/registration', {
            userName:values.userName,                     
            fullName: values.fullName,
            password:values.password,    
            countryId: Number(values.country), 
            stateId: Number(values.state),
            cityId: Number(values.city),
            companyName: values.companyName,
            phone: values.phone,
            email: values.email,
            roleid:Number(values.role),
            aadhar:Number(values.aadhar),
            contact:Number(values.contact),
            name:values.name,
            OTP:values.otp,
        },{headers:headers}
        ).then( (response) => {
            if (response.status === 200) {
                setValues(fields);
                successCallback(true, "Registration successful");
                handleClose();
            } 
        })
        .catch(function (error) {
            console.log(error);
        });
    }

   

    // GetRoles = async() => {    
    //     let elkRoles = await Utils.GetRoles();
    //     this.setState({Roles:elkRoles});
    //   }

    /*
    let IsUserNameExist = async(e:any) => {    
        let isExist = await Utils.IsUserNameExist(e.target.value);
        // console.log("IsUserNameExist",isExist,e.target.value);
        setIsDuplicateUser(isExist);
      }
      */

    


    const RegistrationValidateForm = () => {  
        console.log(values)      ;
      
        let formIsValid = true;
        let inputValues = values; 
        // console.log(values)   ;

        setErrors(fields);
        let err = fields;

        if (isNaN(Number(inputValues.userName)) && !regexEmail.test(inputValues.userName) ) {
            formIsValid = false;
            err.userName= "Enter valid email address";
        }
        if (inputValues.userName.length === 10 && isNaN(Number(inputValues.userName)))  {
            formIsValid = false;
            err.userName= "Enter valid mobile number";
        }
        if (inputValues.aadhar.length !== 12) {
            err.aadhar=("Please enter a valid Aadhar number.");
          }

          if (inputValues.name.length == 5) {
            err.name=("Please enter a Valid Name.");
          }

          if (inputValues.contact.length == 10) {
            // err.MobileNumber=("Please enter a Valid 10 Digits Number.");
          } 

         
        // };
        // if(isDuplicateUser){
        //     formIsValid = false;
        //     err.userName= "User already exists";
        // }
        if (inputValues.userName.length <= 6) {
            formIsValid = false;
            err.userName= "Invalid UserName";
        }
        if (inputValues.password.length <= 6) {
            formIsValid = false;
            err.password= "Password should have atleast 5 letters";
        }
        if(inputValues.confirmPassword !== inputValues.password){
            formIsValid = false;
            err.confirmPassword= "Confirm password is not matching with Password";
        }
        if (inputValues.fullName.length <= 4) {
            formIsValid = false;
            err.fullName= "Full name atleast have 5 letters";
        }
        if (!inputValues.country) {
            formIsValid = false;
            err.country= "Please select Country";
        }
        if (!inputValues.state) {
            formIsValid = false;
            err.state = "Please select state";
        }
        if (!inputValues.city) {
            formIsValid = false;
            err.city= "Please select city";
        }
        if (inputValues.companyName.length <= 10) {
            formIsValid = false;
            err.companyName = "company name atleast have 10 letters";
        }
        if (!inputValues.phone) {
            formIsValid = false;
            err.phone = "Please enter phone number";
        }       
        /*if (!regexEmail.test(inputValues.email)) {
            formIsValid = false;
            err.email = "Please enter valid email.";
        } */
        if (!inputValues.role) {
            formIsValid = false;
            err.role= "Please select role";
        }    
        setErrors(err) ;
        console.log(formIsValid,"formisvalid");
        return formIsValid;
    }


    
  const handleOnChange = (event:any) => {
    event.persist();
    let name = event.target.name;
    let val = event.target.value;
    // console.log(event.target.name);    
    // console.log(event.target.value);
    if(name==="country"){
        filterStates(val);
        setFilteredCities([]);
        setValues({
            ...values,
            state:'',
            city:'',
        });
    }
   /* if (name === "referralName") {
        setReferralName(val);
        setReferralName([]);
      } else if (name === "referralPhoneNumber") {
        setReferralPhoneNumber(val);
        setReferralPhoneNumber([]);
      }
    };*/
     if(name==="state"){
        filterCities(val);
        setValues({
            ...values,
            city:'',
        });
    }
   
    setValues({
            ...values,
            [name]:val,
        });
}

    return(      
          <div className="d-flex" id="jh-wrapper">
                <div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
                    <div className="row">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Registration</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="about-us" style={{ padding: "0px" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4">
                                    <div className="about-text">
                                        <h2>Registration</h2>                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>    

                    <div className="row" style={{paddingLeft:'25px',paddingRight:'20px'}} >
                        <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label>User Name (E-Mail/Mobile No.) <small className="text-danger">*</small></label>
                                    <input type="text" id="txtUserName" value={values.userName} name="userName" className="form-control" onChange={handleOnChange}/>
                                    <span className="text-danger">{errors.userName}</span>
                                </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label>Password <small className="text-danger">*</small></label>
                                    <input type="password" id="txtPassword" value={values.password} name="password" className="form-control" onChange={handleOnChange}/>
                                    <span className="text-danger">{errors.password}</span>
                                </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label>Confirm password <small className="text-danger">*</small></label>
                                    <input type="password" id="txtPassword" value={values.confirmPassword} name="confirmPassword" className="form-control" onChange={handleOnChange}/>
                                    <span className="text-danger">{errors.confirmPassword}</span>
                                </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label>Full Name <small className="text-danger">*</small></label>
                                    <input type="text" id="txtFullName" value={values.fullName} name="fullName" className="form-control" onChange={handleOnChange}/>
                                    <span className="text-danger">{errors.fullName}</span>
                                </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="form-group">
                                        <label>Country <small className="text-danger">*</small></label>
                                        <select id="ddlCountry" value={values.country} name="country" className="form-control city-dropdown"
                                            onChange={handleOnChange}>
                                            <option value="0"></option>
                                            {countries.map((value: any) => <option key={value.countryId} value={value.countryId}>{value.countryName}</option>)}
                                        </select>
                                        <span className="text-danger">{errors.country}</span>
                                </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="form-group">
                                        <label>State <small className="text-danger">*</small></label>
                                        <select id="ddlState" value={values.state} name="state" className="form-control city-dropdown"
                                            onChange={handleOnChange}>
                                            <option value="0"></option>
                                            {filteredStates.map((value: any) => <option key={value.stateId} value={value.stateId}>{value.stateName}</option>)}
                                        </select>
                                        <span className="text-danger">{errors.state}</span>
                                </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="form-group">
                                        <label>City<small className="text-danger">*</small></label>
                                        <select id="ddlCity" value={values.city} name="city" className="form-control city-dropdown"
                                            onChange={handleOnChange}>
                                            <option value="0"></option>
                                            {filteredCities.map((value: any) => <option key={value.cityId} value={value.cityId}>{value.cityName}</option>)}
                                        </select>
                                        <span className="text-danger">{errors.city}</span>
                                </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label>Company Name <small className="text-danger">*</small></label>
                                    <input type="text" id="txtCompanyName" value={values.companyName} name="companyName" className="form-control" onChange={handleOnChange}/>
                                    <span className="text-danger">{errors.companyName}</span>
                                </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label>Phone Number <small className="text-danger">*</small></label>
                                    <input type="text" id="txtPhone" value={values.phone} name="phone" className="form-control" onChange={handleOnChange}/>
                                    <span className="text-danger">{errors.phone}</span>
                                </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label>E-Mail Address<small className="text-danger"></small></label>
                                    <input type="text" id="txtEmail" value={values.email} name="email" className="form-control" onChange={handleOnChange}/>
                                    <span className="text-danger">{errors.email}</span>
                                </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="form-group">
                                        <label>Roles <small className="text-danger">*</small></label>
                                        <select id="ddlRoles" value={values.role} name="role" className="form-control role-dropdown"
                                            onChange={handleOnChange}>
                                            <option value="0"></option>
                                            {roles.map((value: any) => <option key={value.roleId} value={value.roleId}>{value.roleName}</option>)}
                                        </select>
                                        <span className="text-danger">{errors.role}</span>
                                </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label>Aadhar Number<small className="text-danger">*</small></label>
                                    <input type="text" id="txtAadhar" value={values.aadhar} name="aadhar" className="form-control" onChange={handleOnChange}/>
                                    <span className="text-danger">{errors.aadhar}</span>
                                </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label>Referral Name </label>
                                    <input type="text" id="name" value={values.name} name="name" className="form-control" onChange={handleOnChange}/>
                                    {/* <span className="text-danger">{errors.fullName}</span>  */}
                                </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label>Referral MobileNumber </label>
                                    <input type="text" id="txtContact" value={values.contact} name="contact" className="form-control" onChange={handleOnChange}/>
                                    {/* <span className="text-danger">{errors.phone}</span> */}
                                </div>
                        </div>

                    </div>   
                    <br/>
                    <div style={{width:'20%', margin:'auto'}} >
                        <button id="btnSubmit" className="btn btn-primary btn-block" onClick={onSubmit}>Submit</button>
                        <Modal show={show} onHide={handleClose}  backdrop="static" keyboard={false} centered>
                            <Modal.Header closeButton>
                            <Modal.Title>OTP Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                               <p>OTP has been sent to {values.userName}</p> <br/>
                               <div className="form-group">
                                    <input placeholder='Enter OTP' type="password" id="txtOtp" value={values.otp} name="otp" className="form-control" onChange={handleOnChange}/>
                                    <span className="text-danger">{errors.otp}</span>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={onConfirm}>
                                Confirm
                            </Button>
                            </Modal.Footer>
                        </Modal>
                    </div> 
                       
            </div>
        </div>
    )
}
export default Registration;
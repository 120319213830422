import React, { forwardRef, useEffect } from "react";
import { useHistory } from "react-router";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import "@material-ui/core";
import YellowPagesNavigation from "./leftnav";
import Axios from "axios";
import PostReply from "./../components/dashboard/PostReply";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Box from "@material-ui/core/Box";
import EventQuickLinks from "./homeapps/EventQuickLinks";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import SportsCricketIcon from '@material-ui/icons/SportsCricket';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import PeopleIcon from '@material-ui/icons/People';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import ListIcon from '@material-ui/icons/List';
import MovieIcon from '@material-ui/icons/Movie';
import Utils from './utils/homeutils';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import MultiSelect from "react-multi-select-component";
import axios from 'axios';
import * as _ from 'lodash';




class JobRegistration extends React.Component<{
  componentName: any,
  successCallback: any,
  countries: any[],
  states: any[],
  cities: any[],
  history: any
}, any>{
  constructor(props: any) {
    super(props);
    this.state = {
      jobRegistrationFields: {},
      jobRegistrationErrors: {},
      Educations: [],
      Industries: [],
      Skills: [],
      SkillName: [],
      CountryName: '',
      StateName: '',
      CityName: '',
      Education: '',
      Industry: ''
    }
    this.JobRegistrationHandleChange = this.JobRegistrationHandleChange.bind(this);
    this.RegistrationSubmission = this.RegistrationSubmission.bind(this);
    this.RegistrationCancel = this.RegistrationCancel.bind(this);
  }


  async componentDidUpdate(prevProps: any, _prevState: any) {

  }
  async componentDidMount() {
    this.props.componentName("Job Registration");
    let educationItems = await Utils.GetEducationDetails();
    let industryItems = await Utils.GetIndustryDetails();
    let skills = await Utils.GetITSkills();
    let skillsArray: any = [];
    skills.map(function (skill: any) {
      skillsArray.push({ label: skill._source.title, value: skill._source.title })
    });
    this.setState({ Educations: educationItems, Industries: industryItems, Skills: skillsArray });
  }
  OpenJobRegistration = () => {
    this.props.history.push("/jobregistration");
  }
  RegistrationCancel = () => {
    this.setState({ jobRegistrationErrors: {} });
  }
  JobRegistrationHandleChange = (e: any) => {
    let fields = this.state.jobRegistrationFields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });
    if (e.target.name === "industry") {
      this.setState({ SkillName: [] });
    }

    if (e.target.name === "country") {
      this.setState({ "CountryName": e.target.selectedOptions[0].text });
    } else if (e.target.name === "userstate") {
      this.setState({ "StateName": e.target.selectedOptions[0].text });
    }
    else if (e.target.name === "city") {
      this.setState({ "CityName": e.target.selectedOptions[0].text });
    }
    else if (e.target.name === "education") {
      this.setState({ "Education": e.target.selectedOptions[0].text });
    }
    else if (e.target.name === "industry") {
      this.setState({ "Industry": e.target.selectedOptions[0].text });
    }
  }
  handleChange = (event: any, newValue: any) => {
    this.props.history.push("/events/" + newValue.toLowerCase() + "");
  }
  RegistrationSubmission = (e: any) => {
    let mainSate = this;
    let selectedSkills = "";
    e.preventDefault();
    if (this.ValidateRegistrationForm()) {
      if (this.state.jobRegistrationFields.industry === "12") {

        this.state.SkillName.map(function (skill: any) {
          selectedSkills += skill.value + ";";
        });
      }
      axios.post("https://api.jaiho.com/JobSeeker", {
        countryid: Number(this.state.jobRegistrationFields.country),
        countryname: this.state.CountryName,
        stateid: Number(this.state.jobRegistrationFields.userstate),
        statename: this.state.StateName,
        cityid: Number(this.state.jobRegistrationFields.city),
        cityname: this.state.CityName,
        fullname: this.state.jobRegistrationFields.fullname,
        email: this.state.jobRegistrationFields.email,
        contactnumber: this.state.jobRegistrationFields.phone,
        educationid: Number(this.state.jobRegistrationFields.education),
        education: this.state.Education,
        passout: Number(this.state.jobRegistrationFields.passout),
        industryid: Number(this.state.jobRegistrationFields.industry),
        industry: this.state.Industry,
        experience: this.state.jobRegistrationFields.experience,
        skills: selectedSkills,
        coverletter: this.state.jobRegistrationFields.coverletter
      }).then(function (response) {
        if (response.data.statusCode === 200) {
          // mainSate.props.successCallback(true);	
        } else {
          //mainScope.setState({ RegistrationStatus: "Failed" });
        }
      })
        .catch(function (error) {
          console.log(error);
        });
      mainSate.props.successCallback(true, "Your request han been successfully submitted!");
      mainSate.props.history.push("/employment");
    }
  }

  ValidateRegistrationForm = () => {
    let fields = this.state.jobRegistrationFields;
    let errors = this.state.jobRegistrationErrors;
    errors["country"] = "";
    errors["state"] = "";
    errors["city"] = "";
    errors["fullname"] = "";
    errors["email"] = "";
    errors["phone"] = "";
    errors["education"] = "";
    errors["passout"] = "";
    errors["industry"] = "";
    errors["experience"] = "";
    errors["skills"] = "";
    errors["coverletter"] = "";
    let formIsValid = true;
    if (!fields.country) {
      formIsValid = false;
      errors["country"] = "*Please select country.";
    }
    if (!fields.userstate) {
      formIsValid = false;
      errors["userstate"] = "*Please select state.";
    }


    if (!fields.city) {
      formIsValid = false;
      errors["city"] = "*Please select city.";
    }
    if (!fields.fullname) {
      formIsValid = false;
      errors["fullname"] = "*Please enter your full name.";
    }
    if (!fields.email) {
      formIsValid = false;
      errors["email"] = "*Please enter your email.";
    }
    let regexEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!regexEmail.test(fields.email)) {
      formIsValid = false;
      errors["email"] = "*Please enter valid email.";
    }
    if (!fields.phone) {
      formIsValid = false;
      errors["phone"] = "*Please enter your phone number.";
    }
    let regexPhone = /^[0-9]*$/;
    if (!regexPhone.test(fields.phone)) {
      formIsValid = false;
      errors["phone"] = "*Please enter valid phone number.";
    }
    if (!fields.education) {
      formIsValid = false;
      errors["education"] = "*Please select your education.";
    }
    if (!fields.passout) {
      formIsValid = false;
      errors["passout"] = "*Please enter passout year.";
    }
    if (fields.passout) {
      if (Number(fields.passout) < 1970 || Number(fields.passout) > Number(new Date().getFullYear())) {
        formIsValid = false;
        errors["passout"] = "*Please enter valid passout year.";
      }

    }
    if (!fields.industry) {
      formIsValid = false;
      errors["industry"] = "*Please select industry.";
    }
    if (!fields.experience) {
      formIsValid = false;
      errors["experience"] = "*Please enter your experience.";
    }
    if (this.state.jobRegistrationFields.industry === "12" && this.state.SkillName.length < 1) {
      formIsValid = false;
      errors["skills"] = "*Please select your skills.";
    }
    if (!fields.coverletter) {
      formIsValid = false;
      errors["coverletter"] = "*Please enter your coverletter.";
    }

    this.setState({
      jobRegistrationErrors: errors
    });
    return formIsValid;
  }
  handleSkillChange = (event: any) => {
    this.setState({ SkillName: event });
  }

  render() {
    const { countries, states, cities, history } = this.props;
    let { Educations, Industries, SkillName, Skills } = this.state;
    return (
      <div className="d-flex" id="jh-wrapper">
        <div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
          <div className="row">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/employment">Employment</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Job Registration
              </li>
              </ol>
            </nav>
          </div>
          <div className="jh-content-body">
            <div className="col-md-12 jh-events-title">
              <h2 className="jh-title-1">Job Registration</h2>
            </div>
            <div className="col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="jh-employment">
                <div className="jh-employment-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4 col-lg-4 col-12">
                        <div className="form-group">
                          <label>Country <small className="text-danger">*</small></label>
                          <select className="form-control" name="country" value={this.state.jobRegistrationFields.country} onChange={this.JobRegistrationHandleChange}>
                            <option value="0"></option>
                            {countries.map((value) => <option value={value._source.id}>{value._source.title}</option>)}
                          </select>
                          <span className="text-danger">{this.state.jobRegistrationErrors.country}</span>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4 col-12">
                        <div className="form-group">
                          <label>State <small className="text-danger">*</small></label>
                          <select className="form-control" name="userstate" value={this.state.jobRegistrationFields.userstate} onChange={this.JobRegistrationHandleChange}>
                            <option value="0"></option>
                            {states.filter(f => f._source.countryid === Number(this.state.jobRegistrationFields.country)).map((value) => <option value={value._source.id}>{value._source.title}</option>)}
                          </select>
                          <span className="text-danger">{this.state.jobRegistrationErrors.userstate}</span>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4 col-12">
                        <div className="form-group">
                          <label>City <small className="text-danger">*</small></label>
                          <select className="form-control" name="city" value={this.state.jobRegistrationFields.city} onChange={this.JobRegistrationHandleChange}>
                            <option value="0"></option>
                            {cities.filter(x => x._source.stateid === Number(this.state.jobRegistrationFields.userstate)).map((value) => <option value={value._source.id}>{value._source.title}</option>)}
                          </select>
                          <span className="text-danger">{this.state.jobRegistrationErrors.city}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 col-lg-4 col-12">
                        <div className="form-group">
                          <label>Full Name <small className="text-danger">*</small></label>
                          <input type="text" className="form-control" name="fullname" value={this.state.jobRegistrationFields.fullname} onChange={this.JobRegistrationHandleChange} />
                          <span className="text-danger">{this.state.jobRegistrationErrors.fullname}</span>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4 col-12">
                        <div className="form-group">
                          <label>Email <small className="text-danger">*</small></label>
                          <input type="email" className="form-control" name="email" value={this.state.jobRegistrationFields.email} onChange={this.JobRegistrationHandleChange} />
                          <span className="text-danger">{this.state.jobRegistrationErrors.email}</span>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4 col-12">
                        <div className="form-group">
                          <label>Phone <small className="text-danger">*</small></label>
                          <input type="text" className="form-control" name="phone" value={this.state.jobRegistrationFields.phone} onChange={this.JobRegistrationHandleChange} />
                          <span className="text-danger">{this.state.jobRegistrationErrors.phone}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-lg-3 col-12">
                        <div className="form-group">
                          <label>Education <small className="text-danger">*</small></label>
                          <select className="form-control" name="education" value={this.state.jobRegistrationFields.education} onChange={this.JobRegistrationHandleChange}>
                            <option value="0"></option>
                            {Educations.map((value: any) => <option value={value._source.id}>{value._source.title}</option>)}
                          </select>
                          <span className="text-danger">{this.state.jobRegistrationErrors.education}</span>
                        </div>
                      </div>
                      <div className="col-md-3 col-lg-3 col-12">
                        <div className="form-group">
                          <label>Passout <small className="text-danger">*</small></label>
                          <input type="number" min="1970" max={new Date().getFullYear()} className="form-control" name="passout" value={this.state.jobRegistrationFields.passout} onChange={this.JobRegistrationHandleChange} />

                          <span className="text-danger">{this.state.jobRegistrationErrors.passout}</span>
                        </div>
                      </div>
                      <div className="col-md-3 col-lg-3 col-12">
                        <div className="form-group">
                          <label>Industry <small className="text-danger">*</small></label>
                          <select className="form-control" name="industry" value={this.state.jobRegistrationFields.industry} onChange={this.JobRegistrationHandleChange}>
                            <option value="0"></option>
                            {Industries.map((value: any) => <option value={value._source.id}>{value._source.title}</option>)}
                          </select>
                          <span className="text-danger">{this.state.jobRegistrationErrors.industry}</span>
                        </div>
                      </div>
                      <div className="col-md-3 col-lg-3 col-12">
                        <div className="form-group">
                          <label>Experience <small className="text-danger">*</small></label>
                          <input type="text" className="form-control" name="experience" value={this.state.jobRegistrationFields.experience} onChange={this.JobRegistrationHandleChange} />
                          <span className="text-danger">{this.state.jobRegistrationErrors.experience}</span>
                        </div>
                      </div>
                    </div>
                    {this.state.jobRegistrationFields.industry === "12" && <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label>Skills <small className="text-danger">*</small></label>
                          <MultiSelect
                            options={Skills}
                            value={SkillName}
                            onChange={this.handleSkillChange}
                            labelledBy={"Select"}
                          />
                          <span className="text-danger">{this.state.jobRegistrationErrors.skills}</span>
                        </div>
                      </div>
                    </div>
                    }

                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label>Coverletter (Max 1000 chars) <small className="text-danger">*</small></label>
                          <textarea className="form-control" name="coverletter" value={this.state.jobRegistrationFields.coverletter} onChange={this.JobRegistrationHandleChange} cols={10} rows={10}></textarea>
                          <span className="text-danger">{this.state.jobRegistrationErrors.coverletter}</span>
                        </div>
                      </div>

                    </div>
                    <div className="pull-right">
                      <button type="button" className="btn btn-primary mr-1" onClick={this.RegistrationSubmission}>Submit</button>
                      <button type="button" className="btn btn-secondary" onClick={this.RegistrationCancel}>Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    )

  }
}



export default JobRegistration;


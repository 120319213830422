import React from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import Header from './header';
import QuickLinks from './homeapps/quicklinks';
import CarousalApp from './homeapps/carousalapp';
import '../css/Marqueestyles1.css';
import '../css/Marqueestyles2.css';
import '../css/Marqueestyles3.css';
import '../css/customstyle.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import styled from "styled-components";
import times from "lodash/times";
import Sloka from '../img/Slokas.jpg';
import Utils from './utils/homeutils';
import Adds1 from './homeapps/adds1';
import Poll from './homeapps/poll';
import Trainings from './homeapps/trainings';
import Blogs from './homeapps/blog';
import Events from './homeapps/events';
import Adds2 from './homeapps/adds2';
import ImgScroll from './homeapps/imgscroll';
import JaihoFooter from '../components/footer';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import YellowPagesNavigation from '../components/leftnav';
import * as _ from 'lodash';
var $: any = require("jquery");
var moment: any = require('moment');
function Alert(props: any) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class Home extends React.Component<{
	componentName: any,
	country: any,
	countryid: any,
	city: any,
	cityid: any,
	stateid: any,
	allBusinessItems: any[],
	allBusinessRankItems: any[],
	isMainDataLoading: any,
	history: any,
}, {
	listItems: any[],
	prefServices: any[],
	education: any[],
	restaurents: any[],
	groceries: any[],
	jewelry: any[],
	spa: any[],
	notificationItems: any[],
	eventItems: any[],
	eventLoading: boolean,
	fashion: any[],
	carRepair: any[],
	clinicItems: any[],
	employmentItems: any[],
	loading: boolean,
	restLoading: boolean,
	profLoading: boolean,
	groceryLoading: boolean,
	jewelryLoading: boolean,
	spaLoading: boolean,
	educationLoading: boolean,
	apartmentsLoading: boolean,
	fashionLoading: boolean,
	clinicsLoading: boolean,
	carServiceLoading: boolean,
	employmentLoading: boolean,
	adv4gif: any,
	adv4gifs: any[],
	pollsnackbaropen: boolean,
	successMsg: any,
	vertical: any,
	horizontal: any

}>{
	constructor(props: any) {
		super(props);
		this.state = {
			listItems: [],
			prefServices: [],
			education: [],
			restaurents: [],
			groceries: [],
			jewelry: [],
			spa: [],
			notificationItems: [],
			employmentItems: [],
			loading: true,
			restLoading: true,
			profLoading: true,
			groceryLoading: true,
			jewelryLoading: true,
			educationLoading: true,
			spaLoading: true,
			apartmentsLoading: true,
			fashionLoading: true,
			clinicsLoading: true,
			carServiceLoading: true,
			employmentLoading: true,
			eventItems: [],
			eventLoading: true,
			fashion: [],
			carRepair: [],
			clinicItems: [],
			adv4gif: "",
			adv4gifs: [],
			pollsnackbaropen: false,
			successMsg: "",
			vertical: "top",
			horizontal: "center"
		}

	}
	SnackbarCallback = (obj: any, msg: any) => {
		this.setState({ pollsnackbaropen: obj, successMsg: msg });
	}
	async componentDidUpdate(prevProps: any, _prevState: any) {
		if (prevProps.city != this.props.city || prevProps.isMainDataLoading != this.props.isMainDataLoading) {
			let elkNotifications: any = [];
			if (prevProps.city != this.props.city) {
				elkNotifications = await Utils.GetNotificationsItems(this.props.cityid);
				this.setState({ notificationItems: elkNotifications });
			}

			if (this.props.isMainDataLoading) {
				this.setState({
					restLoading: true,
					profLoading: true,
					groceryLoading: true,
					apartmentsLoading: true,
					educationLoading: true,
					spaLoading: true,
					jewelryLoading: true,
					eventLoading: true,
					carServiceLoading: true,
					fashionLoading: true
				});
				return;
			}
			// Get all businessentityid for city
			let allBusinessEntities = this.props.allBusinessItems;
			//await Utils.GetAllBuinessForCity(this.props.city);				
			// Get all advertisement for city
			let allAdtisements = this.props.allBusinessRankItems;
			//await Utils.GetBusinessRanking(this.props.cityid);
			try {
				let restarentsData = await this.GetYellowPages(25, null, allBusinessEntities, allAdtisements);
				this.setState({
					restaurents: restarentsData, restLoading: false
				});
			} catch (e) {
			}
			//Professional Servicestry{
			try {
				let profData = await this.GetYellowPages(null, 3, allBusinessEntities, allAdtisements);
				this.setState({
					prefServices: profData, profLoading: false
				});
			} catch (e) {
			}
			//Grocery
			try {
				let groceriesData = await this.GetYellowPages(31, null, allBusinessEntities, allAdtisements);
				this.setState({
					groceries: groceriesData, groceryLoading: false
				});
			} catch (e) {
			}
			// Apartments		
			try {
				let apartmentsData = await this.GetYellowPages(null, 4, allBusinessEntities, allAdtisements);
				this.setState({
					listItems: apartmentsData, apartmentsLoading: false
				});
			} catch (e) {
			}
			// Education
			try {
				let educationData = await this.GetYellowPages(null, 2, allBusinessEntities, allAdtisements);
				this.setState({
					education: educationData, educationLoading: false
				});
			} catch (e) {
			}
			// SPA
			try {
				let spaData = await this.GetYellowPages(34, null, allBusinessEntities, allAdtisements);
				this.setState({
					spa: spaData, spaLoading: false
				});
			} catch (e) {
			}
			// Jewellary
			try {
				let jewelryData = await this.GetYellowPages(33, null, allBusinessEntities, allAdtisements);
				this.setState({
					jewelry: jewelryData, jewelryLoading: false
				});
			} catch (e) {
			}
			// Events
			try {
				let eventsData = await this.GetYellowPages(null, null, allBusinessEntities, allAdtisements);
				this.setState({
					eventItems: eventsData, eventLoading: false
				});
			} catch (e) {
			}
			// Car Repair
			try {
				let carRepairData = await this.GetYellowPages(null, 1, allBusinessEntities, allAdtisements);
				this.setState({
					carRepair: carRepairData, carServiceLoading: false
				});
			} catch (e) {
			}
			// Fashion
			try {
				let fashionData = await this.GetYellowPages(35, null, allBusinessEntities, allAdtisements);
				this.setState({
					fashion: fashionData, fashionLoading: false
				});
			} catch (e) {
			}
			// Clinics
			try {
				let clinicalData = await this.GetYellowPages(36, null, allBusinessEntities, allAdtisements);
				this.setState({
					clinicItems: clinicalData, clinicsLoading: false
				});
			} catch (e) {
			}
			//requirements
			try {
				let requirements = await Utils.GetRequirements(Number(this.props.cityid));
				this.setState({
					employmentItems: requirements, employmentLoading: false
				});
			} catch (e) {
			}

			// Adv4
			let adv4: any = [];
			let adv4gifname: any = [];
			let adv4Now = moment().format("YYYY-MM-DD");
			adv4 = allAdtisements.filter(function (elkItem: any) {
				//return elkItem._source.advtypeid === 4 && new Date() <= new Date(elkItem._source.tilldate)
				return elkItem._source.advtypeid === 4 && moment(adv4Now, "YYYY-MM-DD").diff(moment(elkItem._source.startdate, "YYYY-MM-DD")) >= 0 && moment(elkItem._source.tilldate, "YYYY-MM-DD").diff(moment(adv4Now, "YYYY-MM-DD")) >= 0
			});
			this.setState({ adv4gifs: adv4 });
			if (adv4.length > 0) {
				adv4gifname = allBusinessEntities.filter(function (elkItem: any) {
					return elkItem._source.id === adv4[0]._source.businessentityid
				});
				if (adv4gifname.length > 0 && adv4gifname[0]._source.gifname != "") {
					this.setState({ adv4gif: adv4gifname[0]._source.gifname });
				} else {
					this.setState({ adv4gif: "" });
				}
			} else {
				this.setState({ adv4gif: "" });
			}

		}
	}

async componentDidMount() {
		this.props.componentName("home");
		if (this.props.city != "" && this.props.city != null) {
			try {
				let elkNotifications = await Utils.GetNotificationsItems(this.props.cityid);
				this.setState({ notificationItems: elkNotifications });
				if (this.props.isMainDataLoading)
					return;
				// Get all businessentityid for city
				let allBusinessEntities = this.props.allBusinessItems;
				//await Utils.GetAllBuinessForCity(this.props.city);				
				// Get all advertisement for city
				let allAdtisements = this.props.allBusinessRankItems;
				//await Utils.GetBusinessRanking(this.props.cityid);				
				try {
					let restarentsData = await this.GetYellowPages(25, null, allBusinessEntities, allAdtisements);
					this.setState({
						restaurents: restarentsData, restLoading: false
					});
				} catch (e) {
				}
				//Professional Servicestry{
				try {
					let profData = await this.GetYellowPages(null, 3, allBusinessEntities, allAdtisements);
					this.setState({
						prefServices: profData, profLoading: false
					});
				} catch (e) {
				}
				//Grocery
				try {
					let groceriesData = await this.GetYellowPages(31, null, allBusinessEntities, allAdtisements);
					this.setState({
						groceries: groceriesData, groceryLoading: false
					});
				} catch (e) {
				}
				// Apartments		
				try {
					let apartmentsData = await this.GetYellowPages(null, 4, allBusinessEntities, allAdtisements);
					this.setState({
						listItems: apartmentsData, apartmentsLoading: false
					});
				} catch (e) {
				}
				// Education
				try {
					let educationData = await this.GetYellowPages(null, 2, allBusinessEntities, allAdtisements);
					this.setState({
						education: educationData, educationLoading: false
					});
				} catch (e) {
				}
				// SPA
				try {
					let spaData = await this.GetYellowPages(34, null, allBusinessEntities, allAdtisements);
					this.setState({
						spa: spaData, spaLoading: false
					});
				} catch (e) {
				}
				// Jewellary
				try {
					let jewelryData = await this.GetYellowPages(33, null, allBusinessEntities, allAdtisements);
					this.setState({
						jewelry: jewelryData, jewelryLoading: false
					});
				} catch (e) {
				}
				// Events
				try {
					let eventsData = await this.GetYellowPages(null, null, allBusinessEntities, allAdtisements);
					this.setState({
						eventItems: eventsData, eventLoading: false
					});
				} catch (e) {
				}
				// Car Repair
				try {
					let carRepairData = await this.GetYellowPages(null, 1, allBusinessEntities, allAdtisements);
					this.setState({
						carRepair: carRepairData, carServiceLoading: false
					});
				} catch (e) {
				}
				// Fashion
				try {
					let fashionData = await this.GetYellowPages(35, null, allBusinessEntities, allAdtisements);
					this.setState({
						fashion: fashionData, fashionLoading: false
					});
				} catch (e) {
				}
				// Clinics
				try {
					let clinicalData = await this.GetYellowPages(36, null, allBusinessEntities, allAdtisements);
					this.setState({
						clinicItems: clinicalData, clinicsLoading: false
					});
				} catch (e) {
				}
				//requirements
				try {
					let requirements = await Utils.GetRequirements(Number(this.props.cityid));
					this.setState({
						employmentItems: requirements, employmentLoading: false
					});
				} catch (e) {
				}
				// Adv4
				let adv4Now = moment().format("YYYY-MM-DD");
				let adv4: any = [];
				let adv4gifname: any = [];
				adv4 = allAdtisements.filter(function (elkItem: any) {
					//return elkItem._source.advtypeid === 4 && moment() >= moment(elkItem._source.startdate) && moment() <= moment(elkItem._source.tilldate)
					return elkItem._source.advtypeid === 4 && moment(adv4Now, "YYYY-MM-DD").diff(moment(elkItem._source.startdate, "YYYY-MM-DD")) >= 0 && moment(elkItem._source.tilldate, "YYYY-MM-DD").diff(moment(adv4Now, "YYYY-MM-DD")) >= 0
				});
				this.setState({ adv4gifs: adv4 });
				if (adv4.length > 0) {
					adv4gifname = allBusinessEntities.filter(function (elkItem: any) {
						return elkItem._source.id === adv4[0]._source.businessentityid
					});
					if (adv4gifname.length > 0 && adv4gifname[0]._source.gifname != "") {
						this.setState({ adv4gif: adv4gifname[0]._source.gifname });
					} else {
						this.setState({ adv4gif: "" });
					}
				} else {
					this.setState({ adv4gif: "" });
				}


			} catch (e) {
			}
		}


	}

	GetYellowPages = async (subcatid: any, catid: any, allBusinessItems: any[], allAdvertisementItems: any[]): Promise<any> => {
		return new Promise<any>(async (resolve) => {
			let results: any = [];
			let tempRestaurents: any = [];
			let toprestaurents: any = [];
			let tempBusinessID: any = [];
			let elkRestauents: any = [];
			let restaurentRankings: any = [];
			if (subcatid !== null) {
				elkRestauents = allBusinessItems.filter(function (elkItem: any) { return elkItem._source.subcategoryid === subcatid });
			} else if (catid !== null) {
				elkRestauents = allBusinessItems.filter(function (elkItem: any) { return elkItem._source.categoryid === catid });
			} else {
				elkRestauents = allBusinessItems.filter(function (elkItem: any) { return elkItem._source.recordtype === 'Event' });
			}
			//await Utils.GetAllItems(this.props.city,catid);
			//let restaurentRankings=await Utils.GetBusinessRanking(this.props.cityid,catid);
			let yellowPagesNow = moment().format("YYYY-MM-DD");
			if (subcatid !== null) {
				restaurentRankings = allAdvertisementItems.filter(function (elkItem: any) {
					//return elkItem._source.subcategoryid && new Date() >= new Date(elkItem._source.startdate) && new Date() <= new Date(elkItem._source.tilldate)
					return elkItem._source.subcategoryid && moment(yellowPagesNow, "YYYY-MM-DD").diff(moment(elkItem._source.startdate, "YYYY-MM-DD")) >= 0 && moment(elkItem._source.tilldate, "YYYY-MM-DD").diff(moment(yellowPagesNow, "YYYY-MM-DD")) >= 0
				});
			} else if (catid !== null) {
				restaurentRankings = allAdvertisementItems.filter(function (elkItem: any) {
					return elkItem._source.categoryid === catid && moment(yellowPagesNow, "YYYY-MM-DD").diff(moment(elkItem._source.startdate, "YYYY-MM-DD")) >= 0 && moment(elkItem._source.tilldate, "YYYY-MM-DD").diff(moment(yellowPagesNow, "YYYY-MM-DD")) >= 0
				});
			} else {
				restaurentRankings = allAdvertisementItems.filter(function (elkItem: any) {
					return elkItem._source.recordtype === 'Events' && moment(yellowPagesNow, "YYYY-MM-DD").diff(moment(elkItem._source.startdate, "YYYY-MM-DD")) >= 0 && moment(elkItem._source.tilldate, "YYYY-MM-DD").diff(moment(yellowPagesNow, "YYYY-MM-DD")) >= 0
				});
			}
			if (restaurentRankings.length > 0) {
				let sortByRank = _.sortBy(restaurentRankings, (e) => {
					return e._source.positionno
				});
				sortByRank.map(function (rank) {
					if (rank._source.businessentityid !== null) {
						let tempItem = elkRestauents.filter(function (elkItem: any) { return elkItem._source.id === rank._source.businessentityid });
						tempRestaurents = [...tempRestaurents, ...tempItem];
					}
				});
				if (tempRestaurents.length > 0) {
					if (tempRestaurents.length < 10) {
						let combined = [...tempRestaurents, ...elkRestauents];
						toprestaurents = _.uniqBy(combined, "_id").slice(0, 10);
					} else {
						toprestaurents = tempRestaurents.slice(0, 10);
					}
					results = toprestaurents;
				} else {
					results = elkRestauents.slice(0, 10);
				}
			} else {
				results = elkRestauents.slice(0, 10);
			}
			resolve(results);
		})
	}
	handleSnackbarClose = (event: any, reason: any) => {
		if (reason === 'clickaway') {
			return;
		}
		this.setState({ pollsnackbaropen: false });
		//setOpen(false);
	};
	// sidebarToggle =()=>{
	//     $("#jh-wrapper").toggleClass("toggled");
	// }
	render() {
		const { listItems, restaurents, groceries, jewelry, education, spa, prefServices,
			notificationItems, eventItems, fashion, carRepair, clinicItems, loading, restLoading,
			groceryLoading, jewelryLoading, spaLoading, educationLoading, apartmentsLoading,
			fashionLoading, clinicsLoading, carServiceLoading, profLoading, eventLoading, employmentItems, employmentLoading,
			pollsnackbaropen, vertical, horizontal, successMsg } = this.state;
		return (

			<div className="d-flex" id="jh-wrapper">
				<div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
					<div className="col-12 jh-main-content">
						<div className="row jh-main-content-block">
							<div className="col-lg-6 col-md-6 col-12">
								<Adds1 stateid={this.props.stateid} countryid={this.props.countryid}></Adds1>
							</div>
							<div className="col-lg-6 col-md-6 col-12"
								style={{ paddingRight: '0px' }}>
								<CarousalApp cityid={this.props.cityid}></CarousalApp>
							</div>
						</div>
						<div className="row jh-main-content-block">

							<div className="col-lg-11 col-md-11 col-12">
								<div className="row jh-inner-content-block">
									<div className="col-lg-3 col-md-3 col-12">
										<div className="jh-widget-blocks row">
											<div className="col-lg-12 col-md-12 col-12">
												<div className="jh-widget-block">
													<QuickLinks nodataHeight="125px" history={this.props.history}
														loading={restLoading}
														items={restaurents}
														categoryID="5"
														categoryName="Entertainment"
														subCategoryID="25"
														subCategoryName="Restaurents"
														widgettitle="Dining / Restaurants"
														widgetcolor="widget-color-1"
														widgetIcon="widget-icon fa fa-cutlery"></QuickLinks>
												</div>
												<div className="jh-widget-block">
													<QuickLinks nodataHeight="210px"
														categoryID="2"
														categoryName="Education"
														subCategoryID="0"
														subCategoryName="NA"
														history={this.props.history} loading={educationLoading} items={education} widgetIcon="widget-icon fa fa-leanpub" widgettitle="Education / Kids" widgetcolor="widget-color-7"></QuickLinks>
												</div>
												<div className="jh-widget-block">
													{/* <Poll successCallback={this.SnackbarCallback} country={this.props.country} cityid={this.props.cityid} cityname={this.props.city} history={this.props.history}></Poll> */}
													<QuickLinks nodataHeight="125px" history={this.props.history}
														loading={clinicsLoading}
														items={clinicItems}
														categoryID="5"
														categoryName="Health"
														subCategoryID="null"
														subCategoryName="Health"
														widgettitle="Health / Hospitals"
														widgetcolor="widget-color-1"
														widgetIcon="widget-icon fa fa-cutlery"></QuickLinks>
												</div>
											</div>
										</div>
									</div>

									<div className="col-lg-6 col-md-6 col-12">
										<div className="jh-widget-blocks row">
											<div className="col-lg-6 col-md-6 col-12">
												<div className="jh-widget-block">
													<QuickLinks nodataHeight="180px" categoryID="3" categoryName="Professional Services" subCategoryID="0" subCategoryName="NA" history={this.props.history} loading={profLoading} items={prefServices} widgetIcon="widget-icon fa fa-yelp" widgettitle="Prefessional Services" widgetcolor="widget-color-10"></QuickLinks>
												</div>
												<div className="jh-widget-block">
													<QuickLinks nodataHeight="125px"
														history={this.props.history}
														categoryID="6"
														categoryName="Home Needs"
														subCategoryID="34"
														subCategoryName="Saloon/SPA"
														loading={spaLoading} widgetIcon="widget-icon fa fa-scissors" items={spa} widgettitle="Salon / SPA" widgetcolor="widget-color-2"></QuickLinks>
												</div>
												<div className="jh-widget-block">
													<QuickLinks nodataHeight="125px"
														categoryID="1"
														categoryName="Automotive"
														subCategoryID="0"
														subCategoryName="NA"
														history={this.props.history} loading={carServiceLoading} widgetIcon="widget-icon fa fa-car" items={carRepair} widgettitle="Automotive" widgetcolor="widget-color-9"></QuickLinks>
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-12">
												<div className="jh-widget-block">
													<QuickLinks nodataHeight="125px"
														categoryID="6"
														categoryName="Home Needs"
														subCategoryID="31"
														subCategoryName="Grocery"
														history={this.props.history} loading={groceryLoading} widgetIcon="widget-icon fa fa-shopping-basket" items={groceries} widgettitle="Groceries" widgetcolor="widget-color-3"></QuickLinks>
												</div>
												<div className="jh-widget-block">
													<QuickLinks nodataHeight="125px"
														categoryID="6"
														categoryName="Home Needs"
														subCategoryID="33"
														subCategoryName="Jewellary"
														history={this.props.history} loading={jewelryLoading} widgetIcon="widget-icon fa fa-diamond" items={jewelry} widgettitle="Jewelers" widgetcolor="widget-color-6"></QuickLinks>
												</div>
												<div className="jh-widget-block">
													<QuickLinks nodataHeight="125px"
														categoryID="6"
														categoryName="Home Needs"
														subCategoryID="35"
														subCategoryName="Fashions"
														history={this.props.history} loading={fashionLoading} widgetIcon="widget-icon fa fa-shopping-cart" items={fashion} widgettitle="Fashion" widgetcolor="widget-color-4"></QuickLinks>
												</div>
											</div>
										</div>

									</div>

									<div className="col-lg-3 col-md-3 col-12">
										<div className="jh-widget-blocks row">
											<div className="col-lg-12 col-md-12 col-12">
												<div className="jh-widget-block">
													<QuickLinks nodataHeight="125px"
														categoryID="4"
														categoryName="Real Estate"
														subCategoryID="0"
														subCategoryName="NA"
														history={this.props.history} loading={apartmentsLoading} items={listItems} widgetIcon="widget-icon fa fa-link" widgettitle="Real Estate" widgetcolor="widget-color-5"></QuickLinks>
												</div>
											</div>
											<div className="col-lg-12 col-md-12 col-12">
												<div className="jh-widget-block">
													<QuickLinks nodataHeight="125px"
														categoryID="NA"
														categoryName="Employment"
														subCategoryID="0"
														subCategoryName="NA"
														history={this.props.history} loading={employmentLoading} items={employmentItems} widgetIcon="widget-icon fa fa-list" widgettitle="Employment" widgetcolor="widget-color-12"></QuickLinks>
												</div>
											</div>
											<div className="col-lg-12 col-md-12 col-12">
												<div className="jh-widget-block">
													<QuickLinks nodataHeight="125px"
														categoryID="NA"
														categoryName="Events"
														subCategoryID="0"
														subCategoryName="NA"
														history={this.props.history} loading={eventLoading} items={eventItems} widgetIcon="widget-icon fa fa-calendar" widgettitle="Events" widgetcolor="widget-color-11"></QuickLinks>
												</div>
											</div>
										</div>
									</div>

								</div>
							</div>
							<div className="col-lg-1 col-md-1 col-12">
								<Adds2 city={this.props.city} allBusiness={this.props.allBusinessItems} allRanks={this.props.allBusinessRankItems}></Adds2>
							</div>
						</div>
					</div>
					<div className="row jh-adv4-wrapper">
						{this.state.adv4gifs.length > 0 && this.state.adv4gifs.map(function (item) {
							return (
								<div className="col-lg-2 col-md-2 col-12 jh-adv4-img-wrapper">
									<Link to={`/entity/${item._source.businessname}/${item._source.businessentityid}`}><img src={`https://admin.jaiho.com/assets/businessimg/${item._source.gifname}`} alt="#" style={{ width: '100%' }} /></Link>
								</div>
								
							)
						})
						}
					</div>
				</div>
				<Snackbar open={pollsnackbaropen} autoHideDuration={3000} onClose={this.handleSnackbarClose} anchorOrigin={{ vertical, horizontal }}
				>
					<Alert onClose={this.handleSnackbarClose} severity="success">
						{successMsg}
					</Alert>
				</Snackbar>
			</div>



		)

	}
}



export default Home;

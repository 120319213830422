import React from 'react';


class AdminPage extends React.Component {
    render() {
      return (
          <div className="container">
        <div className="row" style={{ padding: '5px' }}>
          <div className="col-lg-1 col-md-1 col-12">
          </div>
          <div className="col-lg-5 col-md-5 col-12">
            <div id="contact" className="contact">
              <div className="contact-head">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="form-head">
                      <div className="row" style={{ marginLeft: '30%', padding: '25px' }}>
                        <div style={{ textAlign: 'center', fontSize: '30px' }}>
                          <b>Admin</b>
                        </div>
                      </div>
                      
                      
                      <div className="form-group">
                        <input name="country" className={"form-control"} type="text" placeholder="Country" />
                        {/* value={this.state.regfields.username} onChange={this.handleChange} /> */}
                        {/* <small className="text-danger">{this.state.errors.username}</small> */}
                      </div>
                      
                      
  
                      <div className="form-group">
                        <div className="button" style={{ marginLeft: '35%', marginBottom: 40 }}>
                          <button type="submit" className="btn" >Add</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          </div>
        </div>
      
      )
    }
  }
  
  

export default AdminPage;

import React from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import Precident from '../img/DrKrishna.jpeg';
import CEO from '../img/ceopic.jpeg';
class AboutUS extends React.Component<{
    componentName: any
}> {
    constructor(props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.componentName("About");
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    render() {
        return (
            <div className="d-flex" id="jh-wrapper">
                <div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
                    <div className="row">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">About Us</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="about-us" style={{ padding: "0px" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="about-text">
                                        <h2>Who We Are</h2>
                                        <p>
                                            Jai Ho App & Web Application is a Social Networking platform useful in connecting the
                                            business with common man across over 4000 cities in India, JaiHo over a period of time
                                            also plans to connect over 320 cities globally. The search services are targeting to
                                            have a dedicated channel for flow of information about the host of business under
                                            one umbrella. Our primary target would be Employment, Health Care, Education, Agriculture,
                                            Entertainment, Product services, Electronics, Matrimonial, Real Estate and the list goes on.
                                        </p>
                                        <p>
                                            JaiHo's search services will be available to users across multiple platforms such as website, mobile website, Apps, voice and text (SMS).
                                            JaiHo's search services bridges the gap between users and businesses by helping the users find relevant service providers for
                                            various products and services effortlessly, while helping businesses listed in JaiHo's database to expand the customer base
                                            across the geographical boundaries.
                                            JaiHo Apps will be available to the business and end users on Android and iOS platforms and have location - based
                                            services for mobile internet users.
                                            JaiHo's voice services will be available on a toll-free no, which will be accessible 24 hours a day, 7 days
                                            a week with multi-lingual support.
                                            With its registered & corporate office based in Hyderabad, JaiHo also plans to have its footprints in key
                                            cities of India in the first phase, cities such as  Ahmedabad, Bengaluru, Chandigarh, Chennai, Coimbatore, Delhi,
                                            Mumbai, Jaipur, Kolkata and Pune are the primary targets.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="about-us" style={{ padding: "0px" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="about-text">
                                        <h2>Our Mission</h2>
                                        <p>
                                            To provide fast, free, reliable and comprehensive information to our users and connect buyers to sellers.
                                        </p>
                                        <p>
                                            <ul className="jh-about-list">
                                                <li>
                                                    The company's operations began in 2020 with offering local search services
                                                    under the JaiHo brand, which is now the leading local search engine in India & USA.
                                                </li>
                                                <li>
                                                    The official website www.jaiho.com was launched in 2020.
                                                </li>
                                                <li>
                                                    JaiHo's search services bridge the gap between users and businesses by helping users find relevant
                                                    providers of products and services effortlessly, while helping businesses listed in JaiHo's database
                                                    to market their offerings.
                                                </li>
                                                <li>
                                                    JaiHo Apps are available on Android, iOS platforms and have location - based services for mobile internet users.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="about-us" style={{ padding: "0px" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="about-text">
                                        <h2>Key Highlights</h2>

                                        <p>
                                            <ul className="jh-about-list">
                                                <li style={{ listStyle: "square" }}>
                                                    <b>Pan India presence:</b>  Services will be offered in about 4000+ cities in the Indian subcontinent.
                                                </li>
                                                <li style={{ listStyle: "square" }}>
                                                    <b>Advanced and scalable technology platform:</b>  A vast range of features for a more engaging user experience
                                                    with 230 transaction-oriented searches plus verticals, – JaiHo's own social sharing platform, and a Real
                                                    Time Chat Messenger, on a single platform.
                                                </li>
                                                <li style={{ listStyle: "square" }}>
                                                    <b>Attractive value proposition for local MSMEs:</b>  Jai Ho's value-added offerings to the
                                                    MSMES will provide them with huge online visibility, payment solutions,
                                                    customized website, mobile site and mobile app; all packaged together, will make it
                                                    an attractive value proposition for MSMEs.
                                                </li>
                                                <li style={{ listStyle: "square" }}>
                                                    <b>Local Expertise:</b>  planning to hit the market with a strong and widespread sales force,
                                                    in-depth local market knowledge, JaiHo will enjoy significant expertise across all
                                                    regions of the country.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="about-us" style={{ padding: "0px" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="about-text">
                                        <h2>CSR</h2>
                                        <p>
                                            As a respirable startup we at JaiHo have in principle decided that 1/3 our profits go
                                            toward the Army welfare fund and another 1/3 goes towards improving educational infrastructure
                                            in rural India.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="about-us " >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="about-text" style={{ marginTop: "0px" }}>
                                        <h2>President</h2>
                                        <div className="jh-president-text-block row">
                                            {/* <div className="jh-president-text-block-img col-md-4">
    <img src={Precident} className="jh-president-img"/>
</div> */}
                                            <div className="jh-president-text-block-text col-md-12">
                                                <p> <span className="jh-president-img-text"><img src={Precident} className="jh-president-img" alt="Dr. Krishna Harshvardhan Reddy" /> <span className="jh-president-name">Dr. Krishna Harshvardhan Reddy</span></span>

                                                    <b>Dr. Krishna Harshvardhan Reddy</b> A medical professional by profession is also a serial Entrepreneur, Investor & Philanthropist, based out in Michigan USA.
                                                    Born to a traditional south Indian family in erstwhile state Andhra Pradesh now known as Telangana. Dr. Reddy after completing his MBBS from Osmania Medical College (1986-92) Hyderabad,
                                                    went to the US for higher studies in the field of Medicine where he had specialized and Board Certified in Internal Medicine. Dr. Reddy still takes care of indigenous population of Detroit downtown in Michigan.
                                                </p>
                                                <p>Dr. Reddy’s passion for innovative thinking and always do something new which others are not explored before.
                                                    In 1993 Dr. Reddy conducted first mock test in India successfully with 9500 medical and engineering aspirants and gave results next day.
                                                    Top rank holders are also stood in top in main exam thereby becoming a trend after that year all over India. JaiHo.com mission was to provide info about desi businesses,
                                                    add or find services, look for or post an employment, find your partner and blogs for free.</p>
                                                <p>In the Jan, 2015 Dr. Reddy along with his team of professional technocrats founded iGuru Portal Services LLP with a sole mission and vision “To Build & Implement the world best, state of the art Web Application and App for the education domain.</p>
                                                <p>In a short span we already have approximately 1250 educational institutions signed up with iGuru across the country empowering over 25,000 teachers and over Million students.</p>

                                            </div>
                                            <div className="col-md-12">
                                                <p>Dr. Reddy is founder and Director for many startups and fully grown companies in India and the United States of America.</p>
                                                <p>1. Health First Medical Center, Michigan<br />
                                                    2. iGuru Portal Services PVT Ltd. Hyderabad<br />
                                                    3. India Flavors Authentic Indian Restaurant in Michigan<br />
                                                    4. iGolf in Southfield, Michigan</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-12">
                                            <div className="about-text" style={{ marginTop: "20px" }}>
                                                <h2>CEO</h2>
                                                <div className="jh-president-text-block row">
                                                    {/* <div className="jh-president-text-block-img col-md-4">
    <img src={Precident} className="jh-president-img"/>
</div> */}
                                                    <div className="jh-president-text-block-text col-md-12">
                                                        <p> <span className="jh-president-img-text"><img src={CEO} className="jh-president-img" alt="Dr. Krishna Harshvardhan Reddy" /> <span className="jh-president-name">Jasvinder Singh Mukar</span></span>

                                                            <b>Jasvinder Singh Mukar</b> Jasvinder is an MBA in Marketing with progressively responsible experience of over thirteen years with reputed organizations. He is an accomplished and applauded professional with various awards, promotions and other recognitions for stellar performance in his corporate life and then moved on to start his own hospitality business in 2021 to follow is passion.
                                                        </p>
                                                        <p>Having core expertise in starting and launching business from scratch and launching new products, Mr. Mukar also possesses the ability to develop, train and motivate a strong operational team to drive revenues with excellent communication and interpersonal skills.</p>


                                                    </div>
                                                    <div className="col-md-12">
                                                        <p>JaiHo App & Web Application is a Social Networking platform useful in connecting the businesses with common man across 4000 cities in India, JaiHo over a period of time also plans to connect over 320 cities globally. The search services are targeting to have a dedicate channel for flow of information about the host of business under one umbrella. The search services are targeting to have a dedicate channel for flow of information about the host of business under one umbrella. Our primary target would on Health Care, Education, Agriculture, Entertainment, Product services, Electronics, Matrimonial, Real Estate and list goes on.</p>
                                                        <p>Led by a strong management team, Jai Ho has a solid Franchise Partnership Program (FPP), which brings to the table various level of Franchise Association programs based upon the domain expertise/area of operations & Investments potential, of an individual/Group or Companies.With the intent to work closely with JaiHo franchise associates he wants to create 10,000 jobs for this community portal. Operating in multiple cities, states, and foreign cities, this will be one of the biggest social enterprises in India and abroad.</p>
                                                        <p><b>The Giving Pledge</b> as Philanthropist Dr. Reddy gave 1400 scholarships to the students of 12 schools across India who achieve high laurels in Academics.
                                                            Dr. Reddy also contributes 15% of his net income to community services.
                                                            Dr. Reddy has also committed and have in principle decided that 1/3 of JaiHo profits goes toward Army welfare fund in India and another 1/3 goes to cause for improving educational infrastructure in the rural India.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}



export default AboutUS;

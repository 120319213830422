import React from 'react';
import MUIRichTextEditor from 'mui-rte'
import AsyncImageUpload from './custom/RichText';
import Utils from './utils/homeutils';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

var moment: any = require('moment');

class Trends extends React.Component<{
    componentName: any,
    countryid:any,
    cityid:any,
    history: any,
    match: any
}, {
    trend: any
}> {
    constructor(props: any) {
        super(props);
        this.state = {
            trend: ''
        }
    }
    GoToHome = () => {
        this.props.history.push("/");
    }
    async componentDidUpdate(prevProps: any, _prevState: any) {
        if (Number(prevProps.cityid)!= Number(this.props.cityid)){
            let objTrends = await Utils.GetAllTrends();
            let alltrends: any = [];
            let citytrends: any = [];
            let cid=this.props.countryid;
            let cityid=this.props.cityid;
            let now = moment().format("YYYY-MM-DD");
            citytrends = objTrends.filter(function (elkItem: any) {           
                return elkItem._source.cityid===Number(cityid) &&  moment(now, "YYYY-MM-DD").diff(moment(elkItem._source.startdate, "YYYY-MM-DD")) >= 0 && elkItem._source.isactive==true
            });
            if(citytrends.length==0){
                alltrends = objTrends.filter(function (elkItem: any) {           
                    return elkItem._source.countryid===Number(cid) &&  moment(now, "YYYY-MM-DD").diff(moment(elkItem._source.startdate, "YYYY-MM-DD")) >= 0 && elkItem._source.isactive==true
                });
            }
            if(citytrends.length>0){
                this.setState({ trend: citytrends[0]._source.trendtext });
            }else if(alltrends.length>0){
                this.setState({ trend: alltrends[0]._source.trendtext });
            }else{
                this.setState({ trend: "No Data found" });
            }
            
        }
    }
    async componentDidMount() {
        this.props.componentName("Trends");
        let objTrends = await Utils.GetAllTrends();
        let alltrends: any = [];
        let citytrends: any = [];
        let cid=this.props.countryid;
        let cityid=this.props.cityid;
        let now = moment().format("YYYY-MM-DD");
        citytrends = objTrends.filter(function (elkItem: any) {           
            return elkItem._source.cityid===Number(cityid) &&  moment(now, "YYYY-MM-DD").diff(moment(elkItem._source.startdate, "YYYY-MM-DD")) >= 0 && elkItem._source.isactive==true
        });
        if(citytrends.length==0){
            alltrends = objTrends.filter(function (elkItem: any) {           
                return elkItem._source.countryid===Number(cid) &&  moment(now, "YYYY-MM-DD").diff(moment(elkItem._source.startdate, "YYYY-MM-DD")) >= 0 && elkItem._source.isactive==true
            });
        }
        if(citytrends.length>0){
            this.setState({ trend: citytrends[0]._source.trendtext });
        }else if(alltrends.length>0){
            this.setState({ trend: alltrends[0]._source.trendtext });
        }else{
            this.setState({ trend: "No Data found" });
        }
        //let objSelectedEntityPG=await Utils.GetSingleEntityFromPG(Number(entityID));
        
    }
    private GoToYellowpages = (cat: any, catid: any) => {
        //const { history } = this.props;
        this.props.history.push("/yellowpages/" + cat + "/" + catid + "");
    }
    render() {

        return (
            <div className="d-flex" id="jh-wrapper">
                <div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
                    <div className="row">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/"}>Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Trending</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-12 jh-main-content jh-inner-page-main-content">
                        <div className="row jh-main-content-block">
                            {/* <div className="jh-card-page-title-block">
                                <h4 className="jh-card-page-title">Trending News</h4>
                            </div> */}
                        </div>
                        <div className="row jh-main-content-block">
                            <div className="card-section">
                                <div className="card">
                                    <div className="col-md-12 col-lg-12 col-sm-12">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {ReactHtmlParser(this.state.trend)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>



        )



        
    }
}



export default Trends;

import React from 'react';
import Utils from '../utils/homeutils';
var $: any = require("jquery");
const imgpath=process.env.REACT_APP_NOTI_IMG_Path;
class CarousalApp extends React.Component<{
  cityid: any
}, {
  notificationItems: any[]
}>{
  constructor(props: any) {
    super(props);
    this.state = {
      notificationItems: []
    }
  }

  async componentDidUpdate(prevProps: any, _prevState: any) {
    if (prevProps.cityid !== this.props.cityid) {
      $("#carousel").carousel();
      let elkNotifications = await Utils.GetNotificationsItems(this.props.cityid);
      this.setState({ notificationItems: elkNotifications });
    }
  }

  async componentDidMount() {
    let elkNotifications = await Utils.GetNotificationsItems(this.props.cityid);
    this.setState({ notificationItems: elkNotifications });

  }
  render() {
    const { notificationItems } = this.state;
    return (
      <div>
        <div className="marquee">
          <div className="row">
            <div className="col-md-12">
              <div className="carousel slide" data-ride="carousel" id="carousel">
                <div className="carousel-inner">
                  {notificationItems.map(function (item, index) {

                    return (
                      <div className={index === 0 ? "carousel-item jh-carousel-notification active" : "carousel-item jh-carousel-notification"} key={item+index}>
                        <div className="jh-carousel-notification-inner">
                          <div className="jh-carousel-notification-img">
                            <img src={`${imgpath}/${item._source.imagename}`} />
                          </div>
                          <div className="jh-carousel-notification-list">
                            <span className="marqleft">{item._source.description}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )

  }
}


export default CarousalApp;

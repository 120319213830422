import React, { Props } from 'react';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/jaihostyles.css';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
class ChangePwd extends React.Component<{
    isPwdChanged:any,
    history:any,
    componentName:any
}, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            PasswordChangeStatus: "",
            fields: {},
            errors: {}
        }
        // Binding functions
        this.handleChange = this.handleChange.bind(this);
        this.SubmitClick = this.SubmitClick.bind(this);
    }
    componentDidMount(){
       this.props.componentName("cpwd");
    }
    //Functions
    validateForm = () => {
        let fields = this.state.fields;
        let errors = this.state.errors;
        errors = {};
        let formIsValid = true;
        if (!fields.email) {
            formIsValid = false;
            errors["email"] = "Please enter email.";
        }
        let regexEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (fields.email && !regexEmail.test(fields.email)) {
            formIsValid = false;
            errors["email"] = "Please enter valid email.";
        }
        if (!fields.oldpassword) {
            formIsValid = false;
            errors["oldpassword"] = "Please enter old password.";
        }
        if (!fields.newpassword) {
            formIsValid = false;
            errors["newpassword"] = "Please enter new password.";
        }
        this.setState({
            errors: errors
        });
        return formIsValid;
    }
    SignClick = () => {
        this.props.isPwdChanged("Y");
        this.props.history.push('/login');
      }

    SubmitClick = (e: any) => {
        let mainScope = this;
        e.preventDefault();
        if (this.validateForm()) {
            let loginURL = "http://jaiho.com/ChangePassword";
            axios.post(loginURL, {
                "email": this.state.fields.email,
                "oldpassword": this.state.fields.oldpassword,
                "newpassword": this.state.fields.newpassword,
            })
                .then(function (response) {
                    console.log(response);
                    if (response.data.errorHandler.success == "Y") {
                        localStorage.clear();
                        mainScope.setState({ PasswordChangeStatus: "Success" });
                    } else {
                        mainScope.setState({ PasswordChangeStatus: "Failed" });
                    }
                })
                .catch(function (error) {
                    mainScope.setState({ PasswordChangeStatus: "Failed" });
                });
            let fields = this.state.fields;
            fields["email"] = "";
            fields["oldpassword"] = "";
            fields["newpassword"] = "";
            this.setState({ fields: fields });
        }

    }

    handleChange = (e: any) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
    }


    render() {
        return (
            <div className="container" style={{ height: '600px' }}>
                <div className="row" style={{ padding: '5px' }}>
                    <div className="col-lg-3 col-md-3 col-12">
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div id="contact" className="contact">
                            <div className="contact-head">
                                <div className="row">
                                    <div className="col-md-12 col-lg-12 col-sm-12">
                                        <div className="form-head">
                                            <div style={{ textAlign: 'center', fontSize: '30px', padding: '25px' }}>
                                                <b>CHANGE PASSWORD</b>
                                            </div>
                                            {this.state.PasswordChangeStatus &&
                                                <div className={this.state.PasswordChangeStatus == 'Success' ? "alert alert-success" : "alert alert-danger"}>
                                                    {this.state.PasswordChangeStatus == "Success" && <p>Password changed successfully. Click <a href="#" onClick={this.SignClick}>here</a> to login</p>}
                                                    {this.state.PasswordChangeStatus == "Failed" && <span>Failed to change the password</span>}
                                                </div>}
                                            <div className="form-group">
                                                <input name="email" className={this.state.errors.email ? "border-danger" : "form-control"} type="text" placeholder="Email" value={this.state.fields.email} onChange={this.handleChange} />
                                                <small className="text-danger">{this.state.errors.email}</small>
                                            </div>
                                            <div className="form-group">
                                                <input name="oldpassword" className={this.state.errors.oldpassword ? "border-danger" : "form-control"} type="text" placeholder="Old Password" value={this.state.fields.oldpassword} onChange={this.handleChange} />
                                                <small className="text-danger">{this.state.errors.oldpassword}</small>
                                            </div>
                                            <div className="form-group">
                                                <input name="newpassword" className={this.state.errors.newpassword ? "border-danger" : "form-control"} type="text" placeholder="New Password" value={this.state.fields.newpassword} onChange={this.handleChange} />
                                                <small className="text-danger">{this.state.errors.newpassword}</small>
                                            </div>
                                            <div className="form-group">
                                                <div className="button" style={{ marginLeft: '35%' }}>
                                                    <button type="submit" className="btn" onClick={this.SubmitClick}>SUBMIT</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-12">
                    </div>

                </div>
            </div >

        )

    }
}



export default ChangePwd;

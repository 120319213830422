import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import axios from 'axios';
import {
  Button,
  Typography,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "51%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  title: {
    marginLeft: theme.spacing(2),
    marginTop: 8,
    marginBottom : 32,
    flex: 1,
  },
  formButtonControl: {
    margin: theme.spacing(5),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    marginLeft: -10,
  },
}));

function CreateTopic() {
  const [topicCategory, setTopicCategory] = React.useState("");
  const [topicTitle, setTopicTitle] = React.useState("");
  const [name, setName] = React.useState("");
  const [createTopicMail, setCreateTopicMail] = React.useState("");
  const [topicMessage, setTopicMessage] = React.useState("");
  const [topicInfo,setTopicInfo]=React.useState<any>({});
 const[topicErrors,setTopicErrors]=  React.useState<any>({});
 const [loading, setLoading] = React.useState(false);
  const [otpMessage, setOtpMessage] = React.useState("");
  const history = useHistory();

  const handleCategoryChange = (event: any) => {
    console.log("category" + event.target.value);
    setTopicCategory(event.target.value);
  };
const postReplyHandleChange=(e:any) =>{          
        var fields:any = topicInfo;        
        fields[e.target.name] = e.target.value;
        setTopicInfo(fields);      
  }
  const generateOTP=()=>{
	setLoading(true);
	var fields:any=topicInfo;
	var axiosConfig = {
	  headers: {		  
			 Accept: 'application/json',
			'Content-Type': 'application/json'
	  }	  
	};
	axios.post('https://api.jaiho.com/DiscussionReplyOTP', {
		DiscussionID:0,
		Useremail:fields.useremail
	}, axiosConfig)
	.then((res) => {
		setLoading(false);
		setOtpMessage("OTPSENT");		
	})
	.catch((err) => {
		setLoading(false);
	  console.log("AXIOS ERROR: ", err);
	})
}
const createTopicValidateForm=()=> {
        var fields :any= topicInfo;
        let errors = topicErrors;
			errors["username"] = "";			
        let formIsValid = true;
		if (!fields.username) {
            formIsValid = false;
            errors["username"]="*Please enter your name.";
        }
		if (!fields.useremail) {
            formIsValid = false;
            errors["useremail"]="*Please enter your email.";
        }
		let regexEmail= /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
         if (!regexEmail.test(fields.useremail)) {
          formIsValid = false;
          errors["useremail"]="*Please enter valid email.";
         }
		 if (!fields.title) {
            formIsValid = false;
            errors["title"]="*Please enter message title.";
         }
		 if (!fields.category) {
            formIsValid = false;
            errors["category"]="*Please select category";
         }
		 if (!fields.description) {
            formIsValid = false;
            errors["description"]="*Please enter description.";
        }
		if (!fields.otp) {
            formIsValid = false;
            errors["otp"]="*Please enter OTP.";
        }
		setTopicErrors(errors);
       return formIsValid;
  }
  const handleSave = (e:any) => {	
	   e.preventDefault();
		if(createTopicValidateForm())
		{
			var fields :any= topicInfo;
			var axiosConfig = {
				headers: {		  
				 Accept: 'application/json',
				'Content-Type': 'application/json'
				}
			};
			axios.post('https://api.jaiho.com/Discussion', {
			title: fields.title,
			username: fields.username,
			useremail: fields.useremail,
			description: fields.description,
			discussioncategoryname: fields.category,
			OTP:fields.otp,
			noofviews: 0,
			noofreplies: 0
			}, axiosConfig)
			.then((res) => {
				if(res.data.errorHandler.success=="Y"){					
					history.push("/discussion");
				  }else{		  
					 if(res.data.errorHandler.message=="InvalidOTP"){
						  setOtpMessage("InvalidOTP");
					  }
				  }		
			})
			.catch((err) => {
			  console.log("AXIOS ERROR: ", err);
			})
		}		
	};
  const classes = useStyles();
  return (
    <div className="d-flex" id="jh-wrapper">
      <div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
        <div className="row">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
			  <li className="breadcrumb-item">
                <Link to="/discussion">Message Board</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Post New Message
              </li>
            </ol>
          </nav>
        </div>
        <div className="jh-content-body">
          <div className="col-md-12 jh-events-title">
            <h2 className="jh-title-1">Post New Message</h2>
          </div>
          <div className="col-11 col-lg-11 col-md-11 col-sm-12">
            <div className="jh-events">
                <div className="jh-events-body">
					<div className="row">
						<div className="col-md-6 col-lg-6 col-12">
							<div className="row">
								<div className="col-12">
									<div className="form-group">
										<label>Name <small className="text-danger">*</small></label>
										<input type="text" className="form-control" name="username"  onChange={postReplyHandleChange}/>
									    <span className="text-danger">{topicErrors.username}</span>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<div className="form-group">
										<label>Email <small className="text-danger">*</small></label>
										<input type="email" className="form-control" name="useremail" onChange={postReplyHandleChange}/>
										<span className="text-danger">{topicErrors.useremail}</span>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<div className="form-group">
										<label>Title <small className="text-danger">*</small></label>
										<input type="text" className="form-control" name="title"  onChange={postReplyHandleChange}/>
									    <span className="text-danger">{topicErrors.title}</span>
									</div>
								</div>
							</div>	
							<div className="row">
								<div className="col-12">
									<div className="form-group">
										<label>Category <small className="text-danger">*</small></label>
										<select className="form-control" name="category" onChange={postReplyHandleChange}>
											<option value="NA"></option>
											<option value="General">General</option>
											<option value="Immigration">Immigration</option>
											<option value="Travel">Travel</option>
										</select>
										<span className="text-danger">{topicErrors.category}</span>
									</div>
								</div>
							</div>							
							<div className="row">
								<div className="col-12">
									<div className="form-group">
										<label>Description <small className="text-danger">*</small></label>
										<textarea className="form-control" name="description" cols={6} onChange={postReplyHandleChange}/>
										<span className="text-danger">{topicErrors.description}</span>
									</div>
								</div>
							</div>							
							<div className="row">
								<div className="col-md-8 col-lg-8 col-12">
									<div className="form-group">
										<label>OTP <small className="text-danger">*</small></label>
										<input type="text" className="form-control" name="otp" onChange={postReplyHandleChange}/>
										<span className="text-danger">{topicErrors.otp}</span>
									</div>
								</div>
								<div className="col-md-4 col-lg-4 col-12">
									<div className="form-group">										
										<button className="btn btn-primary btn-block" style={{marginTop:"25px"}} onClick={generateOTP}>
											{loading && <span className="spinner-border spinner-border-sm"></span>}GET OTP</button>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
								{otpMessage=='OTPSENT' && <div className="alert alert-success">
										OTP has been sent to your email
								</div>}
								{otpMessage=='InvalidOTP' && <div className="alert alert-danger">
										Invalid OTP
								</div>}
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 col-sm-6 col-lg-6 col-12">
									<button className="btn btn-primary btn-block" onClick={handleSave}>Save</button>
								</div>
								<div className="col-md-6 col-sm-6 col-lg-6  col-12">
									<button className="btn btn-secondary btn-block" onClick={() => history.push("/discussion")}>Cancel</button>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-6 col-12">						
						</div>
					</div>
                </div>
              </div>
            </div>
         </div>          
        </div>
      </div>
  );
}

export default CreateTopic;

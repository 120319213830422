import React from 'react';
import Utils from './utils/homeutils';
import YellowPagesNavigation from '../components/leftnav';

class EventServices extends React.Component<{
	componentName: any,
	city: any,
	match:any,
	history:any
}, {	
    items: any[],
    categoryId: any
}> {
    constructor(props: any) {
        super(props);
        this.state = {
            items: [],
            categoryId: ""
        }
    }
    async componentDidUpdate(prevProps: any, _prevState: any) {        
        let entityID = this.props.match.params.id;
        if (prevProps.match.params.id === this.props.match.params.id)
            return;        
        this.setState({ categoryId: this.props.match.params.subcat });        
		let elkData = await Utils.GetAllEvents(this.props.city,Number(entityID));       
        this.setState({ items: elkData });
    }

    async componentDidMount() {        
        let entityID = this.props.match.params.id
        this.setState({ categoryId: this.props.match.params.subcat });        
		let elkData = await Utils.GetAllEvents(this.props.city,Number(entityID));       
        this.setState({ items: elkData });
    }
    private GoToEntity = (obj:any) =>  {
        console.log('obj '+JSON.stringify(obj));
        this.props.history.push("/evententity/"+obj._id+"");
      } 
      GoToHome = () => {
    this.props.history.push("/events");
  }
    render() { 
        let { items, categoryId } = this.state;
        let mainScope=this;
        return (
            <div className="d-flex" id="jh-wrapper">                
                <div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
				 <div className="row">
				  <nav aria-label="breadcrumb">
					<ol className="breadcrumb">
					  <li className="breadcrumb-item"><a href="#" onClick={this.GoToHome}>Home</a></li>
					  <li className="breadcrumb-item"><a href="#" onClick={this.GoToHome}>Events</a></li>
					  <li className="breadcrumb-item active" aria-current="page">{categoryId}</li>
					</ol>
				  </nav>
				</div>
                <div className="col-12 jh-main-content jh-inner-page-main-content"> 
					<div className="row jh-main-content-block">
                            <div className="jh-card-page-title-block">
                                <h4 className="jh-card-page-title">{categoryId}</h4>
                            </div>
                    </div>				
                    <div className="row jh-main-content-block">
                        <div className="col-12">
						{items.length==0 && <div>No Data Found</div>}
                            <div className="card-columns jh-card-columns">
                                    {items.map(function (item) {
                                        return (
                                            <div className="card yellow-card">
                                                <div className="card-body">
                                                    <h5 className="card-title"><a onClick={()=> mainScope.GoToEntity(item)}>{item._source.businessname}
                                        </a> </h5>
                                                    <p className="card-text"><i className="fa fa-phone tiles-phone-icon" aria-hidden="true"></i> {item._source.phone != "" ? item._source.phone : 'NA'}</p>
                                                    <p  className="card-text"><i className="fa fa-map-marker tiles-address-icon" aria-hidden="true"></i> {item._source.address}</p>

                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            
                            </div>                        
                                </div>
                    </div>
                </div>
                
                
            </div>



        )







    }
}



export default EventServices;

import React from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import Utils from './utils/homeutils';
import * as _ from 'lodash';

class AllServices extends React.Component<{
	allEntities: any[],
	categories: any[],
	subcategories: any[],
	componentName: any,
	city: any,
	cattype: any,
	match: any,
	history: any
}, {
	catItems: any[],
	subcatItems: any[],
	subCategory: any,
	subCategoryID: any,
	category: any,
	catID: any,
	isYelloPages: boolean
}> {
	constructor(props: any) {
		super(props);
		this.state = {
			catItems: [],
			subcatItems: [],
			subCategory: "",
			subCategoryID: 0,
			category: "",
			catID: 0,
			isYelloPages: false
		}
	}
	async componentDidUpdate(prevProps: any, _prevState: any) {

		if (prevProps.match.params.id === this.props.match.params.id)
			return;
		let entid = this.props.match.params.id;
		let catParams = this.props.match.params.cat;
		let subCatParams = this.props.match.params.subcat;
		if (this.props.cattype === "yellow") {
			this.setState({ isYelloPages: true, catItems: [], subcatItems: [] });
			return;
		}
		this.setState({ isYelloPages: false });
		let catid = 0;
		let catname = "";
		let subcatid = 0;
		let subcatname = "";
		let sortByCategories: any = [];
		let sortBySubCategories: any = [];
		if (this.props.cattype === "main") {
			catid = Number(entid);
			let filteredCategories = this.props.subcategories.filter(function (elkCat: any) { return elkCat._source.categoryid === Number(entid) && elkCat._source.isactive === true });
			sortByCategories = _.sortBy(filteredCategories, (e) => {
				return e._source.title
			});
			let objCategory = this.props.categories.filter(function (elkCat: any) { return elkCat._source.id === Number(catid) });
			if (objCategory.length > 0) {
				catname = objCategory[0]._source.title;
			}
		}
		if (this.props.cattype === "sub") {
			subcatid = entid;
			let filteredSubCategories = this.props.allEntities.filter(function (elkCat: any) { return elkCat._source.subcategoryid === Number(subcatid) });
			sortBySubCategories = _.sortBy(filteredSubCategories, (e) => {
				return e._source.title
			});
			let filteredCategories = this.props.subcategories.filter(function (elkCat: any) { return elkCat._source.categoryid === Number(catid) && elkCat._source.isactive === true });
			sortByCategories = _.sortBy(filteredCategories, (e) => {
				return e._source.title
			});
			let objSubCategory = this.props.subcategories.filter(function (elkCat: any) { return elkCat._source.id === Number(entid) });
			if (objSubCategory.length > 0) {
				subcatname = objSubCategory[0]._source.title;
				catname = objSubCategory[0]._source.categoryname;
				catid = objSubCategory[0]._source.categoryid;
			}
		}
		this.setState({ catItems: sortByCategories, subcatItems: sortBySubCategories, catID: Number(catid), category: catname, subCategoryID: subcatid, subCategory: subcatname });

	}

	async componentDidMount() {
		this.props.componentName("Tiles");
		let entid = this.props.match.params.id;
		let catParams = this.props.match.params.cat;
		let subCatParams = this.props.match.params.subcat;
		if (this.props.cattype === "yellow") {
			this.setState({ isYelloPages: true, catItems: [], subcatItems: [] });
			return;
		}
		this.setState({ isYelloPages: false });
		let mainCategories: any = [];
		let subCategories:any=[];
		if (this.props.categories.length === 0) {
			// Main categories
			let elkcategories = await Utils.GetCategoryItems();
			let filteredCategories = elkcategories.filter(function (elkCat:any) { return elkCat._source.isactive === true && elkCat._source.recordtype === 'Business' });
			let sortByCategories = _.sortBy(filteredCategories, (e) => {
				return e._source.title
			});
			// Sub categories
			let elksubcategories = await Utils.GetSubCategoryItems();
			let filteredSubCategories = elksubcategories.filter(function (elkSubCat:any) {
				return elkSubCat._source.isactive === true
			});
			let sortBySubCategories = _.sortBy(filteredSubCategories, (e) => {
				return e._source.title
			});
			mainCategories=sortByCategories;
			subCategories=sortBySubCategories;
		}else{
			mainCategories=this.props.categories;
			subCategories=this.props.subcategories;
		}
		let catid = 0;
		let catname = "";
		let subcatid = 0;
		let subcatname = "";
		let sortByCategories: any = [];
		let sortBySubCategories: any = [];
		if (this.props.cattype === "main") {
			catid = Number(entid);
			let filteredCategories = subCategories.filter(function (elkCat: any) { return elkCat._source.categoryid === Number(entid) && elkCat._source.isactive === true });
			sortByCategories = _.sortBy(filteredCategories, (e) => {
				return e._source.title
			});
			let objCategory = mainCategories.filter(function (elkCat: any) { return elkCat._source.id === Number(catid) });
			if (objCategory.length > 0) {
				catname = objCategory[0]._source.title;
			}
		}
		if (this.props.cattype === "sub") {
			subcatid = entid;
			let filteredSubCategories = this.props.allEntities.filter(function (elkCat: any) { return elkCat._source.subcategoryid === Number(subcatid) });
			sortBySubCategories = _.sortBy(filteredSubCategories, (e) => {
				return e._source.title
			});
			let filteredCategories = subCategories.filter(function (elkCat: any) { return elkCat._source.categoryid === Number(catid) && elkCat._source.isactive === true });
			sortByCategories = _.sortBy(filteredCategories, (e) => {
				return e._source.title
			});
			let objSubCategory = subCategories.filter(function (elkCat: any) { return elkCat._source.id === Number(entid) });
			if (objSubCategory.length > 0) {
				subcatname = objSubCategory[0]._source.title;
				catname = objSubCategory[0]._source.categoryname;
				catid = objSubCategory[0]._source.categoryid;
			}
		}
		this.setState({ catItems: sortByCategories, subcatItems: sortBySubCategories, catID: Number(catid), category: catname, subCategoryID: subcatid, subCategory: subcatname });
	}
	private GoToEntity = (obj: any) => {
		this.props.history.push("/entity/"+obj._source.businessname.replace(/[^a-zA-Z0-9]/g, '')+"/" + obj._source.id + "");
	}
	GoToHome = () => {
		this.props.history.push("/");
	}
	render() {
		let { catItems, subcatItems, subCategory, category, catID, subCategoryID, isYelloPages } = this.state;
		const { categories, subcategories } = this.props;
		let mainScope = this;
		return (
			<div className="d-flex" id="jh-wrapper">
				<div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
					<div className="row">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><a href="#" onClick={this.GoToHome}>Home</a></li>
								{!isYelloPages && <li className="breadcrumb-item"><Link to={"/yellowpages"}>Yellow Pages</Link></li>}
								{isYelloPages && <li className="breadcrumb-item active" aria-current="page">Yellow Pages</li>}
								{!isYelloPages && subCategory && <li className="breadcrumb-item">
									<Link to={`/yellowpages/${category.replace(/[^a-zA-Z0-9]/g, '')}/${catID}`}>{category}</Link></li>}
								{!isYelloPages && subCategory && <li className="breadcrumb-item active" aria-current="page">{subCategory}</li>}
								{!isYelloPages && !subCategory && <li className="breadcrumb-item active" aria-current="page">{category}</li>}
							</ol>
						</nav>
					</div>
					<div className="col-12 jh-main-content jh-inner-page-main-content">
						<div className="row jh-main-content-block">
							<div className="jh-card-page-title-block">
								{subCategoryID !== 0 && <h4 className="jh-card-page-title">{subCategory}</h4>}
								{subCategoryID === 0 && !isYelloPages && <h4 className="jh-card-page-title">{category}</h4>}
							</div>
						</div>
						<div className="row jh-main-content-block">

							{subcatItems.length === 0 && subCategoryID !== 0 && !isYelloPages && <div>No Data Found</div>}
							<div className="col-12">
								<div className="row jh-card-columns">
									{subcatItems.map(function (item) {
										return (
											<div className="col-lg-4 col-md-6 col-sm-6 yellow-card jh-card">
												<div className="jh-card-inner jh-card-bg-1">
													<div className="jh-card-header d-flex justify-content-center align-items-center">
														<h5 className="jh-card-title">
															<a onClick={() => mainScope.GoToEntity(item)}>{item._source.businessname}</a>
														</h5>
													</div>
													<hr className="jh-card-separator" />
													<div className="jh-card-body">
														<p className="jh-card-text">
															<i className="fa fa-phone tiles-phone-icon" aria-hidden="true"></i>
															<span className="tiles-address-text">{item._source.phone != "" ? item._source.phone : 'NA'}</span>
														</p>
														<p className="jh-card-text">
															<i className="fa fa-map-marker tiles-address-icon" aria-hidden="true"></i>
															<span className="tiles-address-text">{item._source.address}</span>
														</p>
													</div>

												</div>

											</div>
										);
									})}
								</div>
							</div>
							<div className="col-12">
								{subCategoryID == 0 && <div className="row jh-categories-list-block">
									{catItems.map(function (item) {
										return (
											<div className="jh-categories-list col-lg-4 col-md-6 col-sm-6">

												<div className="jh-categories-list-inner">
													<p className="jh-categories-title">
														<Link to={`/yellowpages/${category.replace(/[^a-zA-Z0-9]/g, '')}/${item._source.title.replace(/[^a-zA-Z0-9]/g, '')}/${item._source.id}`}>{item._source.title}</Link>

													</p>
												</div>
											</div>
										);
									})}
								</div>}

							</div>
							<div className="col-12">
								{isYelloPages && <div className="row jh-categories-list-block">
									{categories.map(function (item) {
										return (
											<div className="jh-categories-list col-lg-4 col-md-6 col-sm-6">
												<div className="jh-categories-list-inner">
													<p className="jh-categories-title">
														<Link to={`/yellowpages/${item._source.title.replace(/[^a-zA-Z0-9]/g, '')}/${item._source.id}`}>{item._source.title}</Link>
													</p>
												</div>
											</div>
										);
									})}
								</div>}

							</div>


						</div>
					</div>
				</div>


			</div>



		)







	}
}



export default AllServices;

import React, { Props } from 'react';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory } from 'react-router-dom';
import '../App.css';
import '../css/jaihostyles.css';
import JaihoFooterBottom from '../components/footerbtm';
import axios from 'axios';
class Login extends React.Component<{
  isAuthed: any,
  history: any,
  componentName:any
}, any>{
  constructor(props: any) {
    super(props);
    this.state = {
      errorMessage: "",
      RegistrationStatus: "",
      loginSuccess: "",
      fields: {},
      regfields: {},
      errors: {}

    }
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
    //this.SubmitClick=this.SubmitClick.bind(this);
  }
componentDidMount(){
  this.props.componentName("login");
}
  // Functions
  SubmitClick = () => {
    const { history, isAuthed } = this.props;
    let mainSate = this;
    let loginResp = null;
    let loginURL = "https://jaiho.com/Login/";
    axios.post(loginURL, {
      "email": this.state.fields.email,
      "password": this.state.fields.password,
    })
      .then(function (response) {
        console.log(response);
        if (response.data.errorHandler.success == "Y") {
          mainSate.setState({ loginSuccess: "Y" });
          mainSate.props.isAuthed(response.data);
          history.push('/');
        } else {
          mainSate.setState({ errorMessage: "Invalid login" });
          mainSate.props.isAuthed("F");
        }
      })
      .catch(function (error) {
        if (mainSate.state.loginSuccess != "Y") {
          mainSate.setState({ errorMessage: "Invalid login" });
          mainSate.props.isAuthed("F");
        }
      });
  }
  ForgotPwdClick = () => {
    this.props.history.push('/auth/forgotpwd');
  }
  UNChange = (e: any) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });
  }
  handleChange = (e: any) => {
    let regfields = this.state.regfields;
    regfields[e.target.name] = e.target.value;
    this.setState({
      regfields
    });
  }
  submituserRegistrationForm = (e: any) => {
    e.preventDefault();
    let mainScope = this;
    if (this.validateForm()) {
      axios.post("https://jaiho.com/Login", {
        email: this.state.regfields.email,
        name: this.state.regfields.username,
        phone: this.state.regfields.phone,
        city: this.state.regfields.city,
        state: this.state.regfields.st,
        country: this.state.regfields.country,
        password: this.state.regfields.password,
        confirmpassword: this.state.regfields.confirmpassword
      }).then(function (response) {
        if (response.data.statusCode === 200) {
          mainScope.setState({ RegistrationStatus: "Success" });
        } else {
          mainScope.setState({ RegistrationStatus: "Failed" });
        }
      })
        .catch(function (error) {
          mainScope.setState({ RegistrationStatus: "Failed" });
          console.log(error);
        });
      let regfields = this.state.regfields;
      regfields["username"] = "";
      regfields["email"] = "";
      regfields["password"] = "";
      regfields["confirmpassword"] = "";
      regfields["country"] = "";
      regfields["state"] = "";
      regfields["city"] = "";
      regfields["phone"] = "";
      this.setState({ regfields: regfields });

    }


  }

  validateForm = () => {
    let regfields = this.state.regfields;
    let errors = this.state.errors;
    errors = {};
    let formIsValid = true;
    if (!regfields.username) {
      formIsValid = false;
      errors["username"] = "Please enter username.";
    }
    if (!regfields.email) {
      formIsValid = false;
      errors["email"] = "Please enter email.";
    }
    let regexEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (regfields.email && !regexEmail.test(regfields.email)) {
      formIsValid = false;
      errors["email"] = "Please enter valid email.";
    }
    if (!regfields.password) {
      formIsValid = false;
      errors["password"] = "Please enter password.";
    }
    if (!regfields.confirmpassword) {
      formIsValid = false;
      errors["confirmpassword"] = "Please confirm password.";
    }
    if (!regfields.country) {
      formIsValid = false;
      errors["country"] = "Please select country.";
    }
    if (!regfields.st) {
      formIsValid = false;
      errors["st"] = "Please select state.";
    }
    if (!regfields.city) {
      formIsValid = false;
      errors["city"] = "Please select city.";
    }
    if (!regfields.phone) {
      formIsValid = false;
      errors["phone"] = "Please enter phone number.";
    }



    this.setState({
      errors: errors
    });
    return formIsValid;



  }

  render() {
    const { errorMessage } = this.state;
    return (

      <div className="container">
        <div className="row" style={{ padding: '5px' }}>
          <div className="col-lg-1 col-md-1 col-12">
          </div>
          <div className="col-lg-5 col-md-5 col-12">
            <div id="contact" className="contact">
              <div className="contact-head">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="form-head">
                      <div className="row" style={{ marginLeft: '30%', padding: '25px' }}>
                        <div style={{ textAlign: 'center', fontSize: '30px' }}>
                          <b>REGISTRATION</b>
                        </div>
                      </div>
                      {this.state.RegistrationStatus &&
                        <div className={this.state.RegistrationStatus == 'Success' ? "alert alert-success" : "alert alert-danger"}>
                          {this.state.RegistrationStatus == "Success" && <span>Registration Successfully completed. Please login to continue</span>}
                          {this.state.RegistrationStatus == "Failed" && <span>Registration Failed</span>}
                        </div>}


                      <div className="form-group">
                        <input name="username" className={this.state.errors.username ? "border-danger" : "form-control"} type="text" placeholder="Enter Name" value={this.state.regfields.username} onChange={this.handleChange} />
                        <small className="text-danger">{this.state.errors.username}</small>
                      </div>
                      <div className="form-group">
                        <input name="email" type="email" className={this.state.errors.email ? "border-danger" : "form-control"} placeholder="Email Address" value={this.state.regfields.email} onChange={this.handleChange} />
                        <small className="text-danger">{this.state.errors.email}</small>
                      </div>
                      <div className="form-group">
                        <input name="password" type="password" className={this.state.errors.password ? "border-danger" : "form-control"} placeholder="Password" value={this.state.regfields.password} onChange={this.handleChange} />
                        <small className="text-danger">{this.state.errors.password}</small>
                      </div>
                      <div className="form-group">
                        <input name="confirmpassword" className={this.state.errors.confirmpassword ? "border-danger" : "form-control"} type="password" placeholder="Cinfirm Password" value={this.state.regfields.confirmpassword} onChange={this.handleChange} />
                        <small className="text-danger">{this.state.errors.confirmpassword}</small>
                      </div>
                      <div className="form-group">
                        <select id="stateCountry" name="country" className={this.state.errors.country ? "border-danger" : "form-control"} value={this.state.regfields.country} onChange={this.handleChange}>
                          <option value="NA">Select Country</option>
                          <option value="IND">India</option>
                        </select>
                        <small className="text-danger">{this.state.errors.country}</small>
                      </div>
                      <div className="form-group">
                        <select id="stateDropDown" name="st" className={this.state.errors.st ? "border-danger" : "form-control"} value={this.state.regfields.st} onChange={this.handleChange}>
                          <option value="NA">Select State</option>
                          <option value="AP">Andhra Pradesh</option>
                          <option value="TS">Telangana</option>
                          <option value="KA">Bengaluru</option>
                        </select>
                        <small className="text-danger">{this.state.errors.st}</small>
                      </div>
                      <div className="form-group">
                        <select id="cityDropDown" name="city" className={this.state.errors.city ? "border-danger" : "form-control"} value={this.state.regfields.city} onChange={this.handleChange}>
                          <option value="NA">Select City</option>
                          <option value="Bangalore">Bangalore</option>
                          <option value="Hyderabad">Hyderabad</option>
                          <option value="Amaravathi">Amaravathi</option>
                        </select>
                        <small className="text-danger">{this.state.errors.city}</small>
                      </div>
                      {/* <div className="row">
                        <div className="col-md-9 col-lg-9 col-sm-9 col-xs-12">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text" style={{ height: '50px' }}>91</span>
                            </div>
                            <input name="phone" type="text" style={{ height: '50px' }} className={this.state.errors.phone ? "border-danger" : "form-control"} placeholder="Phone Number" value={this.state.regfields.phone} onChange={this.handleChange} />
                            <div className="input-group-append">
                              <button type="button" style={{ height: '50px' }} className="btn btn-primary">GET OTP</button>
                            </div>
                            <small className="text-danger">{this.state.errors.phone}</small>
                          </div>
                        </div>
                        <div className="col-md-3 col-lg-3 col-sm-3 col-xs-12">

                          <div className="form-group">
                            <input name="otp" type="text" className="form-control" placeholder="OTP" value={this.state.regfields.otp} onChange={this.handleChange} />
                            <small className="text-danger">{this.state.errors.otp}</small>
                          </div>
                        </div>
                      </div> */}
  
                      <div className="form-group">
                        <div className="button" style={{ marginLeft: '35%' }}>
                          <button type="submit" className="btn" onClick={this.submituserRegistrationForm}>Register</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-12">
            <div id="contact" className="contact">
              <div className="contact-head">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="form-head">
                      <div className="row" style={{ marginLeft: '35%', padding: '25px' }}>
                        <div style={{ textAlign: 'center', fontSize: '30px' }}>
                          <b>LOGIN</b>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="form-group">
                            <div className="button" style={{ marginLeft: '40%', backgroundColor: '#3b5998' }}>
                              <button style={{ backgroundColor: '#3b5998' }} type="submit" className="btn">FACEBOOK</button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="form-group">
                            <div className="button" style={{}}>
                              <button style={{ backgroundColor: '#c32f10' }} type="submit" className="btn primary">GOOGLE</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      {errorMessage && <div className="alert alert-danger">
                        <span className="text-danger">{errorMessage}</span>
                      </div>}
                      <div className="form-group">
                        <input name="email" value={this.state.field} type="email" placeholder="Email Address" onChange={this.UNChange} />
                      </div>
                      <div className="form-group">
                        <input name="password" value={this.state.field} type="password" placeholder="Password" onChange={this.UNChange} />
                      </div>

                      <div className="form-group">
                        <a className="pull-right" style={{ paddingBottom: '5px !important' }} onClick={this.ForgotPwdClick}>Forgot Password</a>
                      </div>


                      <div className="form-group">
                        <div className="button" style={{ marginLeft: '40%' }}>
                          <button type="submit" className="btn" onClick={this.SubmitClick}>LOGIN</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-1 col-md-1 col-12">
          </div>
        </div>
      </div>


    )

  }
}



export default Login;

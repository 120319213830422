import React from 'react';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';


var monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

function dateFormat2(d :any) {
    var t = new Date(d);
    return monthShortNames[t.getMonth()]+ ' ' + t.getDate();
  }

class EventQuickLinks extends React.Component<{
  items: any[],
  widgettitle: any,
  widgetcolor: any,
  widgetIcon: any,
  loading: boolean,
  nodataHeight:any,
  history: any
}>{
  constructor(props: any) {
    super(props);
  } 
  private GoToEntity = (obj: any) => {
    this.props.history.push("/entity/"+obj._source.businessname.replace(/[^a-zA-Z0-9]/g, '')+"/"  + obj._source.id + "");
  }
  render() {
    const { items, widgettitle, widgetcolor, widgetIcon, loading ,nodataHeight} = this.props;	
    let helpIcon = ">> ";
    let mainScopre = this;
    return (

      <div className="jh-events-block">{/* <div className={"widget " + widgetcolor}> */}
        <div className="jh-events-block-title">
          
          <span>{widgettitle}</span>
		   <Link to={`/events/${widgettitle.toLowerCase()}`} className="moreevents"><i className="fa fa-angle-double-right"/></Link>		         
        </div>
        <div className="jh-events-block-body">         
          {items.length > 0 && (
            <ul className="jh-events-block-list">
              {items.map(function (item) {

                return (
                  <li className="jh-events-block-list-item">
                      
                    <a 
                       className="jh-events-block-list-link"
                      onClick={() => mainScopre.GoToEntity(item)}
                    >                                          
                      <span>
                      {dateFormat2(item._source.eventendtime)} {' '}
                        {item._source.businessname.length>35?`${item._source.businessname.slice(0,30)}...`:item._source.businessname}
                      </span>
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
          
		  {items.length===0 && !loading && (<div style={{margin:'5px'}}>No Data Found</div>)}

        </div>
      </div>
    )
  }
}


export default EventQuickLinks;

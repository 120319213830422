import React from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import Utils from '../components/utils/homeutils';
import axios from 'axios';

class Contact extends React.Component <{
	componentName: any,
	successCallback:any,
	countries:any[],
	states:any[]	
},any>{
	constructor(props: any) {
		super(props);
		this.state={
			contactFields: {},
			contactErrors: {},
            cities:[]			
		}
		this.contactHandleChange = this.contactHandleChange.bind(this);
		this.submitcontactForm = this.submitcontactForm.bind(this);
	}
	async componentDidMount(){
		this.props.componentName("Contact");
		let objCities=await Utils.GetAllCities()
		this.setState({cities:objCities});
	}
	// contact handles
  contactHandleChange=(e:any) =>{          
        let fields = this.state.contactFields;        
        fields[e.target.name] = e.target.value;
        this.setState({
          fields
        });        
  }
  submitcontactForm=(e:any)=>{
	  let mainSate = this;
        e.preventDefault();
        if (this.contactValidateForm()) {
			axios.post("https://api.jaiho.com/ContactUsEmail", {
        Country: this.state.contactFields.country,
        State: this.state.contactFields.userstate,
        City: this.state.contactFields.city,
        Name: this.state.contactFields.username,
        Email: this.state.contactFields.email,
        Phone: this.state.contactFields.phone,
		AreaCode: this.state.contactFields.code,
        Message: this.state.contactFields.comments
      }).then(function (response) {
        if (response.data.statusCode === 200) {
          // mainSate.props.successCallback(true);	
        } else {
          //mainScope.setState({ RegistrationStatus: "Failed" });
        }
      })
        .catch(function (error) {         
          console.log(error);
        });
            let fields = this.state.contactFields;
			fields["country"] = "";
			fields["userstate"] = "";
			fields["city"] = "";
            fields["username"] = "";
            fields["email"] = "";
            fields["phone"] = "";
			fields["code"] = "";
            fields["comments"] = "";
            this.setState({contactFields:fields}); 
			 mainSate.props.successCallback(true,"Your request han been successfully submitted!");
					
        }
   }
  contactValidateForm=()=> {
        let fields = this.state.contactFields;
        let errors = this.state.contactErrors;
			errors["country"] = "";
			errors["userstate"] = "";
			errors["city"] = "";
            errors["username"] = "";
            errors["email"] = "";
            errors["phone"] = "";
			errors["code"] = "";
        let formIsValid = true;
		if (!fields.country) {
            formIsValid = false;
            errors["country"]="*Please select country.";
        }
		if (!fields.userstate) {
            formIsValid = false;
            errors["userstate"]="*Please select state.";
        }
		if (!fields.city) {
            formIsValid = false;
            errors["city"]="*Please select city.";
        }
        if (!fields.username) {
            formIsValid = false;
            errors["username"]="*Please enter your name.";
          }
         if (!fields.email) {
            formIsValid = false;
            errors["email"]="*Please enter your email.";
         }
         let regexEmail= /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
         if (!regexEmail.test(fields.email)) {
          formIsValid = false;
          errors["email"]="*Please enter valid email.";
         }		
         if (!fields.phone) {
            formIsValid = false;
            errors["phone"]="*Please enter your phone number.";
         }
		 let regexPhone=/^[0-9]*$/;
		 if (!regexPhone.test(fields.phone)) {
          formIsValid = false;
          errors["phone"]="*Please enter valid phone number.";
         }
		 if (!fields.comments) {
            formIsValid = false;
            errors["comments"]="*Please enter your message.";
         }
        this.setState({
            contactErrors: errors
          });
       return formIsValid;
  }  
  ClearForm = () => {
	   let fields = this.state.contactFields;
	   let errors = this.state.contactErrors;
			fields["country"] = "";
			fields["userstate"] = "";
			fields["city"] = "";
            fields["username"] = "";
            fields["email"] = "";
            fields["phone"] = "";
            fields["comments"] = "";  
			errors["country"] = "";
			errors["userstate"] = "";
			errors["city"] = "";
            errors["username"] = "";
            errors["email"] = "";
			errors["code"] = "";
            errors["phone"] = "";             			
    this.setState({contactFields:fields,contactErrors:errors });
  }
    render() {
		const {countries,states,successCallback}=this.props;
		let {contactFields,contactErrors,contactsnackbaropen,cities}=this.state;
      return(
	   <div className="d-flex"  id="jh-wrapper">
            
            <div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
                <div className="row">
				  <nav aria-label="breadcrumb">
					<ol className="breadcrumb">
					  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
					  <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
					</ol>
				  </nav>
				</div>
				
		<section id="contact" className="contact section">
            <div className="container">
              {/* <div className="row">
                    <div className="col-md-12">
                        <div className="section-title">
                            <h2><span>Contact</span> Us</h2>
                        </div>
                    </div>
                </div> */}
              <div className="contact-head">
                <div className="row">
                  <div className="col-12 jh-contact-description">
                    <h2 className="jh-title-1">Contact Us</h2>
                    <div className="jh-paragraph">

                      <p>
                        JaiHo web site is self explanatory and automated to promote your business instantly either as text based links
                        or digital gif images. You can advertise any business in the site and it will reach
                                    lakhs of users that lives in your city. Please check this <Link to="/advertise">advertisement</Link> link to see the
                                    advertisement options that are available in the site.
                                    If you still have questions,
                                    please email your queries to <a href="mail:support@jaiho.com">support@jaiho.com.</a>
                                    Our dedicated support team will revert you as soon as possible.
                                </p>
                      <p>
                        If you want us to contact you, please leave a message in the form here.
                        Our JaiHo representatives are dedicated to support you in fulfilling your
                        business promotions efficiently and profitably as possible.
                                </p>
                      <p>
                        Please make sure that you provided the correct details like email id, phone number.
                        We understand your privacy and the details that you shared here will be kept as confidential data
                        and will not be shared/used with any one for commercial purpose.
                        These details will be used to communicate you to provide only JaiHo services.
                                </p>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-12 col-12 jh-contact-details">
                    <div className="jh-contact-details-inner">
                      <div className="contact-info">
                        <div className="icon"><i className="fa fa-map"></i></div>
                        <h3>Corporate Office Hyderabad Location</h3>
                        <p>JaiHo, MSquare Suite 102, Hitceh City Madhapur, Hyderabad, TS 500081</p>
                      </div>
                      <div className="contact-info">
                        <div className="icon"><i className="fa fa-envelope"></i></div>
                        <h3>Email Address</h3>
                        <a href="mailto:support@jaiho.com">support@jaiho.com</a>                      
                      </div>
                      <div className="contact-info">
                        <div className="icon"><i className="fa fa-phone"></i></div>
                        <h3>Get in Touch</h3>
                        <p>+91 9270123456</p>
                      </div>

                    </div>
                  </div>

                  <div className="col-lg-7 col-md-12 col-12 jh-contact-form">

                    <div className="row">
                      <div className="col-md-6 col-lg-6 col-12">
                        <div className="form-group">
                          <label>Country <small className="text-danger">*</small></label>
                          <input type="text" className="form-control" name="country" value={this.state.contactFields.country} onChange={this.contactHandleChange}/>
                            <span className="text-danger">{this.state.contactErrors.country}</span>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6 col-12">
                        <div className="form-group">
                          <label>State <small className="text-danger">*</small></label>
                          <input type="text" className="form-control" name="userstate" value={this.state.contactFields.userstate} onChange={this.contactHandleChange}/>
                           <span className="text-danger">{this.state.contactErrors.userstate}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-lg-6 col-12">
                        <div className="form-group">
                          <label>City <small className="text-danger">*</small></label>
                          <input type="text" className="form-control" name="city" value={this.state.contactFields.city} onChange={this.contactHandleChange}/>
                            <span className="text-danger">{this.state.contactErrors.city}</span>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6 col-12">
                        <div className="form-group">
                          <label>Name <small className="text-danger">*</small></label>
                          <input type="text" className="form-control" name="username" value={this.state.contactFields.username} onChange={this.contactHandleChange} />
                          <span className="text-danger">{this.state.contactErrors.username}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-lg-6 col-12">
                        <div className="form-group">
                          <label>Email <small className="text-danger">*</small></label>
                          <input type="email" className="form-control" name="email" value={this.state.contactFields.email} onChange={this.contactHandleChange} />
                          <span className="text-danger">{this.state.contactErrors.email}</span>
                        </div>
                      </div>
					  <div className="col-md-2 col-lg-2 col-12">
                        <div className="form-group">
                          <label>Area Code</label>
                          <input type="text" className="form-control" name="code" value={this.state.contactFields.code} onChange={this.contactHandleChange} />
                          <span className="text-danger">{this.state.contactErrors.code}</span>
                        </div>
                      </div>					  
                      <div className="col-md-4 col-lg-4 col-12">
                        <div className="form-group">
                          <label>Phone <small className="text-danger">*</small></label>
                          <input type="text" className="form-control" name="phone" value={this.state.contactFields.phone} onChange={this.contactHandleChange} />
                          <span className="text-danger">{this.state.contactErrors.phone}</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Message <small className="text-danger">*</small></label>
                      <textarea className="form-control" name="comments" rows={2} cols={2} value={this.state.contactFields.comments} onChange={this.contactHandleChange} />
                      <span className="text-danger">{this.state.contactErrors.comments}</span>
                    </div>

                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-lg-6 col-12">
                        <button className="btn btn-primary btn-block" onClick={this.submitcontactForm}>Submit</button>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6  col-12">
                        <button className="btn btn-secondary btn-block" onClick={this.ClearForm}>Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="contact-head contact-map">
                <div className="row">

                  <div className="col-12">
                    <iframe id="gmap_canvas" style={{ width: "100%", height: "350px" }}
                      src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD93TMrpJljTzWorm7ylkEk4OX6QMLL1do&q=MSquare,Hitceh+City +Madhapur,Hyderabad,Telangana"
                      scrolling="no"></iframe>
                  </div>
                </div>
              </div>

            </div>
           </section>

        </div>
      </div>
    );
  }
}



export default Contact;

import { AnyCnameRecord } from 'dns';
import React from 'react';
import Utils from '../utils/homeutils';
class Adds1 extends React.Component<{
    stateid: any,
    countryid:AnyCnameRecord
}, {
    newsItems: any[],
    stateNewsItems: any[],
    breakingNews:any[]
}>{
    constructor(props: any) {
        super(props);
        this.state = {
            newsItems:[],
            stateNewsItems:[],
            breakingNews:[]
        }
    }
    async componentDidUpdate(prevProps: any, _prevState: any) {
        if (prevProps.stateid !== this.props.stateid) {
            let elkNews = await Utils.GetNewsItems();
            let stateID = this.props.stateid;
            let countryID=this.props.countryid;
            let centralNews = elkNews.filter(function (elkItem: any) { return elkItem._source.countryid===Number(countryID) && elkItem._source.stateid === null });
            let stateNews = elkNews.filter(function (elkItem: any) { return elkItem._source.stateid === Number(stateID) });
            this.setState({ newsItems: centralNews.slice(0, 4) });
            this.setState({ stateNewsItems: stateNews.slice(0, 4) });
        }
    }
    async componentDidMount() {
        let elkNews = await Utils.GetNewsItems();
        let stateID = this.props.stateid;
        let countryID=this.props.countryid;
        let centralNews = elkNews.filter(function (elkItem: any) { return elkItem._source.countryid===Number(countryID) && elkItem._source.stateid === null });
        let stateNews = elkNews.filter(function (elkItem: any) { return elkItem._source.stateid === Number(stateID) });
        let breakingNews=elkNews.filter(function (elkItem: any) { return elkItem._source.countryid===Number(countryID) && elkItem._source.title === "BreakingNews" });
        this.setState({ newsItems: centralNews.slice(0, 4) });
        this.setState({ stateNewsItems: stateNews.slice(0, 4) });
        this.setState({breakingNews:breakingNews});
    }
    render() {
        const { newsItems, stateNewsItems,breakingNews } = this.state;
        return (
        <div className="row jh-news-thumbnail-block">
        	<div className="col-md-5 col-lg-5 col-12">            
            	<div className="row">				
					{newsItems.map(function (item,index) {
               const newsClass="nationalNews"+index;
              return (                  
                <div className="col-3 newlogowrapper" key={item+index}>
					<div className="newslogoinner">				 
						<a href={item._source.url} target="_blank">
						<img src={`https://admin.jaiho.com/assets/newsimg/${item._source.imagename}`} title={item._source.title} className="newslogo" />
						</a>
					</div>
                </div>
              );
            })}
			</div>                  
    		</div>
			<div className="col-md-2 col-lg-2 col-12 newlogowrapper">				
					<div className="brknewslogoinner">
						{breakingNews.length>0 && <a href="#" target="_blank">
							<img src={`https://admin.jaiho.com/assets/newsimg/${breakingNews[0]._source.imagename}`} title='Breaking News' className="BreakingNewsClass" />
						</a>}					
                </div>
			</div>
			<div className="col-md-5 col-lg-5 col-12">
				<div className="row">
{stateNewsItems.map(function (item,index) {  
                const newsClass1="stateNews"+index;            
              return (
                <div className="col-3 newlogowrapper" key={item+index}>
					<div className="newslogoinner">
						<a href={item._source.url} target="_blank">
						<img src={`https://admin.jaiho.com/assets/newsimg/${item._source.imagename}`} title={item._source.title} className="newslogo" />
						</a>
					</div>
                </div>
              );
            })}				
                </div> 
            </div>
        </div>
        )
    }
}
export default Adds1;

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class Franchise extends React.Component<any,any>{
  constructor(props: any) {
    super(props);
    this.state={
        open:true
    }    
  }
  // Functions
  handleClose = () => {
    this.setState({ open: false });
  }
  
  render() {
    
    return (
<Dialog classes={{ paper: 'city-dailog-wrapper' }} open={this.state.open} maxWidth='lg' fullWidth={true} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title">
            JaiHo Franchise
          </DialogTitle>

          <DialogContent>


          </DialogContent>
          <DialogActions>




          </DialogActions>
        </Dialog>
      
    )
  }
}


export default Franchise;

import React from 'react';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

class QuickLinks extends React.Component<{
  items: any[],
  widgettitle: any,
  widgetcolor: any,
  widgetIcon: any,
  loading: boolean,
  nodataHeight: any,
  history: any,
  categoryID: any,
  categoryName: any,
  subCategoryID: any,
  subCategoryName: any

}>{
  constructor(props: any) {
    super(props);
    //this.FormateLinkText = this.FormateLinkText.bind(this);

  }
  // Functions

  private GoToEntity = (obj: any) => {
    this.props.history.push("/entity/"+obj._source.businessname.replace(/[^a-zA-Z0-9]/g, '')+"/" + obj._source.id + "");
  }  
  private GoToEmploymentEntity = (obj: any) => {
    this.props.history.push("/employmententity/"+ obj._source.id + "");
  }
  private FormateLinkText = (obj: any): any => {
    return "test";
  }
  render() {
    const { items, widgettitle, widgetcolor, widgetIcon, loading, nodataHeight, categoryID, categoryName, subCategoryID, subCategoryName } = this.props;
    let helpIcon = ">> ";
    let mainScopre = this;
    return (

      <div className={"widget " + widgetcolor}>
        <div className="widget-header">

          <span className="widget-caption">{widgettitle}</span>
          <span className="widget-caption-icon"><i className={widgetIcon}></i></span>
        </div>
        <div className="widget-body">
          {loading && (<div>
            <Skeleton count={10} height={25} />
          </div>)}
          {!loading && items.length > 0 && categoryName !== "Employment" && (
            <ul className="widget-list">
              {items.map(function (item) {
                let t = item._source.businessname + " | " + item._source.subcity;
                return (
                  <li className="widget-list-item" key={item._source.id}>
                    <a
                      className="widget-list-item-link"
                      onClick={() => mainScopre.GoToEntity(item)}
                    >
                      <i className="fa fa-angle-double-right"></i>
                      {item._source.businessname.length >= 35 &&
                        <span className="widget-list-text">
                          {`${item._source.businessname.toLowerCase().slice(0, 32)}...`}
                        </span>
                      }
                      {item._source.businessname.length < 35 && t.length >= 35 &&
                        <span className="widget-list-text">
                          {t.slice(0, 30).trim().charAt(t.slice(0, 30).trim().length - 1) === '|' ? `${t.toLowerCase().slice(0, 30).trim().slice(0, -1)}` : `${t.toLowerCase().slice(0, 30)}...`}
                        </span>
                      }
                      {item._source.businessname.length < 35 && t.length < 35 &&
                        <span className="widget-list-text">
                          {t.toLowerCase()}
                        </span>
                      }
                    </a>
                  </li>


                );
              })}

                          
                        
              <li>{<Link  className="pull-left jh-addyourbusiness-link" to="/addbusiness">Add your Business</Link>}{subCategoryName !== "NA" && categoryID !== "NA" && <Link className="widget-view-all text-right" to={`/yellowpages/${categoryName}/${subCategoryName}/${subCategoryID}`}>View All</Link>}
                {subCategoryName === "NA" && categoryID !== "NA" && <Link className="widget-view-all text-right" to={`/yellowpages/${categoryName}/${categoryID}`}>View All</Link>}
                {categoryName === "Events" && <Link className="widget-view-all text-right" to={"/events/all"}>View All</Link>}
              </li>
            </ul>
          )}
          {!loading && items.length > 0 && categoryName === "Employment" &&
            (
              <ul className="widget-list">
                {items.map(function (item) {
                  return (<li className="widget-list-item" key={item._source.id}>
                  <a 
                    className="widget-list-item-link"
                    onClick={() => mainScopre.GoToEmploymentEntity(item)}
                  >
                    <i className="fa fa-angle-double-right"></i> {item._source.companyname} - {item._source.title}</a></li>)
                })}  
                <li>
                <a href="https://admin.jaiho.com" target="_blank" className="pull-left jh-addyourbusiness-link">Add your requirement</a>
                <Link className="widget-view-all text-right" to={"/employment"}>View All</Link>
                </li>              
              </ul>
            )}
          
                
            
		  {items.length === 0 && !loading && (<div style={{ margin: '5px' }}>No Data Found</div>)}
          {items.length === 0 && !loading && (<ul className="widget-list">
            <li>{<a href="https://admin.jaiho.com" target="_blank" className="pull-left jh-addyourbusiness-link">Add your business</a>}{subCategoryName !== "NA" && categoryID !== "NA" && 
            <Link className="widget-view-all text-right" to={`/yellowpages/${categoryName}/${subCategoryName}/${subCategoryID}`}>View All</Link>}
              {subCategoryName === "NA" && categoryID !== "NA" && <Link className="widget-view-all text-right" to={`/yellowpages/${categoryName}/${categoryID}`}>View All</Link>}
              {categoryName === "Events" && <Link className="widget-view-all text-right" to={"/events/all"}>View All</Link>}
              {categoryName === "Employment" && <Link className="widget-view-all text-right" to={"/employment"}>View All</Link>}
            </li>
          </ul>)}
        </div>
      </div>
    )
  }
}


export default QuickLinks;

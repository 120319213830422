import React from 'react';
import * as _ from 'lodash';
var moment:any = require('moment'); 
class Adds2 extends React.Component<{
	city:any,
	allBusiness:any[],
	allRanks:any[]
},{
	adv5Items:any[],
	noDataHeight:any
}>{
    constructor(props: any) {
        super(props);
		this.state={
			adv5Items:[],
			noDataHeight:"920px"
		}
    }
	async componentDidUpdate(prevProps:any, _prevState:any) {
        if(prevProps.city!=this.props.city || prevProps.allBusiness!=this.props.allBusiness){          
                
			try {
				this.setState({adv5Items:[]});
				let allBusinessEntities=this.props.allBusiness;				
				let allAdtisements =this.props.allRanks;
				let adv5Rankings:any=[];
				let tempItems:any=[];
				adv5Rankings=allAdtisements.filter(function (elkItem: any) {
						return elkItem._source.advtypeid === 5 && moment()>=moment(elkItem._source.startdate)  && moment()<=moment(elkItem._source.tilldate)});
			if(adv5Rankings.length>0)
				{
					let sortByRank = _.sortBy(adv5Rankings, (e) => {
						return e._source.positionno
					});					
				 	sortByRank.map(function (rank) {
						if(rank._source.businessentityid!==null){ 
						console.log("Start date");
						console.log(moment());
						console.log(rank._source.startdate);
					console.log(moment(rank._source.startdate));					
						let tempItem = allBusinessEntities.filter(function (elkItem: any) { return elkItem._source.id === rank._source.businessentityid});							
						 tempItems=[...tempItems,...tempItem];
						}
					});
					if(tempItems.length>0){
						let addHight=920-(87*tempItems.length+5*tempItems.length);
						this.setState({noDataHeight:addHight.toString()+'px'});
					}else{
						this.setState({noDataHeight:'920px'});
					}						
					this.setState({adv5Items:tempItems});
				}else{
					this.setState({noDataHeight:'920px'});
				}
            } catch (e) {
            }
				
                
        }
    }
    
	
    async componentDidMount() {        
             
        if (this.props.city !="" && this.props.city!=null) {
            try {
				this.setState({adv5Items:[]});
				let allBusinessEntities=this.props.allBusiness;				
				let allAdtisements =this.props.allRanks;
				let adv5Rankings:any=[];
				let tempItems:any=[];
				adv5Rankings=allAdtisements.filter(function (elkItem: any) {
						return elkItem._source.advtypeid === 5 && moment()>=moment(elkItem._source.startdate)  && moment()<=moment(elkItem._source.tilldate)});
			if(adv5Rankings.length>0)
				{
					let sortByRank = _.sortBy(adv5Rankings, (e) => {
						return e._source.positionno
					});					
				 	sortByRank.map(function (rank) {
						if(rank._source.businessentityid!==null){							
						  let tempItem = allBusinessEntities.filter(function (elkItem: any) { return elkItem._source.id === rank._source.businessentityid});							
						  tempItems=[...tempItems,...tempItem];
						}
					});	
					if(tempItems.length>0){
						let addHight=920-(87*tempItems.length+5*tempItems.length);
						this.setState({noDataHeight:addHight.toString()+'px'});
					}else{
						this.setState({noDataHeight:'920px'});
					}
					this.setState({adv5Items:tempItems});
				}
            } catch (e) {
            }
		}
        
        
    }
    render() {
        return (
            <div className="row right-thumbnail-banners">
			{this.state.adv5Items.length>0 && this.state.adv5Items.map(function (item) {
				return(
						<div className="right-thumbnail-banner" style={{marginBottom:'5px'}}>
							<img src={`https://admin.jaiho.com/assets/businessimg/${item._source.gifname}`} alt="#" style={{width:'100%',height:'87px'}} />
						</div>
					)
				})
			}
			<div className="right-thumbnail-banner">
                    <img src={require('../../img/advertise5.gif')} alt="#" style={{width:'100%',height:`${this.state.noDataHeight}`}} />
                </div>
                
            </div>
        )
    }
}
export default Adds2;

import React from 'react';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';


var monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

function dateFormat2(d: any) {
  var t = new Date(d);
  return monthShortNames[t.getMonth()] + ' ' + t.getDate();
}

class UsefulQuickLinks extends React.Component<{
  items: any[],
  widgettitle: any,
  widgetcolor: any,
  widgetIcon: any,
  loading: boolean,
  catid: any,
  nodataHeight: any,
  history: any
}, any>{
  constructor(props: any) {
    super(props);
    this.state = {
      componentloading: true,
      linksData: []
    }
  }
  private GoToEntity = (obj: any) => {
    this.props.history.push("/entity/" + obj._source.businessname.replace(/[^a-zA-Z0-9]/g, '') + "/" + obj._source.id + "");
  }
  async componentDidMount() {
    let componentData = this.props.items;
    let componentCat = this.props.catid;
    let componentFilteredData = componentData.filter((e: any) => e._source.isactive === true && e._source.categoryid === Number(componentCat));
    this.setState({ linksData: componentFilteredData, componentloading: false });
  }
  //
  render() {
    const { items, widgettitle, widgetcolor, widgetIcon, loading, nodataHeight, catid } = this.props;
    let { linksData, componentloading }=this.state;
    let helpIcon = ">> ";
    let mainScopre = this;
    return (

      <div className="jh-usefullinks-block">{/* <div className={"widget " + widgetcolor}> */}
        <div className="jh-usefullinks-block-title">

          <span>{widgettitle}</span>
          <Link to={`/usefullinks/${widgettitle.toLowerCase().replace(/[^a-zA-Z0-9]/g, '')}/${catid}`} className="moreevents"><i className="fa fa-angle-double-right" /></Link>
        </div>
        {componentloading && (<div>
          <Skeleton count={10} height={25} />
        </div>)}
        {/* <div className="jh-events-block-body">          */}
        {!componentloading && linksData.length > 0 && (
          <ul className="jh-usefullinks-block-list">
            {linksData.slice(0, 5).map(function (item:any) {

              return (
                <li className="jh-events-block-list-item">

                  <a
                    className="jh-events-block-list-link"
                    href={item._source.url} target="_blank" rel="noopener noreferrer"
                  >
                    <span>
                      <i className="fa fa-angle-double-right"></i> {item._source.title.length > 35 ? `${item._source.title.slice(0, 30)}...` : item._source.title}
                    </span>
                  </a>
                </li>
              );
            })}
          </ul>
        )}



        {/* </div> */}
      </div>
    )
  }
}


export default UsefulQuickLinks;

import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {
  Button,
  Typography,
  DialogContent,
  TextField,
  FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "51%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  title: {
    marginLeft: theme.spacing(2),
    marginTop: 8,
    marginBottom: 18,
    flex: 1,
  },
  subTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "red",
  },
  textMessage: {
    marginLeft: theme.spacing(2),
    flex: 1,
    marginTop: 14,
  },
  textMessageTwo: {
    marginLeft: theme.spacing(1),
    flex: 1,
    marginTop: 24,
    color: 'black'
  },
  formButtonControl: {
    margin: theme.spacing(5),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    marginLeft: -10,
  },
}));

function DisclaimerPage() {

  const history = useHistory();
  const { rowid } = useParams();

  

  const classes = useStyles();
  return (
    <div className="card">
      <div className="card-body">
        <Typography variant="h6" className={classes.title}>
          JaiHo Discussion Boards Terms and Conditions and Disclaimer
        </Typography>
        <Typography variant="subtitle2" className={classes.subTitle}>
          WARNING
        </Typography>
        <Typography variant="subtitle2" className={classes.subTitle}>
          Please do not post personal information about individuals. If you
          violate this condition, it is possible that the affected person may
          seek legal action
        </Typography>
        <Typography variant="body2" className={classes.textMessage}>
          1. Your IP address will be tracked if you wish to post data
        </Typography>
        <Typography variant="body2" className={classes.textMessage}>
          2. JaiHo reserves the right to delete any message.
        </Typography>
        <Typography variant="body2" className={classes.textMessage}>
          3. This message board is intended to help the Michigan Indian
          community exchange useful information quickly and efficiently.
        </Typography>
        <Typography variant="body2" className={classes.textMessage}>
          4. JaiHo is requesting all visitors to use good judgment and express
          their thoughts without offending the feelings of our community members
          and others.
        </Typography>
        <Typography variant="body2" className={classes.textMessage}>
          5. JaiHo is not responsible for any comments or statements made here.
        </Typography>
        <Typography variant="body2" className={classes.textMessage}>
          6. Use descriptive Topic Subject. This will help others find what they
          want to read. Topics with bad Topic subjects maybe deleted!
        </Typography>
        <Typography variant="body2" className={classes.textMessage}>
          7. No trolling. Don't make posts that are inflammatory just to annoy
          people.
        </Typography>
        <Typography variant="body2" className={classes.textMessage}>
          8. Personal Attacks. Criticize ideas, not people. Flaming will not be
          tolerated.
        </Typography>
        <Typography variant="body2" className={classes.textMessage}>
          9. This includes any material which is vulgar, defamatory, inaccurate,
          harassing, hateful, threatening, invading of others privacy, sexually
          oriented, or violates any laws.
        </Typography>
        <Typography variant="body2" className={classes.textMessage}>
          10. Do not post links to objectionable content. Only link to sites
          that are for family viewing only.
        </Typography>
        <Typography variant="body2" className={classes.textMessage}>
          11. Respect the privacy of others. Do not post other's phone numbers,
          addresses, etc., without their permission.
        </Typography>
        <Typography variant="body2" className={classes.textMessage}>
          12. Do not use objectionable language.
        </Typography>
        <Typography variant="body2" className={classes.textMessage}>
          13. NO Blatant Advertising. This forum is NOT free advertising space.
        </Typography>
        <Typography variant="body2" className={classes.textMessage}>
          14. NO Spamming. No commercial posts or flooding the forum with
          useless content.
        </Typography>
        <Typography variant="body2" className={classes.textMessage}>
          15. Message content. You remain solely responsible for the content of
          your messages.
        </Typography>
        <Typography variant="body2" className={classes.textMessageTwo}>
          We thank you in advance for your understanding and continued support.
        </Typography>
        
        <Button
              onClick={() => history.push(`/postReply/${rowid}`)}
              variant="outlined"
              color="inherit"
              style={{
                display: "flex",
                color: "#01579b",
                background: "#fff",
                borderColor: "#01579b",
                marginTop: 14,
              }}
            >
              I Accept Terms & Conditions
            </Button>
      </div>
    </div>
  );
}

export default DisclaimerPage;

import React, { useEffect } from "react";
import { useHistory, useParams, } from "react-router";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { red } from "@material-ui/core/colors";
import PostAddIcon from "@material-ui/icons/PostAdd";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Button,
  Typography,  
  TextField,
  FormControl,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
//import { Table, ProgressBar } from 'react-bootstrap';
import axios from "./../../utilities/AxiosApi";
import AxiosApi from "./../../utilities/AxiosApi";
import Utils from '../utils/homeutils';

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function dateFormat2(d :any){
		let monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
    let t = new Date(d);
    return monthShortNames[t.getMonth()]+ ' ' + t.getDate();
  }
const useStyles = (theme:any) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },
  padding: {
    padding: theme.spacing(0),
  },
  indicator: {
     backgroundColor: "#fff"
    //backgroundColor: '#fff#823587',
  },
  indicatorColor: {
    backgroundColor: "#82338d",
  },
  "&.Mui-selected": {
    outline: "none",
  },
  rootCard: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  subheader: {
    //backgroundColor: '#FF9933',
    backgroundColor: "#823587",
    minHeight: 24,
    height: 42,
    color: "#fff",
  }
});

class Polling extends React.Component<{
  componentName: any,
  successCallback:any,
classes:any,
history:any,
match:any
},any
>{
	constructor(props: any) {
		super(props);
		this.state = {
			franchiseopen:false,
			openPollRequest:false,
			value:'Local',
			pollingData:[],
			globalPolls:[],
			pollFields: {},
			pollErrors: {}
			
		}
		this.pollHandleChange = this.pollHandleChange.bind(this);
		this.ClearForm=this.ClearForm.bind(this);
	} 
	pollHandleChange=(e:any) =>{          
        let fields = this.state.pollFields;        
        fields[e.target.name] = e.target.value;
        this.setState({
          fields
        });        
  }
	async componentDidUpdate(prevProps:any, _prevState:any) {
		if(_prevState.value!=this.state.value){
		}
	}
	async componentDidMount() {
		this.props.componentName("Polling");
		let cityID=this.props.match.params.id;
		/*let elkAllPolls=await Utils.GetAllPolls();		
		let localPolls=elkAllPolls.filter(function (elkPollItem: any) { 					
						return elkPollItem._source.cityid== cityID});
		this.setState({pollingData:localPolls,globalPolls:elkAllPolls});*/
		AxiosApi.get("Polls").then((data) => {        
        let pollData = data.data;
		let localPolls=pollData.filter(function (elkPollItem: any) { 					
						return elkPollItem.cityid== cityID});
		this.setState({pollingData:localPolls,globalPolls:pollData});
      }).catch(() => {});
	}
	handleChange=(event:any, newValue:any)=>{
		let that=this;
		this.setState({value:newValue});
		if(newValue==="Local"){
			let globalPollItems=this.state.globalPolls.filter(function (elkPollItem: any) { 					
						return elkPollItem.cityid== that.props.match.params.id});
						this.setState({pollingData:globalPollItems});
		}else{
			let globalPollItems=this.state.globalPolls.filter(function (elkPollItem: any) { 					
						return elkPollItem.cityid!= that.props.match.params.id});
						this.setState({pollingData:globalPollItems});
		}
		
	}
 FranchiseClose=()=>{		
		this.setState({openPollRequest:false});
	}
 CreatePollClick=()=>{
	 this.setState({openPollRequest:true});		
	}
	submitcontactForm=(e:any)=>{
	  let mainSate = this;
	  let countryName="";
	  let stateName="";
	  let cityName="";
	  if(this.state.value=="Global"){
		 countryName=this.props.match.params;
		 stateName=this.state.pollFields.state;
		 cityName= this.state.pollFields.city;
	  }else{
		 countryName="NA";
		 stateName="NA";
		 cityName= this.props.match.params.city;
	  }
        e.preventDefault();
        if (this.contactValidateForm()) {
			axios.post("https://api.jaiho.com/PollRequestEmail", { 
		Country:this.state.pollFields.country,
		State:this.state.pollFields.state,
        City: this.props.match.params.city,
        Name: this.state.pollFields.username,
        Email: this.state.pollFields.email,
        Phone: this.state.pollFields.phone,
		AreaCode: this.state.pollFields.code,
        PollTitle: this.state.pollFields.poll,
		Option1:this.state.pollFields.polloption1,
		Option2:this.state.pollFields.polloption2,
		Option3:this.state.pollFields.polloption3
      }).then(function (response) {
        if (response.data.statusCode === 200) {
          // mainSate.props.successCallback(true);	
        } else {
          //mainScope.setState({ RegistrationStatus: "Failed" });
        }
      })
        .catch(function (error) {         
          console.log(error);
        });
            let fields = this.state.pollFields;	
            fields["username"] = "";
			fields["state"] = "";
			fields["city"] = "";
            fields["email"] = "";
            fields["phone"] = "";
			fields["code"] = "";
            fields["poll"] = "";
			fields["polloption1"] = "";
			fields["polloption2"] = "";
			fields["polloption3"] = "";
            this.setState({pollFields:fields,openPollRequest:false}); 
			 mainSate.props.successCallback(true,"Your request han been successfully submitted!");
					
        }
   }
  contactValidateForm=()=> {
        let fields = this.state.pollFields;
        let errors = this.state.pollErrors;
			errors["state"] = "";		
			errors["city"] = "";
            errors["username"] = "";
			errors["state"] = "";			
            errors["email"] = "";
            errors["phone"] = "";
			errors["poll"] = "";
			errors["polloption1"] = "";
			errors["polloption2"] = "";
			errors["polloption3"] = "";			
        let formIsValid = true;
		if (this.state.value=="Global" && !fields.state) {
            formIsValid = false;
            errors["state"]="*Please enter state name.";
          }
		  if (this.state.value=="Global" && !fields.city) {
            formIsValid = false;
            errors["city"]="*Please enter city name.";
          }
        if (!fields.username) {
            formIsValid = false;
            errors["username"]="*Please enter your name.";
          }
         if (!fields.email) {
            formIsValid = false;
            errors["email"]="*Please enter your email.";
         }
         let regexEmail= /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
         if (!regexEmail.test(fields.email)) {
          formIsValid = false;
          errors["email"]="*Please enter valid email.";
         }		
         if (!fields.phone) {
            formIsValid = false;
            errors["phone"]="*Please enter your phone number.";
         }
		 let regexPhone=/^[0-9]*$/;
		 if (!regexPhone.test(fields.phone)) {
          formIsValid = false;
          errors["phone"]="*Please enter valid phone number.";
         }
		 if (!fields.poll) {
            formIsValid = false;
            errors["poll"]="*Please enter poll title.";
         }
		  if (!fields.poll) {
            formIsValid = false;
            errors["polloption1"]="*Please enter option 1.";
         }
		  if (!fields.poll) {
            formIsValid = false;
            errors["polloption2"]="*Please enter option 2.";
         }
		  if (!fields.poll) {
            formIsValid = false;
            errors["polloption3"]="*Please enter option 3.";
         }
        this.setState({
            pollErrors: errors
          });
       return formIsValid;
  }  
  ClearForm = () => {
	   let fields = this.state.pollFields;
	   let errors = this.state.pollErrors;			
			fields["state"]="";
			fields["city"]="";
            fields["username"] = "";			
            fields["email"] = "";
            fields["phone"] = "";
            fields["code"] = "";  
			fields["poll"] = "";
            fields["polloption1"] = "";
            fields["polloption2"] = "";
            fields["polloption3"] = "";
			errors["username"] = "";
            errors["email"] = "";
            errors["phone"] = "";			
			errors["poll"] = "";
			errors["polloption1"] = "";
			errors["polloption2"] = "";
            errors["polloption3"] = "";
                         			
    this.setState({pollFields:fields,pollErrors:errors,openPollRequest:false });
  }
	render() {
		 const {classes,history }=this.props;
		 let {value,openPollRequest,pollingData}=this.state;
	return (
	<div className="d-flex"  id="jh-wrapper">            
            <div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
				<div className="row">
				  <nav aria-label="breadcrumb">
					<ol className="breadcrumb">
					  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
					  <li className="breadcrumb-item active" aria-current="page">Poll Results</li>
					</ol>
				  </nav>
				</div>
				
				 <div className="jh-content-body">
				 <div className="row">
					 <div className="col-lg-12 jh-events-title">
						<h2 className="jh-title-1">Poll Listing</h2>
					</div>					
				</div>
				<div className="col-12">
					<div className="jh-events">
						<div className="jh-events-body">
							<div className={classes.root}>
								<AppBar position="static" color="default">
									<Tabs
										  value={value}
										  onChange={this.handleChange}
										  variant="scrollable"
										  scrollButtons="auto"
										  className={classes.indicator}
										  TabIndicatorProps={{ className: classes.indicatorColor }}
										  aria-label="scrollable force tabs example"
									>
										  <Tab
											label='Local' value='Local'
											{...a11yProps(0)}
											style={{ minWidth: 75 }}
										  />
										<Tab
											label='Global' value='Global'
											{...a11yProps(0)}
											style={{ minWidth: 75 }}
										  />
										  									  
									</Tabs>
								</AppBar>
				 </div></div></div></div>
				 {openPollRequest && <div><Dialog open={openPollRequest} maxWidth='md' fullWidth={true} onClose={this.FranchiseClose} aria-labelledby="form-dialog-title">
          <DialogTitle classes={{ root: 'jh-franchise-dailog-title' }} id="customized-dialog-title">
            <span>JaiHo Poll Request</span><span className="pull-right" onClick={this.FranchiseClose}><i className="fa fa-times jh-mdl-appdownload-close"/></span>
          </DialogTitle>

          <DialogContent>
            <div className="container">
			{this.state.value=="Global" && <div className="row">
				<div className="col-md-4 col-lg-4 col-sm-4 col-12">
				 <div className="form-group">
					<label>Country <small className="text-danger">*</small></label>
                    <input type="text" className="form-control" name="country" value={this.props.match.params.country} disabled/> 
				 </div>					
				</div>
				<div className="col-md-4 col-lg-4 col-sm-4 col-12">
				 <div className="form-group">
					<label>State <small className="text-danger">*</small></label>
                    <input type="text" className="form-control" name="state" onChange={this.pollHandleChange} />   
					<span className="text-danger">{this.state.pollErrors.state}</span>
				 </div>
				</div>
				<div className="col-md-4 col-lg-4 col-sm-4 col-12">
				  <div className="form-group">
					<label>City <small className="text-danger">*</small></label>
                    <input type="text" className="form-control" name="city" onChange={this.pollHandleChange} />   
					<span className="text-danger">{this.state.pollErrors.city}</span>
				  </div>
				</div>
			</div>}
              <div className="row">                
                <div className="col-md-3 col-lg-3 col-12">
                  <div className="form-group">
                    <label>Name <small className="text-danger">*</small></label>
                    <input type="text" className="form-control" name="username" onChange={this.pollHandleChange} />   
					<span className="text-danger">{this.state.pollErrors.username}</span>					
				  </div>
                </div> 
				<div className="col-md-3 col-lg-3 col-12">
                  <div className="form-group">
                    <label>Email <small className="text-danger">*</small></label>
                    <input type="email" className="form-control" name="email" onChange={this.pollHandleChange}/>
					<span className="text-danger">{this.state.pollErrors.email}</span>					
                  </div>
                </div>
				<div className="col-md-2 col-lg-2 col-12">
                  <div className="form-group">
                    <label>Area Code</label>
                    <input type="text" className="form-control" name="code" onChange={this.pollHandleChange}/>	
					
                  </div>
                </div>
				<div className="col-md-4 col-lg-4 col-12">
                  <div className="form-group">
                    <label>Phone <small className="text-danger">*</small></label>
                    <input type="text" className="form-control" name="phone" onChange={this.pollHandleChange}/>	
					<span className="text-danger">{this.state.pollErrors.phone}</span>					
                  </div>
                </div>
								
              </div>
			  <div className="row">
				<div className="col-12">
                  <div className="form-group">
                    <label>Poll Description <small className="text-danger">*</small></label>
                    <input type="text" className="form-control" name="poll" onChange={this.pollHandleChange}/>	
					<span className="text-danger">{this.state.pollErrors.poll}</span>					
                  </div>
                </div>
			  </div>
			  <div className="row">
						  
			  <div className="col-md-4 col-lg-4 col-12">
                  <div className="form-group">
                    <label>Option 1 <small className="text-danger">*</small></label>
                    <input type="text" className="form-control" name="polloption1" onChange={this.pollHandleChange}/>	
					<span className="text-danger">{this.state.pollErrors.polloption1}</span>					
                  </div>
                </div>
			  
			  <div className="col-md-4 col-lg-4 col-12">
                  <div className="form-group">
                    <label>Option 2 <small className="text-danger">*</small></label>
                    <input type="text" className="form-control" name="polloption2" onChange={this.pollHandleChange}/>
					<span className="text-danger">{this.state.pollErrors.polloption2}</span>					
                  </div>
                </div>
			  
			  <div className="col-md-4 col-lg-4 col-12">
                  <div className="form-group">
                    <label>Option 3 <small className="text-danger">*</small></label>
                    <input type="text" className="form-control" name="polloption3" onChange={this.pollHandleChange}/>	
					<span className="text-danger">{this.state.pollErrors.polloption3}</span>					
                  </div>
                </div>
			</div>
			  
			  <div className="row">				
				<div className="col-md-6 col-lg-6 col-12">
					<button type="button" className="btn btn-primary btn-block" onClick={this.submitcontactForm}>Submit</button>
				</div>
				<div className="col-md-6 col-lg-6 col-12">
					<button type="button" className="btn btn-secondary btn-block" onClick={this.ClearForm}>Cancel</button>
				</div>
			  </div>
            </div>
          </DialogContent>
          <DialogActions>
          </DialogActions>
				 </Dialog></div>}
				 <hr/>
				 <DialogContent>
		<div className="pull-right">
										<Button
						  variant="outlined"
						  startIcon={<PostAddIcon />}
						  style={{
							display: "flex",
							color: "#fff",
							background: "#823587",
							marginLeft: "auto",
						  }}
						  onClick={this.CreatePollClick}
						>
						  Create Poll
						</Button>
					</div>
				</DialogContent>				
				<div className="col-md-12">
            <div className="row">
              {pollingData.map((poll: any) =>
                <div className="jh-polls-results col-md-4">
                  <DialogContent>
                    <div className="jh-results-block-main">
                      <div className="jh-results-block-main-header">
                        <div className="jh-polls-results-block-main-title">
                          <h6>{poll.title}</h6>
                        </div>
                      </div>
                      <div className="jh-results-block-main-body">
                        <div className="jh-polls-results-block">
                          <div className="jh-polls-results-block-title">
                            <Typography>{poll.option1text}</Typography>
                          </div>
                          <div className="jh-polls-results-block-progress">
                            <div className="progress">
                                <div className="progress-bar" style={{ width: `${Math.ceil((poll.option1count / (poll.option1count + poll.option2count + poll.option3count)) * 100)}%` }}></div>
                            </div>
                            <div className="progress-bar-votes">({poll.option1count + ' votes'})</div>
                          </div>
                          <div className="jh-polls-results-block-percent">
                            <Typography>{Math.ceil((poll.option1count / (poll.option1count + poll.option2count + poll.option3count) * 100)) + '%'}</Typography>
                          </div>
                        </div>
                        <div className="jh-polls-results-block jh-polls-results-block-warning">
                          <div className="jh-polls-results-block-title">
                            <Typography>{poll.option2text}</Typography>
                          </div>
                          <div className="jh-polls-results-block-progress">
                            <div className="progress">
                              
                              <div className="progress-bar progress-bar-striped bg-warning" style={{ width: `${Math.ceil((poll.option2count / (poll.option1count + poll.option2count + poll.option3count)) * 100)}%` }}></div>
                            </div>
                            <div className="progress-bar-votes">({poll.option2count + ' votes'})</div>
                          </div>
                          <div className="jh-polls-results-block-percent">
                            <Typography>{Math.trunc((poll.option2count / (poll.option1count + poll.option2count + poll.option3count) * 100)) + '%'}</Typography>
                          </div>
                        </div>
                        <div className="jh-polls-results-block jh-polls-results-block-success">
                          <div className="jh-polls-results-block-title">
                            <Typography>{poll.option3text}</Typography>
                          </div>
                          <div className="jh-polls-results-block-progress">
                            <div className="progress">
                              
                              <div className="progress-bar progress-bar-striped bg-success" style={{ width: `${Math.ceil((poll.option3count / (poll.option1count + poll.option2count + poll.option3count)) * 100)}%` }}></div>
                            </div>
                            <div className="progress-bar-votes">({poll.option3count + ' votes'})</div>
                          </div>
                          <div className="jh-polls-results-block-percent">
                            <Typography>{Math.trunc((poll.option3count / (poll.option1count + poll.option2count + poll.option3count) * 100)) + '%'}</Typography>
                          </div>
                        </div>
                      </div>
                    </div>                  
                </DialogContent>
                </div>

              )}

            </div>
          </div>
					  
	</div>
  </div>
</div>
	)};
  }


export default withStyles(useStyles)(Polling);
import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
import Utils from './utils/homeutils';
var moment: any = require('moment');


class Search extends React.Component<{
  componentName: any,
  city: any,
  searchKeyword: any,
  history: any
}, {
  allItems: any[]
  items: any[],
  pageNumber: any,
  numberOfItems: any,
  startPage: 0,
  endPage: 0,
  PageNumberCount: any,
  PageCount: any,
  externalResults: any[],
  internalResults: any[],
  isExternal: boolean,
  loading: boolean
}>{
  constructor(props: any) {
    super(props);
    this.state = {
      allItems: [],
      items: [],
      pageNumber: 1,
      numberOfItems: 10,
      startPage: 0,
      endPage: 0,
      PageNumberCount: 0,
      PageCount: [],
      externalResults: [],
	  internalResults: [],
      isExternal: false,
      loading: true

    }
    //this.handleChange=this.handleChange.bind(this);
  }
  async componentDidUpdate(prevProps: any, _prevState: any) {
    if (prevProps.searchKeyword !== this.props.searchKeyword) {
      let elkResults = [];
      if (this.props.searchKeyword.length < 4) {
      } else {
        this.setState({ loading: true });
        elkResults = await Utils.GetSearchResultItems(this.props.city, this.props.searchKeyword + "*", 25);
		this.setState({internalResults:elkResults});
        if (elkResults.length > 0) {
          let topResults = elkResults.slice(0, 10);
          let pageCount = Math.ceil(elkResults.length / 10);
          if (topResults.length >= 10) {
            this.setState({ loading: false, items: topResults, externalResults: [], allItems: elkResults, PageNumberCount: pageCount, isExternal: false });
          }
          if (elkResults.length < 20) {
            if (this.props.searchKeyword.length < 3)
              return;
            let googleResults = await Utils.GetGoogleSearchItems(this.props.searchKeyword, 1);
            let combined = [...elkResults, ...googleResults.results];
            let totalExtResults = 0;
            if (googleResults.totalResults > 25) {
              totalExtResults = 25;
            } else {
              totalExtResults = googleResults.totalResults;
            }
            let extSearchPageCount = Math.ceil(totalExtResults / 10);
            let totalPageCount = pageCount + extSearchPageCount;
            let combinedpageCount = 0;
            if (totalPageCount <= 3) {
              combinedpageCount = totalPageCount;
            }
            else {
              combinedpageCount = 3;
            }
            this.setState({ items: combined.slice(0, 10), allItems: combined, PageNumberCount: combinedpageCount, loading: false });
          }
        } else {
          let googleResults = await Utils.GetGoogleSearchItems(this.props.searchKeyword, 1);
          let topResultsExt = googleResults.results;
          let pageCountExt = 0;
          if (googleResults.totalResults > 25) {
            pageCountExt = 3;
          } else {
            pageCountExt = Math.ceil(Number(googleResults.totalResults) / 10);
          }
          this.setState({ loading: false, items: topResultsExt, allItems: [], PageNumberCount: pageCountExt });
        }
      }
    }
  }

  async componentDidMount() {
    this.props.componentName("Search");
    let elkResults = [];
    if (this.props.searchKeyword.length < 4) {
    } else {
      this.setState({ loading: true });
      elkResults = await Utils.GetSearchResultItems(this.props.city, this.props.searchKeyword + "*", 25);
	  this.setState({internalResults:elkResults});
      if (elkResults.length > 0) {
        let topResults = elkResults.slice(0, 10);
        let pageCount = Math.ceil(elkResults.length / 10);
        if (topResults.length >= 10) {
          this.setState({ loading: false, items: topResults, externalResults: [], allItems: elkResults, PageNumberCount: pageCount, isExternal: false });
        }
        if (elkResults.length < 20) {
          if (this.props.searchKeyword.length < 3)
            return;
          let googleResults = await Utils.GetGoogleSearchItems(this.props.searchKeyword, 1);
          let combined = [...elkResults, ...googleResults.results];
          let totalExtResults = 0;
          if (googleResults.totalResults > 25) {
            totalExtResults = 25;
          } else {
            totalExtResults = googleResults.totalResults;
          }
          let extSearchPageCount = Math.ceil(totalExtResults / 10);
          let totalPageCount = pageCount + extSearchPageCount;
          let combinedpageCount = 0;
          if (totalPageCount <= 3) {
            combinedpageCount = totalPageCount;
          }
          else {
            combinedpageCount = 3;
          }
          this.setState({ items: combined.slice(0, 10), allItems: combined, PageNumberCount: combinedpageCount, loading: false });

        }
      } else {
        //  external search results
        let googleResults = await Utils.GetGoogleSearchItems(this.props.searchKeyword, 1);
        //	Net Api - googleResults.results
        let topResultsExt = googleResults.results;
        let pageCountExt = 0;
        if (googleResults.totalResults > 25) {
          pageCountExt = 3;
        } else {
          pageCountExt = Math.ceil(Number(googleResults.totalResults) / 10);
        }
        this.setState({ loading: false, items: topResultsExt, allItems: [], PageNumberCount: pageCountExt });
      }
    }
  }
  handleChange = async (event: any, value: any) => {
    let start: any;
    let end: any;
    if (value === 1) {
      start = 0;
    } else {
      start = (value - 1) * this.state.numberOfItems;
    }
    end = value * this.state.numberOfItems;
    let results = this.state.allItems.slice(start, end);

    if (results.length < 10 && this.state.allItems.length<20) {
	  let startIndex = Number(start) + 1;
      let googleResults = await Utils.GetGoogleSearchItems(this.props.searchKeyword, startIndex);
      let combined = [...results, ...googleResults.results];
      results =
	  combined;
    }
    if (!this.state.isExternal) {
      this.setState({ items: results });
    } else {
      this.setState({ externalResults: results });
    }

  }
  GoToEntity = (obj: any) => {
    this.props.history.push("/entity/" + obj._source.businessname.replace(/[^a-zA-Z0-9]/g, '') + "/"+obj._source.id);
    //${tile._source.businessname.replace(/[^a-zA-Z0-9]/g, '')}
  }
  GoToHome = () => {
    this.props.history.push("/");
  }
  render() {
    let { items, externalResults, allItems, pageNumber, PageCount, loading, PageNumberCount } = this.state;
    const tempAraay = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    return (

      <div id="jh-wrapper">
        <div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
          <div className="row">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="#" onClick={this.GoToHome}>Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">Search Results</li>
              </ol>
            </nav>
          </div>
          <div className="row">
            <div className="col-12 searchWrapDIV">
              {loading && (tempAraay.map((tile: any) => (
                <div>
				 <div>					
					  <Skeleton height={30} />					
				  </div>
                  <Skeleton height={15} />
                  <Skeleton height={70} />
                </div>
              ))

              )}


              <ul className="list-group searchResultsWrapperUL">
                {!loading && items.length > 0 && items.map((tile: any) => (
                  <li className="list-group-item" style={{ paddingBottom: '5px' }}>
                    {tile._source != null && <div>
                      <h5>
                        <a onClick={() => this.GoToEntity(tile)} className="nav-link search-item"><i className="fa fa-chevron-circle-right" aria-hidden="true"></i> {tile._source.businessname}</a>
                      </h5>
                      {tile._source.website !== null && tile._source.website !== "null" && tile._source.website !== "" && (<a className="searchresults-item searchresults-tem-url" href={tile._source.website} target="_blank">{tile._source.website}</a>)}
                      <p className="searchresults-item">{tile._source.shortdescription}</p>
                      <div className="row searchresults-item">
                        <div className="col"><i className="fa fa-phone text-success" aria-hidden="true"></i> {tile._source.phone ? tile._source.phone : 'NA'}
                        </div>
                        <div className="col"><a className="jh-search-item-direction nav-link" href={`https://www.google.com/maps/dir/Current+Location/${tile._source.latitude},${tile._source.longitude}`} target="_blank"><i className="fa fa-car text-danger" aria-hidden="true"></i>  Directions</a></div>
                        <div className="col">Posted on: {tile._source.createddate ? moment(tile._source.createddate).format('MM/DD/yyyy') : 'NA'}</div>
                      </div>
                    </div>}
                    {tile.link && <div>
                      <h5>
                        <a href={tile.link} target="_blank" className="nav-link search-item"><i className="fa fa-chevron-circle-right" aria-hidden="true"></i> {tile.title}</a>
                      </h5>
                      {tile.link !== "null" && tile.link !== "" && (<a className="searchresults-item searchresults-tem-url" href={tile.link} target="_blank">{tile.link}</a>)}
                      <p className="searchresults-item">{tile.description}</p>
                    </div>}
                  </li>

                ))}
              </ul>
              <div className="pagingWrapper">
                <Pagination count={PageNumberCount}
                  onChange={this.handleChange}
				  hidePrevButton 
				  hideNextButton 
                  color="primary"
                />
              </div>
            </div>

          </div>

        </div>
      </div>

    )
  }
}
export default Search;

import React, { Props } from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Home from './home';
import Events from './events';
import Contact from './contact';
import { useHistory } from 'react-router-dom'




class JaihoFooter extends React.Component<any> {
    constructor(props: any) {
        super(props);
    }
    ScrollToTop=()=>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
    render() {

        return (
            <footer className="footer overlay section">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="single-widget about">
                                    <div className="logo">
                                        <a href="index.html"><img src={require('../img/logo.png')} alt="#" /></a>
                                    </div>
                                    <p>Vivamus volutpat eros pulvinar velit laoreet, sit amet egestas erat dignissim. Lorem ipsum dolor sit amet, consectetur</p>
                                    <ul className="list">
                                        <li><i className="fa fa-phone"></i>Phone: +123 456-7890 </li>
                                        <li><i className="fa fa-envelope"></i>Email: <a href="mailto:info@youremail.com">Info@jaiho.com</a></li>
                                        <li><i className="fa fa-map-o"></i>Address: 211 Ronad, California, Us</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-12">
                                <div className="single-widget useful-links">
                                    <h2>Useful Links</h2>
                                    <ul>
                                        <li><a href="#"><i className="fa fa-angle-right"></i>Home</a></li>
                                        <li><a href="#"><i className="fa fa-angle-right"></i>Yellow Pages</a></li>
                                        <li><a href="#"><i className="fa fa-angle-right"></i>Discussion Board</a></li>
                                        <li><a href="#"><i className="fa fa-angle-right"></i>Blogs</a></li>
                                        <li><a href="#"><i className="fa fa-angle-right"></i>Events</a></li>
                                        <li><a href="#"><i className="fa fa-angle-right"></i>Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="single-widget latest-news">
                                    <h2>Latest Posts</h2>
                                    <div className="news-inner">
                                        <div className="single-news">
                                            <img src={require('../img/abacus.jpg')} alt="#" />
                                            <h4><a href="#">Nunc purus orci, blandit</a></h4>
                                            <p>Nunc purus orci, blandit condimentum rhoncus vels</p>
                                        </div>
                                        <div className="single-news">
                                            <img src={require('../img/reglngs.jpg')} alt="#" />
                                            <h4><a href="#">Nunc purus orci, blandit</a></h4>
                                            <p>Nunc purus orci, blandit condimentum rhoncus vels</p>
                                        </div>
                                        <div className="single-news">
                                            <img src={require('../img/lecture1.jpg')} alt="#" />
                                            <h4><a href="#">Nunc purus orci, blandit</a></h4>
                                            <p>Nunc purus orci, blandit condimentum rhoncus vele</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="single-widget newsletter">
                                    <h2>Subscribe Newsletter</h2>
                                    <div className="mail">
                                        <p>Don't miss to  subscribe to our news feed, Get the latest updates from us!</p>
                                        <div className="form">
                                            <input type="email" placeholder="Enter your email" />
                                            <button className="button" type="submit"><i className="fa fa-envelope"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer Bottom */}
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="bottom-head">
                                    <div className="row">
                                        <div className="col-12">
                                            {/* Social */}
                                            <ul className="social">
                                                <li><a href="#"><i className="fa fa-twitter" /></a></li>
                                                <li><a href="#"><i className="fa fa-facebook" /></a></li>
                                                <li><a href="#"><i className="fa fa-google-plus" /></a></li>
                                                <li><a href="#"><i className="fa fa-linkedin" /></a></li>
                                                <li><a href="#"><i className="fa fa-youtube" /></a></li>
                                            </ul>
                                            {/* End Social */}
                                            {/* Copyright */}
                                            <div className="copyright">
                                                <p>© Copyright 2020 <a href="#">JaiHo</a>. All Rights Reserved</p>
                                            </div>
                                            <div className="pull-right">
                                            <button type="button" id="scrollUp jh-move-up-button"  className="pull-right" onClick={this.ScrollToTop}><i className="fa fa-angle-up"></i></button>
                                            </div>
                                            {/*/ End Copyright */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*/ End Footer Bottom */}
            </footer>
        )
    }
}
export default JaihoFooter;

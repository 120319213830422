import React from 'react';
import MUIRichTextEditor from 'mui-rte'
import AsyncImageUpload from '../components/custom/RichText';
import Utils from './utils/homeutils';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';



class Entity extends React.Component<{
	componentName:any,
	history:any,
	match:any
}, {
    selectedItem: any,
    name: any,
    address1: any,
    address2: any,
    phone: any,
    mobile: any,
    email: any,
    fax: any,
    website: any,
	description:any,
	category:any,
	categoryid:any,
	subcategory:any,
	subcategoryid:any,
	recordtype:any
}> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedItem: null,
            name: "",
            address1: "",
            address2: "",
            phone: "",
            mobile: "",
            email: "",
            fax: "",
            website: "",
			description:"",
			category:"",
			categoryid:"",
			subcategory:"",
			subcategoryid:"",
			recordtype:""
        }
    }
	 GoToHome = () => {
    this.props.history.push("/");
  }
    async componentDidUpdate(prevProps: any, _prevState: any) {
        let entityID = this.props.match.params.id;
        if (prevProps.match.params.id === this.props.match.params.id)
            return;
         entityID = this.props.match.params.id;
        let objSelectedEntity=await Utils.GetSingleEntity(Number(entityID));
        this.setState({
            name: objSelectedEntity[0]._source.businessname,
            address1: objSelectedEntity[0]._source.address,
            address2: objSelectedEntity[0]._source.area,
            phone: objSelectedEntity[0]._source.phone,
            mobile: objSelectedEntity[0]._source.mobile,
            email: objSelectedEntity[0]._source.email,
            fax: objSelectedEntity[0]._source.fax,
            website: objSelectedEntity[0]._source.website,			
			category:objSelectedEntity[0]._source.category,
			categoryid:objSelectedEntity[0]._source.categoryid,
			subcategory:objSelectedEntity[0]._source.subcategory,
			subcategoryid:objSelectedEntity[0]._source.subcategoryid,
			recordtype:objSelectedEntity[0]._source.recordtype
        });
		let objSelectedEntityPG=await Utils.GetSingleEntityFromPG(Number(entityID));
		this.setState({description:objSelectedEntityPG[0].description});
}
    async componentDidMount() {
		this.props.componentName("Entity");
        let entityID = this.props.match.params.id;
        /*let elkDataFromCache = null;
        elkDataFromCache = localStorage.getItem("ElkResults");
        let elkData = [];
        if (elkDataFromCache === null) {
            elkData = await Utils.GetAllItems("Bangalore",44);
            console.log(elkData);
            localStorage.setItem("ElkResults", JSON.stringify(elkData));
        } else {
            elkData = JSON.parse(elkDataFromCache);
        }
        console.log(elkData);
        let objSelectedEntity = elkData.filter(function (elkItem: any) { return elkItem._source.id === Number(entityID) });*/
		let objSelectedEntity=await Utils.GetSingleEntity(Number(entityID));
        this.setState({
            name: objSelectedEntity[0]._source.businessname,
            address1: objSelectedEntity[0]._source.address,
            address2: objSelectedEntity[0]._source.area,
            phone: objSelectedEntity[0]._source.phone,
            mobile: objSelectedEntity[0]._source.mobile,
            email: objSelectedEntity[0]._source.email,
            fax: objSelectedEntity[0]._source.fax,
            website: objSelectedEntity[0]._source.website,			
			category:objSelectedEntity[0]._source.category,
			categoryid:objSelectedEntity[0]._source.categoryid,
			subcategory:objSelectedEntity[0]._source.subcategory,
			subcategoryid:objSelectedEntity[0]._source.subcategoryid,
			recordtype:objSelectedEntity[0]._source.recordtype
        });
		let objSelectedEntityPG=await Utils.GetSingleEntityFromPG(Number(entityID));
		this.setState({description:objSelectedEntityPG[0].description});
    }
	private GoToYellowpages = (cat:any,catid:any) => {
        //const { history } = this.props;
        this.props.history.push("/yellowpages/"+cat+"/" + catid + "");
    }
    render() {
        let { name, address1, address2, phone, mobile, email, fax, website,description,category,categoryid,subcategory,subcategoryid,recordtype } = this.state;
		return (
            <div className="d-flex"  id="jh-wrapper">                
                <div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
				 <div className="row">
				  <nav aria-label="breadcrumb">
					<ol className="breadcrumb">
					  <li className="breadcrumb-item"><Link to={"/"}>Home</Link></li>
					   <li className="breadcrumb-item">{recordtype==="Business" && <Link to={"/yellowpages"}>Yellow Pages</Link>}
					   {recordtype==="Event" && <Link to={"/events/all"}>Events</Link>}</li>						 
					  <li className="breadcrumb-item">{recordtype==="Business" && <Link to={`/yellowpages/${category.replace(/[^a-zA-Z0-9]/g, '')}/${categoryid}`}>{category}</Link>}
						  {recordtype==="Event" && <Link to={`/events/${category.toLowerCase()}`}>{category}</Link>}</li>
						  {recordtype==="Business" && <li className="breadcrumb-item"><Link to={`/yellowpages/${category.replace(/[^a-zA-Z0-9]/g, '')}/${subcategory.replace(/[^a-zA-Z0-9]/g, '')}/${subcategoryid}`}>{subcategory}</Link></li>}
					  <li className="breadcrumb-item active" aria-current="page">{name}</li>
					</ol>
				  </nav>
				</div>
                    <div className="col-12 jh-main-content jh-inner-page-main-content">
                        <div className="row jh-main-content-block">
                            <div className="jh-card-page-title-block">
                                <h4 className="jh-card-page-title">{name}</h4>
                            </div>
                        </div>
                        <div className="row jh-main-content-block">
                        <div className="card-section">
                            <div className="card">
                            <div className="col-md-6 col-sm-12">
                                    
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12 jh-card-address-blocks">
                                                <div className="jh-card-address-block">
                                                    <div className="jh-card-address-block-left">
                                                        <p className="jh-card-address-block-title"><b>Address</b></p>
                                                    </div>
                                                    <div className="jh-card-address-block-right jh-card-address-text">
                                                        <p>{address1}</p>
                                                        <p>{address2}</p>
                                                    </div>
                                                    
                                                </div>
                                                <hr/>
                                                <div className="jh-card-address-block">
                                                    <div className="jh-card-address-block-left">
                                                        <p className="jh-card-address-block-title"><b>Contact</b></p>
                                                    </div>
                                                    <div className="jh-card-address-block-right">
                                                        <div className="jh-card-address-block-inner">
                                                            <div className="jh-card-address-block-icons">
                                                                <i className="fa fa-phone"></i>
                                                            </div>
                                                            <div className="jh-card-address-block-details">
                                                                <p><a href={'tel:'+ phone }>{phone}</a></p>
                                                            </div>
                                                        
                                                        </div>
                                                        <div className="jh-card-address-block-inner">
                                                            <div className="jh-card-address-block-icons">
                                                            <i className="fa fa-mobile"></i>
                                                            </div>
                                                            <div className="jh-card-address-block-details">
                                                                <p>
                                                                    <a href={'tel:'+ mobile }>{mobile}</a>
                                                                </p>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="jh-card-address-block-inner">
                                                            <div className="jh-card-address-block-icons">
                                                                <i className="fa fa-envelope"></i>
                                                            </div>
                                                            <div className="jh-card-address-block-details">
                                                                <p>
                                                                    <a href={'mailto:'+ email }>{email}</a>                                                                    
                                                                </p>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="jh-card-address-block-inner">
                                                            <div className="jh-card-address-block-icons">
                                                                <i className="fa fa-fax"></i>
                                                            </div>
                                                            <div className="jh-card-address-block-details">
                                                                <p>
                                                                    <a href={'fax:'+ fax }> {fax}</a>
                                                                
                                                                </p>
                                                            </div>
                                                        
                                                        </div>
                                                        
                                                    
                                                    </div>
                                                    </div>
                                                    <hr/>
                                                    <div className="jh-card-address-block">
                                                    <div className="jh-card-address-block-left">
                                                        <p className="jh-card-address-block-title"><b>Website</b></p>
                                                    </div>
                                                    <div className="jh-card-address-block-right">
                                                        <div className="jh-card-address-block-inner">
                                                                <div className="jh-card-address-block-icons">
                                                                    <i className="fa fa-globe"></i>
                                                                </div>
                                                                <div className="jh-card-address-block-details">
                                                                    <p><a href={website} target="_blank">{website}</a></p>
                                                                </div>
                                                            
                                                            </div>
                                                    </div>
                                                    
                                                </div>
                                                <hr/>
                                                
                                            </div>
                                        </div>
                                        
                                            
                                        <div className="row">
                                                <div className="col-md-12">
													{ReactHtmlParser(description)}
                                                </div> 
                                            </div>
                                    </div>
                                    </div>

                            </div>
                                
                                </div>
                            
                            </div>
                            
                        </div>
                    </div>
            </div>



        )







    }
}



export default Entity;

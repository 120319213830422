import React from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import Utils from './utils/homeutils';
import Skeleton from '@material-ui/lab/Skeleton';
import UsefulQuickLinks from "./homeapps/UsefulQuickLinks";
import {
  Button,
  Typography,
  TextField,
  FormControl,
} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as _ from 'lodash';
class UsefulLinksByCat extends React.Component<{
  componentName: any,
  cityid: any,
  countryid: any,
  history: any,
  match: any
}, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      categories: [],
      links: [],
      loading: true,
      categoryname: '',
      skels: ["1", "2", "3", "4", "5", "6", "1", "2", "3", "4", "5", "6"]
    }
  }
  async componentDidUpdate(prevProps: any, _prevState: any) {
    if (prevProps.match.params.id != this.props.match.params.id) {
      this.setState({ value: this.props.match.params.id, loading: false });
    }
  }
  async componentDidMount() {
    this.props.componentName("UsefulLinks");
    window.scrollTo({ top: 0, behavior: 'smooth' });
    let linkCatID = this.props.match.params.id;
    let usefullinkscats = await Utils.GetUsefullinksCatItems(Number(this.props.countryid));
    let cattitle = usefullinkscats.filter((e: any) => e._source.id === Number(linkCatID))[0]._source.title;
    let usefullinkItems = await Utils.GetUsefullinksItems(Number(this.props.countryid));
    let linksByCat = usefullinkItems.filter((e: any) => e._source.categoryid === Number(linkCatID));
    this.setState({ links: linksByCat, categoryname: cattitle, loading: false });
  }
  render() {
    const { history, countryid } = this.props;
    let { links, categories, loading, categoryname, skels } = this.state;
    return (
      <div className="d-flex" id="jh-wrapper">
        <div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
          <div className="row">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page"><Link to="/usefullinks/all">Useful Links</Link></li>
                <li className="breadcrumb-item active" aria-current="page">{categoryname}</li>
              </ol>
            </nav>
          </div>
          <div className="jh-content-body">
            <div className="row">
              <div className="col-lg-12 jh-events-title">
                <h2 className="jh-title-1">{categoryname}</h2>
              </div>
            </div>
            <div className="col-12">
              <div className="jh-events">
                <div className="jh-events-body">
                  {loading && <div className="row">
                    {skels.map((i: any) => (
                      <div className="jh-polls-results col-lg-4 col-md-6 col-sm-6 col-12">
                        <DialogContent>
                          <div className="jh-results-block-main">
                            <div className="jh-usefulinks-block-main-header-skl">
                              <div className="jh-polls-block-main-title-skl">
                                <span className="usefullink-header-skl"><Skeleton height={30} /></span>
                              </div>
                            </div>
                            <div className="jh-results-block-main-body">
                              <div className="jh-usefullinks-results-block">
                                <div className="jh-polls-results-block-title">
                                  <Skeleton height={50} />
                                </div>
                              </div>

                            </div>

                            <div className="sklmorelinks">
                              <Skeleton variant="rect" height={10} width={50} />
                            </div>


                          </div>

                        </DialogContent>




                      </div>
                    ))}
                  </div>}

                  <div className="row">
                    {links.map((poll: any) =>
                      <div className="jh-polls-results col-md-4">
                        <DialogContent>
                          <div className="jh-results-block-main">
                            <div className="jh-usefulinks-block-main-header">
                              <div className="jh-polls-block-main-title">
                                <span className="usefullink-header">{poll._source.title}</span>
                              </div>
                            </div>
                            <div className="jh-results-block-main-body">
                              <div className="jh-usefullinks-results-block">
                                <div className="jh-polls-results-block-title">
                                  {poll._source.description.length>50 && <Typography>{`${poll._source.description.slice(0,50)}...`}</Typography>}
                                  {poll._source.description.length<=50 && <Typography>{poll._source.description}</Typography>}</div>
                              </div>

                            </div>

                            <div className="pull-right">
                              <a href={poll._source.url} className="usefullink-list-item-link" target="_blank"><i className="fa fa-angle-double-right"></i> Open</a>
                            </div>


                          </div>



                        </DialogContent>

                      </div>

                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  };
}


export default UsefulLinksByCat;

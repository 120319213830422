import React, { Props } from 'react';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/jaihostyles.css';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
class ForgotPassword extends React.Component<{
    componentName:any,
    history:any
}, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            PasswordChangeStatus: "",
            fields: {},
            errors: {}
        }
        // Binding functions
        this.handleChange = this.handleChange.bind(this);
        this.SubmitClick = this.SubmitClick.bind(this);
    }
    componentDidMount(){
        this.props.componentName("fpwd");
     }
    //Functions
    validateForm = () => {
        let fields = this.state.fields;
        let errors = this.state.errors;
        errors = {};
        let formIsValid = true;
        if (!fields.email) {
            formIsValid = false;
            errors["email"] = "Please enter email.";
        }
        let regexEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (fields.email && !regexEmail.test(fields.email)) {
            formIsValid = false;
            errors["email"] = "Please enter valid email.";
        }
        this.setState({
            errors: errors
        });
        return formIsValid;
    }

    SignClick = () => {
        this.props.history.push('/login');
    }

    SubmitClick = (e: any) => {
        let mainScope = this;
        e.preventDefault();
        if (this.validateForm()) {
            let loginURL = "http://68.41.45.20:5100/ForgotPassword";
            axios.post(loginURL, null, {
                "headers": {
                    "email": this.state.fields.email
                }
            })
                .then(function (response) {
                    console.log(response);
                    if (response.data.StatusCode == "Y") {
                        localStorage.clear();
                        mainScope.setState({ PasswordChangeStatus: "Success" });
                    } else {
                        mainScope.setState({ PasswordChangeStatus: "Failed" });
                    }
                })
                .catch(function (error) {
                    mainScope.setState({ PasswordChangeStatus: "Failed" });
                });
            let fields = this.state.fields;
            fields["email"] = "";
            fields["phone"] = "";
            this.setState({ fields: fields });
        }

    }

    handleChange = (e: any) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
    }


    render() {
        return (
            <div className="container" style={{ height: '600px' }}>
                <div className="row" style={{ padding: '5px' }}>
                    <div className="col-lg-3 col-md-3 col-12">
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div id="contact" className="contact">
                            <div className="contact-head">
                                <div className="row">
                                    <div className="col-md-12 col-lg-12 col-sm-12">
                                        <div className="form-head">
                                            <div style={{ textAlign: 'center', fontSize: '30px', padding: '25px' }}>
                                                <b>FORGOT PASSWORD</b>
                                            </div>
                                            {this.state.PasswordChangeStatus &&
                                                <div className={this.state.PasswordChangeStatus == 'Success' ? "alert alert-success" : "alert alert-danger"}>
                                                    {this.state.PasswordChangeStatus == "Success" && <p>Password has been sent to your email. Click <a href="#" onClick={this.SignClick}>here</a> to login</p>}
                                                    {this.state.PasswordChangeStatus == "Failed" && <span>Failed to send the password.</span>}
                                                </div>}
                                            <div className="form-group">
                                                <input name="email" className={this.state.errors.email ? "border-danger" : "form-control"} type="text" placeholder="Email" value={this.state.fields.email} onChange={this.handleChange} />
                                                <small className="text-danger">{this.state.errors.email}</small>
                                            </div>
                                            <div className="form-group">
                                                <input name="phone" className={this.state.errors.phone ? "border-danger" : "form-control"} type="text" placeholder="Phone Number" value={this.state.fields.phone} onChange={this.handleChange} />
                                                <small className="text-danger">{this.state.errors.phone}</small>
                                            </div>
                                            <div className="form-group">
                                                <div className="alert alert-info">
                                                    <p>Note: OTP will be sent to your email and phone</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-8 col-lg-8 col-sm-8 col-xs-12">
                                                    <div className="form-group">
                                                        <input name="otp" className={this.state.errors.otp ? "border-danger" : "form-control"} type="text" placeholder="OTP" value={this.state.fields.otp} onChange={this.handleChange} />
                                                        <small className="text-danger">{this.state.errors.otp}</small>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                                                    <div className="button">
                                                        <button type="button" style={{ width: '100%' }} className="btn-primary btn-lg">GET OTP</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="button" style={{ marginLeft: '35%' }}>
                                                    <button type="submit" className="btn" onClick={this.SubmitClick}>SUBMIT</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-12">
                    </div>

                </div>
            </div >

        )

    }
}



export default ForgotPassword;
